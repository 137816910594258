<template>
  <v-app-bar fixed color="white" :elevation="0">
    <v-app-bar-nav-icon
      v-if="$route.path == '/profile'"
      @click="handlerClickBars"
    ></v-app-bar-nav-icon>
    <template>
      <v-img
        v-if="showImageLogo && isMobile && !isRaMicro"
        class="logo"
        contain
        src="../assets/easymeet_icon.svg"
        @click="goToMainPage"
      ></v-img>
      <v-img
        v-if="showImageLogo && !isMobile && !isRaMicro"
        class="logo"
        contain
        src="../assets/easymeet_logo-b.svg"
        @click="goToMainPage"
      ></v-img>
    </template>
    <template>
      <v-img
        v-if="showImageLogo && isMobile && isRaMicro"
        class="logo"
        contain
        src="../assets/easymeet_icon.svg"
        @click="goToMainPage"
      ></v-img>
      <v-img
        v-if="showImageLogo && !isMobile && isRaMicro"
        class="logo"
        contain
        src="../assets/easymeet_logo-b.svg"
        @click="goToMainPage"
      ></v-img>
    </template>
    <v-spacer></v-spacer>
    <router-link
      to="/plans"
      v-if="
        $route.path !== '/profile' &&
          $route.path !== '/plans' &&
          !isMobile &&
          !isRaMicro
      "
      class="noWrap mt-1"
      :class="{ 'mr-5': $route.path == '/' }"
      >{{ $t("generics.pricing") }}</router-link
    >

    <v-btn
      icon
      outlined
      color="primary"
      class="mx-3 call-btn"
      v-if="$route.path !== '/'"
      @click="startNow"
    >
      <font-awesome-icon
        :icon="['fas', 'phone-alt']"
        :style="{ fontSize: 16 }"
      />
    </v-btn>

    <v-btn
      depressed
      color="primary"
      v-if="
        !user &&
          $route.path !== '/login' &&
          $route.path !== '/register' &&
          $route.path !== '/forgot-password'
      "
      @click="goToLogin"
    >
      {{ $t("generics.login") }}
    </v-btn>

    <v-menu
      rounded
      offset-y
      v-if="
        user &&
          $route.path !== '/login' &&
          $route.path !== '/register' &&
          $route.path !== '/forgot-password'
      "
      min-width="350"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
          @click="setDefaultAvatar"
          :color="
            isRaMicro && !user.photoURL && !user.customPhotoURL ? 'primary' : ''
          "
        >
          <img v-if="user.customPhotoURL" :src="user.customPhotoURL" />
          <img v-else-if="user.photoURL" :src="user.photoURL" />
          <RandomAvatar
            id="avatarSvg"
            v-else-if="!user.photoURL && !user.customPhotoURL && !isRaMicro"
            @click="setDefaultAvatar"
          />
          <span
            class="white--text"
            v-else-if="!user.photoURL && !user.customPhotoURL && isRaMicro"
            >{{ getAvatar }}</span
          >
        </v-avatar>
        <!-- <v-btn color="primary" v-bind="attrs" v-on="on">
            Account
          </v-btn> -->
      </template>

      <v-list class="pt-7 pb-5">
        <v-list-item>
          <v-list-item-title>
            <v-avatar
              size="80px"
              :color="
                isRaMicro && !user.photoURL && !user.customPhotoURL
                  ? 'primary'
                  : ''
              "
            >
              <img
                contain
                v-if="user.customPhotoURL"
                :src="user.customPhotoURL"
              />
              <img contain v-else-if="user.photoURL" :src="user.photoURL" />
              <span
                class="white--text"
                style="font-size: 30px;"
                v-else-if="!user.photoURL && !user.customPhotoURL && isRaMicro"
                >{{ getAvatar }}</span
              >
            </v-avatar>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="mt-2">
          <v-list-item-title>
            <p v-if="user.displayName" class="mb-0 font-weight-bold body-1">
              {{ user.displayName }}
            </p>
            <p class="mb-0">{{ user.email }}</p>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="mt-1 mb-2">
          <v-list-item-title>
            <v-btn depressed color="primary" @click="goToManageAccount">
              {{ $t("links.manage-account") }}
            </v-btn>
            <!--<div class="divManage">
            Manage your account
          </div>-->
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn color="primary" outlined @click="doLogout">
              {{ $t("links.logout") }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <needs-login
      :showNeedsLogin="showNeedsLogin"
      :closeModal="closeNeedsLogin"
      :goToLogin="goToLogin"
      :goToRegister="goToRegister"
    />
  </v-app-bar>
</template>
<script>
import RandomAvatar from "./randomAvatar.vue";
import { svgAsPngUri } from "save-svg-as-png";
import { uploadFile } from "@/firebase";
import { isMobile, randomString, isRaMicro } from "../utils";
import NeedsLogin from "./modals/needsLogin.vue";
import FingerprintJS from "fingerprintjs";
import { getFirebaseToken } from "../firebase";
export default {
  name: "Header",
  props: ["openCloseSidebar"],
  components: { RandomAvatar, NeedsLogin },
  data: () => ({
    isMobile: isMobile(),
    isRaMicro: isRaMicro(),
    showNeedsLogin: false
  }),
  methods: {
    startNow() {
      if (!this.isRaMicro) {
        this.initCall();
      } else {
        if (this.user) {
          this.goToStartCallPage();
          // this.initCall();
        } else {
          this.showNeedsLogin = true;
        }
      }
    },
    closeNeedsLogin() {
      this.showNeedsLogin = false;
    },
    goToStartCallPage() {
      this.$router.push({ path: `/start` });
    },
    goToRegister() {
      this.$router.push({ path: `/register` });
    },
    async initCall() {
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            let fingerPrint = new FingerprintJS().get();
            let ts = Date.now();
            this.$router.push({
              path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}`
            });
            this.showCallComponent = true;
          } else {
            // Try again with a different room
            this.initCall();
          }
        });
    },
    handlerClickBars() {
      if (this.openCloseSidebar) {
        this.openCloseSidebar();
      }
    },
    goToMainPage() {
      this.$router.replace("/");
    },
    goToManageAccount() {
      this.$router.push({ path: `/profile` });
    },
    doLogout() {
      this.$store.dispatch("USER_SIGNOUT");
      if (this.$route.path !== "/") {
        this.$router.replace("/");
      }
    },
    goToLogin() {
      this.$router.push({
        path: `/login`
      });
    },
    setDefaultAvatar() {
      if (this.$store.state.user) {
        if (
          !this.user.customPhotoURL &&
          !this.user.photoURL &&
          !this.isRaMicro
        ) {
          let filename = "profile_pic/" + this.user.uid + ".png";
          svgAsPngUri(document.getElementById("avatarSvg"), {
            width: 256,
            height: 256
          })
            .then(uri => {
              return fetch(uri);
            })
            .then(res => {
              return res.blob();
            })
            .then(blob => {
              return uploadFile(blob, filename);
            })
            .then(url => {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  customPhotoURL: url
                }
              });
            })
            .catch(error => {
              console.log("error uploading file: " + error);
            });
        }
      }
    }
  },
  computed: {
    getAvatar() {
      let avatarN = "";
      let stringToLoop;
      if (this.user.displayName) {
        stringToLoop = this.user.displayName;
      } else {
        stringToLoop = this.user.email;
      }
      for (var i = 0; i < stringToLoop.length; i++) {
        if (avatarN.length < 2 && /^[a-z0-9]+$/i.test(stringToLoop.charAt(i))) {
          avatarN += stringToLoop.charAt(i);
        }
      }
      return avatarN.toUpperCase();
    },
    showImageLogo() {
      return (
        this.$route.path !== "/" &&
        this.$route.path !== "/c" &&
        this.$route.path !== "/login" &&
        this.$route.path !== "/register" &&
        this.$route.path.indexOf("/paypal/") == -1 &&
        this.$route.path !== "/cancel"
      );
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
@import url(https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css);
//.divManage {
//margin: 0 auto;
//word-break: break-word;
//max-width: 60%;
//border: 1px solid #cccccc;
//padding: 6px;
//}
// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
@media only screen and (max-width: 700px) {
  .logo {
    max-width: 45px !important;
  }
}
.logo {
  height: 2rem;
  width: 13rem;
  cursor: pointer;
}
.call-btn {
  //border: solid thin #1976d2;
  width: 38px !important;
  height: 38px !important;
  //& > span > svg {
  // color: #1976d2;
  //}
  &:hover {
    //color: rgb(25 118 210 / 54%)!important;
  }
}
.button {
  font-family: "Muli", sans-serif;
}

.spacer {
  flex-basis: 100%;
}
.noWrap {
  white-space: nowrap;
  color: black;
  &:hover {
    text-decoration: underline;
  }
}
</style>
