import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import firebase, {
  onAuthenticationChanged,
  getFirebaseClaims,
  getFirebaseToken,
  getDeviceToken
} from "./firebase";
import wb from "./registerServiceWorker";
// create web-component for beacon
import beacon from "./components/beacon.vue";
import wrap from "@vue/web-component-wrapper";
import { isRaMicro } from "@/utils.js";
import FingerprintJS from "fingerprintjs";
import { injectAirtimeScript } from "./tubePaymentLoader";
import Notifications from "vue-notification";

// Invokes method to shim Promise.allSettled if it is unavailable or noncompliant.
import { shim as allSettledShim } from "promise.allsettled";
allSettledShim();

const wrappedElement = wrap(Vue, beacon);
window.customElements.define("easy-beacon", wrappedElement);
// const IPSTACK_KEY = "5ea9b1bcf4b332a710689ab920e02db7";

Vue.prototype.$workbox = wb;
Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_SENTRYEY) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRYKEY,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });
}
Vue.use(Notifications);
new Vue({
  router,
  store,
  vuetify,
  data: {
    firebase
  },
  created() {
    // REDIRECT in case you're connecting from germany
    fetch(
      `https://api.ipstack.com/check?access_key=${process.env.VUE_APP_IPSTACK_KEY}&output=json`
    )
      .then(response => response.json())
      .then(response => {
        let result = (response || "").toString();
        if (!result) {
          console.error("unable to fetch the country");
        }
        // console.log(response);
        if (response.country_code == "DE") {
          if (
            !window.location.hostname.startsWith("de.") &&
            !window.location.hostname.startsWith("ra-easymeet--beta") &&
            window.location.href.indexOf("/r/") == -1 &&
            window.location.href.indexOf("/c/") == -1
          ) {
            window.location.href = "https://de.easymeet.me";
          }
        }
      });
    //REDIRECT END
    onAuthenticationChanged().then(user => {
      if (user) {
        let fingerPrint = new FingerprintJS().get();
        this.$store.dispatch("USER_SET_FINGERPRINT", { fingerPrint });
        this.$store.dispatch("USER_FETCH", { user });
        this.$store.dispatch("USER_TRANSACTIONS_FETCH", { user });
        this.$store.dispatch("USER_SUBSCRIPTIONS_FETCH", { user });
        this.$store.dispatch("USER_STRIPE_INVOICE_FETCH", {
          user,
          isRaMicro: isRaMicro()
        });
        this.$store.dispatch("USER_STRIPE_SUBSCRIPTIONS_FETCH", {
          user,
          isRaMicro: isRaMicro()
        });
        this.checkCryptoClaim();
        getDeviceToken().then(token => {
          if (token) {
            this.saveDeviceToken(user, token);
          }
        });
      } else {
        this.$store.commit("USER_LOADING", false);
      }
    });
    if (!isRaMicro()) {
      injectAirtimeScript();
    }
  },
  methods: {
    async saveDeviceToken(user, token) {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/savePushNotificationDevice`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            user: user.uid,
            deviceID: token
          })
        }
      ).catch(error => {
        console.warn("Error on savePushNotificationDevice", error);
      });
    },
    async checkCryptoClaim() {
      let claims = await getFirebaseClaims(true);
      if (
        claims.hasSub === 1 &&
        (claims.subType === 2 || claims.subType === 3)
      ) {
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/updateCryptoClaim`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          }
        });
      }
    }
  },
  render: h => h(App)
}).$mount("#app");
