<template>
  <v-dialog v-model="showNeedsLogin" persistent max-width="600px">
    <v-card>
      <HeaderModals :titleModal="'Info'"></HeaderModals>
      <v-col cols="12">
        <v-card-text class="text-left">
          {{ $t("generics.needsLogin") }}
        </v-card-text>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="goToLogin">
          {{ $t("generics.login") }}
        </v-btn>
        <v-btn color="primary" @click="goToRegister">
          {{ $t("generics.sign-up") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
export default {
  components: { HeaderModals },
  name: "settingsModal",
  props: ["showNeedsLogin", "closeModal", "goToLogin", "goToRegister"],
  data: () => ({
  }),
  methods:{},
  computed:{}
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

