<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-row class="rowPills">
      <v-col cols="12">
        <h1 class="pb-2">
          {{ $t("profile.personalInfo.personalInfo") }}
        </h1>
        <p>
          {{ $t("profile.personalInfo.subtitle") }} <strong>easy</strong>meet.me
          {{ $t("profile.personalInfo.subtitle-1") }}
        </p>
      </v-col>
      <!-- <v-col cols="3"></v-col> -->
      <v-col cols="12" style="text-align:left;" class="px-0">
        <div class="bordered pa-5">
          <div class="pb-3">
            <p class="mb-1 title">
              {{ $t("2FA.securityTitel") }}
            </p>
            <p class="body-1" v-if="!user.security">
              {{ $t("2FA.securitySubTitelEnable") }}
            </p>
            <p
              class="body-1"
              v-if="user.security"
              v-html="$t('2FA.securitySubTitelDisable')"
            ></p>
          </div>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
              v-if="!user.security && !responseGenerate"
            >
              <v-btn
                color="primary"
                @click="enable2FA"
                :loading="generatingSecret"
                >{{ $t("2FA.enable2FA") }}</v-btn
              >
            </v-col>
            <v-row v-if="user.security" class="mx-0">
              <v-col cols="12" class="text-left">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  required
                  :label="$t('2FA.2FAcode')"
                  v-on:keyup.enter="sendCode('disable2FA')"
                  v-model="confirm2fa"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  @click="sendCode('disable2FA')"
                  :loading="sendingCode"
                  :disabled="confirm2fa == ''"
                  >{{ $t("2FA.disable2FA") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="responseGenerate" class="mx-0">
              <v-col cols="12" class="text-center">
                <div class="d-inlineb qrDiv">
                  <v-img
                    :src="responseGenerate.qrcode"
                    contain
                    height="200"
                    width="200"
                  ></v-img>
                </div>
                <div class="d-inlineb">
                  <p class="base32Code">{{ responseGenerate.base32 }}</p>
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    required
                    :label="$t('2FA.2FAcode')"
                    v-model="confirm2fa"
                    v-on:keyup.enter="sendCode('verifySecret')"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" class="text-right" v-if="responseGenerate">
                <v-btn
                  color="primary"
                  @click="sendCode('verifySecret')"
                  :loading="sendingCode"
                  :disabled="confirm2fa == ''"
                  >{{ $t("callComponent.accept") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-row>
        </div>
        <br />
        <div class="bordered pa-5">
          <div class="pb-3">
            <p class="mb-1 title">
              {{ $t("profile.personalInfo.basicInfo") }}
            </p>
            <p class="body-1">
              {{ $t("profile.personalInfo.basicInfoSubtitle") }}
            </p>
          </div>
          <v-row class="links" @click="showAddPhoto">
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.photo") }}
            </v-col>
            <v-col cols="7" sm="8">
              {{ $t("profile.personalInfo.photoDesc") }}
            </v-col>
            <v-col cols="1" class="pb-0 text-center">
              <v-avatar size="30px">
                <img
                  v-if="$store.state.user.user.customPhotoURL"
                  :src="$store.state.user.user.customPhotoURL"
                />
                <img
                  v-else-if="$store.state.user.user.photoURL"
                  :src="$store.state.user.user.photoURL"
                />
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("generics.email") }}
            </v-col>
            <v-col cols="7" sm="8">
              {{ email }}
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.name") }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="name"
                @change="_ => (name = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.language") }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-combobox
                hide-details
                dense
                outlined
                :items="getLanguages"
                v-model="selectedLang"
                item-text="name"
                item-value="code"
                @input="isSaveButtonDisabled = false"
              />
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row class="links" @click="showChangePassword">
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.password") }}
            </v-col>
            <v-col cols="7" sm="8">
              ********
            </v-col>
            <v-col cols="1" class="text-center">
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <br />
        <div class="bordered pa-5">
          <div class="pb-3">
            <p class="mb-1 title">
              {{ $t("profile.personalInfo.contactInfo") }}
            </p>
            <p class="body-1">
              {{ $t("profile.personalInfo.contactInfoSubtitle") }}
            </p>
          </div>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{
                !isMobile
                  ? $t("profile.personalInfo.company")
                  : $t("profile.personalInfo.companyMobile")
              }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="company"
                @change="_ => (company = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.street") }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="street"
                @change="_ => (street = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.address") }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="address"
                @change="_ => (address = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{ $t("profile.personalInfo.country") }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="country"
                @change="_ => (country = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{
                !isMobile
                  ? $t("profile.personalInfo.taxNo")
                  : $t("profile.personalInfo.taxNoMobile")
              }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="taxNo"
                @change="_ => (taxNo = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" class="font-weight-bold">
              {{
                isMobile
                  ? $t("profile.personalInfo.billingEmailMobile")
                  : $t("profile.personalInfo.billingEmail")
              }}
            </v-col>
            <v-col cols="7" sm="8">
              <v-text-field
                v-on:keyup.enter="saveUserInfo"
                dense
                outlined
                hide-details
                required
                :value="billingEmail"
                @change="_ => (billingEmail = _)"
                @input="isSaveButtonDisabled = false"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-center"> </v-col>
          </v-row>
        </div>

        <br />
        <div style="text-align:right;">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="saveUserInfo"
            :disabled="isSaveButtonDisabled"
            >{{ $t("paymentModals.save") }}</v-btn
          >
        </div>

        <AddPhotoModal
          :showModalAddPhoto="showModalAddPhoto"
          :closeModal="closeAddPhoto"
        />
        <EditInfoModal
          :showModalEditInfo="showModalEditInfo"
          :closeModal="closeEditInfo"
          :targetField="targetField"
        />
        <ChangePassword
          :showModalChangePassword="showModalChangePassword"
          :closeModal="closeChangePassword"
        />
      </v-col>
      <!-- <v-col cols="3"></v-col> -->
      <v-alert :value="showError" type="error" class="genericAlerts">
        {{ errorMessage }}
      </v-alert>
    </v-row>
  </v-card>
</template>
<script>
import AddPhotoModal from "./modals/addphoto.vue";
import EditInfoModal from "./modals/editInfo.vue";
import ChangePassword from "./modals/changePassword.vue";
import { availableLanguages, isMobile } from "@/utils.js";
import { getFirebaseToken } from "../../firebase";
import Vue from "vue";
export default {
  name: "PersonalInfo",
  props: ["showSection"],
  components: { AddPhotoModal, EditInfoModal, ChangePassword },
  data: () => ({
    name: "",
    email: "",
    company: "",
    street: "",
    address: "",
    country: "",
    taxNo: "",
    billingEmail: "",
    showModalAddPhoto: false,
    showModalEditInfo: false,
    showModalChangePassword: false,
    targetField: "",
    isSaveButtonDisabled: true,
    languageList: "",
    selectedLang: {},
    generatingSecret: false,
    responseGenerate: false,
    confirm2fa: "",
    sendingCode: false,
    showError: false,
    errorMessage: "",
    isMobile: isMobile()
  }),
  created() {},
  mounted() {
    this.name = this.$store.state.user.user.displayName || "";
    this.email = this.$store.state.user.user.email || "";
    this.company = this.$store.state.user.user.company || "";
    this.street = this.$store.state.user.user.street || "";
    this.address = this.$store.state.user.user.address || "";
    this.country = this.$store.state.user.user.country || "";
    this.taxNo = this.$store.state.user.user.taxNo || "";
    this.billingEmail = this.$store.state.user.user.billingEmail || "";
    this.selectedLang = {
      name: this.$t(`languages.${this.$locale.current()}`),
      code: this.$locale.current()
    };
  },
  methods: {
    async enable2FA() {
      this.generatingSecret = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/generateSecret`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          name: this.user.displayName || this.user.email
        })
      })
        .then(response => response.json())
        .then(response => {
          this.generatingSecret = false;
          if (response.success) {
            this.responseGenerate = response.data;
            const user = this.user;
            this.$store.dispatch("USER_FETCH", { user });
          }
        });
    },
    async sendCode(action) {
      this.sendingCode = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/verifySecret`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          action: action,
          userToken: this.confirm2fa
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.responseGenerate = false;
            const user = this.user;
            this.$store.dispatch("USER_FETCH", { user });
          } else if (response.error && response.message == "INCORRECT_CODE") {
            this.showError = true;
            this.errorMessage = Vue.prototype.$t("2FA.invalidCode");
          } else if (response.error) {
            this.showError = true;
            this.errorMessage = Vue.prototype.$t("2FA.somethingWentWrong");
          }
          if (this.showError) {
            setTimeout(() => {
              this.showError = false;
            }, 4000);
          }
          this.sendingCode = false;
          this.confirm2fa = "";
        });
    },
    showAddPhoto() {
      this.showModalAddPhoto = true;
    },
    closeAddPhoto() {
      this.showModalAddPhoto = false;
    },
    showEditInfo(targetField) {
      this.targetField = targetField;
      this.showModalEditInfo = true;
    },
    closeEditInfo() {
      this.showModalEditInfo = false;
    },
    showChangePassword() {
      this.showModalChangePassword = true;
    },
    closeChangePassword() {
      this.showModalChangePassword = false;
    },
    saveUserInfo() {
      this.$store.dispatch("SAVE_USER_INFO", {
        user: this.$store.state.user,
        userInfo: {
          displayName: this.name,
          language: this.selectedLang.code,
          company: this.company,
          street: this.street,
          address: this.address,
          country: this.country,
          taxNo: this.taxNo,
          billingEmail: this.billingEmail
        }
      });
      this.$locale.change(this.selectedLang.code);
      window.localStorage.setItem("language", this.selectedLang.code);
      this.isSaveButtonDisabled = true;
    }
  },
  computed: {
    getLanguages() {
      return availableLanguages();
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
.d-inlineb {
  display: inline-block;
  &.qrDiv {
    vertical-align: middle;
  }
  & .base32Code {
    font-size: 14px;
  }
}
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.titlePill {
  width: calc(100% - 20px);
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.bordered {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}
.dropDownButton {
  width: 100%;
  color: black;
  .v-icon {
    position: absolute;
    right: -10px;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
