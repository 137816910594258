<template>
  <v-dialog v-model="showModalEnterpriseRequest" persistent max-width="600px">
    <v-card v-if="showSuccessMessage">
      <HeaderModals
        :titleModal="$t('plans.enterpriseSuccessHeader')"
      ></HeaderModals>
      <v-card-text>
        <v-row class="mt-3">
          <v-col class="mb-0 pb-0 text-left" cols="12">
            {{ $t("plans.enterpriseSuccessbody") }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">
          {{ $t("paymentModals.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <!-- <v-progress-linear
        absolute
        v-if="isUploading"
        color="primary accent-4"
        indeterminate
        rounded
        height="5"
      ></v-progress-linear> -->
      <HeaderModals :titleModal="$t('plans.enterprise.title')"></HeaderModals>
      <v-card-text>
        <v-form v-model="isFormValid">
          <v-row class="mt-3">
            <v-col class="mb-0 pb-0" cols="12" md="6">
              <v-text-field
                v-model="email"
                :label="$t('generics.email')"
                required
                :rules="emailRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="6" md="6">
              <v-text-field
                v-model="firstname"
                :label="$t('profile.addressBook.firstname')"
                required
                :rules="firstnameRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="6" md="6">
              <v-text-field
                v-model="lastname"
                :label="$t('profile.addressBook.lastname')"
                required
                :rules="lastnameRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="6" md="6">
              <v-text-field
                v-model="company"
                :label="$t('profile.personalInfo.company')"
                :rules="companyRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="6" md="6">
              <v-text-field
                v-model="phone"
                :label="$t('profile.addressBook.phone')"
                :rules="phoneRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-0 pb-0" cols="12" md="12">
              <v-textarea
                v-model="comment"
                dense
                no-resize
                outlined
                :label="$t('generics.comment')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close()">
          {{ $t("paymentModals.close") }}
        </v-btn>
        <v-btn color="primary" @click="submitForm()" :disabled="!isFormValid">
          {{ $t("generics.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isRaMicro } from "@/utils";
import HeaderModals from "./commons/headerModals.vue";
export default {
  name: "EnterpriseRequestModal",
  props: ["showModalEnterpriseRequest", "closeModal"],
  components: { HeaderModals },
  data() {
    return {
      isRaMicro: isRaMicro(),
      showSuccessMessage: false,
      isFormValid: false,
      email: "",
      firstname: "",
      lastname: "",
      company: "",
      phone: "",
      firstnameRules: [v => !!v || "First Name is required"],
      lastnameRules: [v => !!v || "Last Name is required"],
      companyRules: [v => !!v || "Company is required"],
      phoneRules: [
        v => !!v || "Phone is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^[0-9]+$/.test(v) || "Phone number is invalid"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail is invalid"
      ],
      comment: ""
    };
  },
  methods: {
    close() {
      this.email = "";
      this.firstname = "";
      this.lastname = "";
      this.company = "";
      this.phone = "";
      this.comment = "";
      this.closeModal();
      // setTimeout to prevents flickering
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 100);
    },
    submitForm() {
      let payload = {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        company: this.company,
        phone: this.phone,
        comment: this.comment
      };
      console.log(payload);
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/enterpriseSalesRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            payload: payload
          })
        }
      );
      this.showSuccessMessage = true;
    }
  },
  computed: {}
};
</script>
<style scoped lang="scss">
.imageModal {
  width: 150px;
  max-width: 150px;
}
</style>

<style lang="scss">
.avatar-cropper-btn {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  color: #fff !important;
  height: 36px !important;
  min-width: 64px !important;
  font-size: 0.875rem !important;
  padding: 0 8px !important;
  margin: 0px 5px;
  border-radius: 4px;
}
.avatar-cropper-container {
  min-width: 400px;
  box-shadow: none !important;
  .cropper-bg {
    margin: 0 auto;
  }
  .cropper-modal {
    background-color: white !important;
  }
  .avatar-cropper-image-container {
    margin-bottom: 20px;
  }
}
</style>
