<template>
  <div class="register">
    <Register />
  </div>
</template>

<script>
// @ is an alias to /src
import Register from "@/components/register.vue";

export default {
  name: "register",
  components: {
    Register
  }
};
</script>
<style scoped lang="scss">
.register {
  background-color: #fff;
  word-break: break-word;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  // background-image: url(../assets/d_bg.svg);
  // background-size: auto;
  // background-repeat: no-repeat;
  // background-position: left;
}
</style>
