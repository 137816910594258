<template>
  <avataaars></avataaars>
</template>
<script>
import Avataaars from "vuejs-avataaars";
export default {
  name: "RandomAvatar",
  components: { Avataaars },
  data: () => ({}),
  methods: {}
};
</script>
<style scoped lang="scss"></style>
