<template>
  <div>
    <v-dialog v-model="showModalPaypalSettings" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >PayPal {{ $t("paymentModals.settings") }}

            <!-- <v-tooltip color="red" top>
              <template v-slot:activator="{ on, attrs }">
                <a href="/help" target="_self">
                  <v-icon color="red" v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </a>
              </template>
              <span>{{ $t("profile.payments.needHelp") }}</span>
            </v-tooltip> -->
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="clientID"
                  dense
                  outlined
                  hide-details
                  :label="$t('paymentModals.clientID')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="clientSecret"
                  dense
                  outlined
                  hide-details
                  :label="$t('paymentModals.clientSecret')"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <div class="paddingLeft12">
                <a href="/help" target="_self">
                  <span>{{ $t("profile.payments.needHelp") }}</span>
                </a>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeModal">
            {{ $t("paymentModals.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading || loadingButton"
            @click="saveSettings"
            :disabled="disableSaveSettings"
          >
            {{ $t("paymentModals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert
      v-if="showSettingsSaved"
      class="genericAlerts"
      dense
      color="green accent-4"
      elevation="2"
    >
      Settings saved
    </v-alert>
    <v-alert
      v-if="hasError"
      class="genericAlerts"
      dense
      color="red accent-4"
      elevation="2"
    >
      {{ error }}
    </v-alert>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "PaypalSettingsModal",
  props: ["showModalPaypalSettings", "closeModal"],
  data: () => ({
    clientID: '',
    clientSecret: '',
    loadingButton: false,
    showSettingsSaved: false,
  }),
  watch:{
    hasSuccess:{
      inmediate: true,
      handler: function(value){
        if ( value ){
          this.showSettingsSaved = true;
          setTimeout(() => {
            this.showSettingsSaved = false;
          }, 3000)
        }
      }
    }
  },
  mounted(){
    if ( this.user && this.$store.state.user.user.hasSettings ){
      this.clientID = this.$store.state.user.user.clientID;
      this.clientSecret = this.$store.state.user.user.clientSecret;
    }
    if ( !this.user ){
      let localStorageSettings = localStorage.getItem('userSettings');
      if ( localStorageSettings ){
        localStorageSettings = JSON.parse(localStorageSettings);
        this.clientID = localStorageSettings.clientID;
        this.clientSecret = localStorageSettings.clientSecret;
      }
    }
  },
  methods:{
    saveSettings(){
      if ( this.clientID !== '' && this.clientSecret !== '' ){
        if ( this.user ){
          this.$store.dispatch("SAVE_USER_API_SETTINGS", {
            user: this.$store.state.user,
            settings: {
              clientID: this.clientID,
              clientSecret: this.clientSecret,
              hasSettings: true
            }
          });
        }else{
          this.loadingButton = true;
          const settingsObj = {
            clientID: this.clientID,
            clientSecret: this.clientSecret,
            hasSettings: true
          }
          localStorage.setItem('userSettings', JSON.stringify(settingsObj));
          this.loadingButton = false;
          this.closeModal();
          this.showSettingsSaved = true;
          setTimeout(() => {
            this.showSettingsSaved = false;
          }, 3000)
        }
        
      }
    }
  },
  computed:{
    disableSaveSettings(){
      return this.clientID == '' || this.clientSecret == ''
    },
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess(){
      return !!this.$store.state.user.user_success;
    }
  }
};

</script>
<style scoped>
.paddingLeft12 {
  padding-left: 12px;
}
</style>