<template>
  <div class="terms">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container">
          <h1>Restricted Businesses—Spain</h1>
          <p>Last updated: November 22, 2021</p>

          <section id="restricted_list">
            <p>
              The following categories of businesses and business practices are
              restricted from using the Stripe Service (“Restricted
              Businesses”). Restricted Business categories may be imposed
              through Network Rules or the requirements of our Financial
              Services Providers. In certain cases, businesses listed below may
              be eligible for processing with explicit prior approval from
              Stripe. Note, however, that businesses that offer illegal products
              or services are never eligible to use the Stripe Services. The
              types of businesses listed in the right column are representative,
              but not exhaustive. If you are uncertain as to whether your
              business is a Restricted Business, or have questions about how
              these requirements apply to you, please
              <a href="mailto:support@stripe.com">contact us</a>.
            </p>

            <p>
              <strong
                >By registering with us, you are confirming that you will not
                use the Service to accept payments in connection with the
                following businesses, business activities, or business
                practices, unless you have received prior written approval from
                Stripe. It is prohibited to use the Service for any dealings,
                engagement, or sale of goods/services linked directly or
                indirectly with jurisdictions Stripe has deemed high risk, such
                as Cuba, Iran, North Korea, Crimea Region, and Syria.</strong
              >
            </p>

            <h2 id="financial-and-professional-services">
              Financial and professional services<a
                class="anchor"
                href="#financial-and-professional-services"
              ></a>
            </h2>
            <table>
              <tbody>
                <tr>
                  <td style="width:30%;">
                    Investment &amp; credit services
                  </td>
                  <td>
                    Securities brokers; mortgage consulting or debt reduction
                    services; credit counselling or repair; real estate
                    opportunities; lending instruments
                  </td>
                </tr>

                <tr>
                  <td>
                    Money and legal services
                  </td>
                  <td>
                    Money transmitters, check cashing, wire transfers, money
                    orders; currency exchanges or dealers; bail bonds;
                    collections agencies; law firms collecting funds for any
                    purpose other than to pay fees owed to the firm for services
                    provided by the firm (e.g., firms cannot use Stripe to hold
                    client funds, collection or settlement amounts, disputed
                    funds, etc.)
                  </td>
                </tr>

                <tr>
                  <td>
                    Virtual currency or stored value
                  </td>
                  <td>
                    Virtual currency that can be monetized, resold, or converted
                    to physical or digital products and services or otherwise
                    exit the virtual world (e.g., Bitcoin); sale of stored value
                    or credits maintained, accepted and issued by anyone other
                    than the seller
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 id="ip-infringement-regulated-or-illegal-products-and-services">
              IP Infringement, regulated or illegal products and services<a
                class="anchor"
                href="#ip-infringement-regulated-or-illegal-products-and-services"
              ></a>
            </h2>
            <table>
              <tbody>
                <tr>
                  <td style="width:30%;">
                    Intellectual property or proprietary rights infringement
                  </td>
                  <td>
                    Sales, distribution, or access to counterfeit music, movies,
                    software, or other licensed materials without the
                    appropriate authorization from the rights holder; any
                    product or service that directly infringes or facilitates
                    infringement upon the trademark, patent, copyright, trade
                    secrets, or proprietary or privacy rights of any third
                    party; use of Stripe intellectual property without express
                    consent from Stripe; use of the Stripe name or logo
                    including use of Stripe trade or service marks inconsistent
                    with the
                    <a href="/marks/terms">Stripe Marks Usage Agreement</a>, or
                    in a manner that otherwise harms Stripe or the Stripe brand;
                    any action that implies an untrue endorsement by or
                    affiliation with Stripe
                  </td>
                </tr>
                <tr>
                  <td>
                    Counterfeit or unauthorized goods
                  </td>
                  <td>
                    Unauthorized sale or resale of brand name or designer
                    products or services; sale of goods or services that are
                    illegally imported or exported
                  </td>
                </tr>
                <tr>
                  <td>
                    Gambling
                  </td>
                  <td>
                    Lotteries; bidding fee auctions; sports forecasting or odds
                    making; fantasy sports leagues with cash prizes; internet
                    gaming; contests; sweepstakes; games of chance
                  </td>
                </tr>
                <tr>
                  <td>
                    Regulated or illegal products or services
                  </td>
                  <td>
                    Cannabis dispensaries and related businesses; sale of
                    products containing cannabis/ marijuana, cannabidiol (CBD),
                    and their derivatives; sale of tobacco, e-cigarettes, and
                    e-liquid; online pharmacies; prescription-only products
                    including card-not-present pharmaceuticals; peptides and
                    research chemicals; fake references or ID-providing
                    services; age restricted goods or services; weapons and
                    munitions; gunpowder and other explosives; fireworks and
                    related goods; toxic, flammable, and radioactive materials;
                    products and services with varying legal status on a
                    state-by-state basis; goods or services, the sale of which
                    is illegal under applicable law in the jurisdictions to
                    which your business is targeted or directed
                  </td>
                </tr>
                <tr>
                  <td>
                    Adult content and services
                  </td>
                  <td>
                    Pornography and other obscene materials (including
                    literature, imagery and other media); sites offering any
                    sexually-related services such as prostitution, escorts,
                    pay-per view, adult live chat features
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 id="unfair-predatory-or-deceptive-practices">
              Unfair, predatory, or deceptive practices<a
                class="anchor"
                href="#unfair-predatory-or-deceptive-practices"
              ></a>
            </h2>
            <table>
              <tbody>
                <tr>
                  <td style="width:30%;">
                    Get rich quick schemes
                  </td>
                  <td>
                    Investment opportunities or other services that promise high
                    rewards
                  </td>
                </tr>
                <tr>
                  <td>
                    Mug shot publication or pay-to-remove sites
                  </td>
                  <td>
                    Platforms that facilitate the publication and removal of
                    content (such as mug shots), where the primary purpose of
                    posting such content is to cause or raise concerns of
                    reputational harm
                  </td>
                </tr>
                <tr>
                  <td>
                    No-value-added services
                  </td>
                  <td>
                    Sale or resale of a service without added benefit to the
                    buyer; resale of government offerings without authorization
                    or added value; sites that we determine in our sole
                    discretion to be unfair, deceptive, or predatory towards
                    consumers
                  </td>
                </tr>
              </tbody>
            </table>

            <h2
              id="products-or-services-that-are-otherwise-restricted-by-our-financial-partners"
            >
              Products or services that are otherwise restricted by our
              financial partners<a
                class="anchor"
                href="#products-or-services-that-are-otherwise-restricted-by-our-financial-partners"
              ></a>
            </h2>
            <table>
              <tbody>
                <tr>
                  <td style="width:30%;">
                    Aggregation
                  </td>
                  <td>
                    Engaging in any form of licensed or unlicensed aggregation
                    of funds owed to third parties, factoring, or other
                    activities intended to obfuscate the origin of funds
                  </td>
                </tr>
                <tr>
                  <td>
                    Drug paraphernalia
                  </td>
                  <td>
                    Any equipment designed for making or using drugs, such as
                    bongs, vaporizers, and hookahs
                  </td>
                </tr>
                <tr>
                  <td>
                    High risk businesses
                  </td>
                  <td>
                    Bankruptcy lawyers; computer technical support; travel
                    reservation services and clubs; airlines; cruises;
                    timeshares; prepaid phone cards, phone services, and cell
                    phones; telemarketing, telecommunications equipment and
                    telephone sales; drop shipping; forwarding brokers; negative
                    response marketing; credit card and identity theft
                    protection; the use of credit to pay for lending services;
                    any businesses that we believe poses elevated financial
                    risk, legal liability, or violates card network or bank
                    policies; any business or organization that a. engages in,
                    encourages, promotes or celebrates unlawful violence or
                    physical harm to persons or property, or b. engages in,
                    encourages, promotes or celebrates unlawful violence toward
                    any group based on race, religion, disability, gender,
                    sexual orientation, national origin, or any other immutable
                    characteristic
                  </td>
                </tr>
                <tr>
                  <td>
                    Multi-level marketing
                  </td>
                  <td>
                    Pyramid schemes, network marketing, and referral marketing
                    programs
                  </td>
                </tr>
                <tr>
                  <td>
                    Pseudo pharmaceuticals
                  </td>
                  <td>
                    Pharmaceuticals and other products that make health claims
                    that have not been approved or verified by the applicable
                    local and/or national regulatory body, or make specific
                    claims about weight loss or improved sexual performance
                  </td>
                </tr>
                <tr>
                  <td>
                    Social media activity
                  </td>
                  <td>
                    Sale of Twitter followers, Facebook likes, YouTube views,
                    and other forms of social media activity
                  </td>
                </tr>
                <tr>
                  <td>
                    Substances designed to mimic illegal drugs
                  </td>
                  <td>
                    Sale of a legal substance that provides the same effect as
                    an illegal drug (e.g., salvia, kratom)
                  </td>
                </tr>
                <tr>
                  <td>
                    Video game or virtual world credits
                  </td>
                  <td>
                    Sale of in-game currency or game items, unless the merchant
                    is the operator of the virtual world
                  </td>
                </tr>
                <tr>
                  <td>
                    Use of Stripe in a manner inconsistent with its intended use
                    or as expressly prohibited in the Stripe Services Agreement
                  </td>
                  <td>
                    Use of Stripe principally as a virtual terminal (e.g.,
                    submitting card transactions by manually inputting card
                    information); processing where there is no bona fide good or
                    service sold, or donation accepted; card testing; evasion of
                    card network chargeback monitoring programs; sharing
                    cardholder information with another merchant for payment
                    cross-sell product or service
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stripeTerms",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
</style>
