<template>
  <div>
    <v-dialog v-model="showModalStripeOrPaypal" persistent max-width="600px">
      <v-card>
        <HeaderModals
          :titleModal="$t('plans.payments.choosePaymentPlatform')"
        ></HeaderModals>
        <v-card-text>
          <v-container>
            <div class="paymentRow">
              <div
                class="paymentLogoRow"
                @click="
                  subscribeWithPaypal();
                  closeModal();
                "
              >
                <v-img
                  class="paymentLogo logoPayPal"
                  src="img/PP_Color_Primary.png"
                />
              </div>

              <div
                class="paymentLogoRow"
                @click="
                  subscribeWithStripe();
                  closeModal();
                "
              >
                <v-img
                  class="paymentLogo logoStripe"
                  src="img/StripeLogo.svg.png"
                />
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeModal">
            {{ $t("paymentModals.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
export default {
  name: "StripeOrPaypalModal",
  components: {HeaderModals},
  props: ["showModalStripeOrPaypal", "subscribeWithStripe", "subscribeWithPaypal", "closeModal"],
  data: () => ({

  }),
  watch:{

  },
  mounted(){
    
  },
  methods:{

  },
  computed:{

  }
};

</script>
<style scoped>
.paymentLogoRow {
  width: 125px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px 15px;
  border: 1px solid rgb(25, 118, 210);
  border-radius: 5%;
  cursor: pointer;
}
.paymentRow {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
</style>