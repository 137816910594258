const AIRTIMEMODULEURL = "https://bittubeapp.com/tubepay/airtime.loader.js";
// const AIRTIMEMODULEURL = "http://localhost:5000/tubepay/airtime.loader.js";
// const AIRTIMEMODULEURL = "https://cdn.jsdelivr.net/gh/ipbc-dev/airtime-module-dist@1.1.6-tube4/tubepay/airtime.loader.js"; //Static loader
const PLATFORMUUID = "e43aa929-f809-e73c-a2f9-2b3e2dec3fab";
// const PLATFORMUUID = "c9f97913-59d2-e0bd-8283-998ced405c7b"; //easymeet-dev

export function injectAirtimeScript() {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");
      script.setAttribute("data-verify", "none");
      script.setAttribute("data-autostart", "false");
      script.src = AIRTIMEMODULEURL;

      script.onload = () => {
        // console.debug('Script on load !!!!! ')
        resolve();
      };
      script.onerror = error => {
        // console.debug('Airtime Script on error', error)
        reject(error);
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    } catch (err) {
      reject(err);
    }
  });
}

async function setAirtimeContentID() {
  await _airtimeReadyPromise;
  const setupData = {
    contentName: null,
    contentDisplayName: null,
    platformUUID: PLATFORMUUID,
    platformDisplayName: "easymeet.me"
  };
  console.debug("== AIRTIME SETTING CONTENT ID ==", setupData);
  window.airtime.setup(setupData);
  return setupData;
}

let retries = 0;
function trySetup() {
  if (window.airtime) {
    console.debug("== AIRTIME SETUP ==");
    _airtimeReadyHolder.resolve();
    setAirtimeContentID();
  } else {
    console.debug("== AIRTIME SETUP WAITING ==");
    if (retries < 10) {
      retries++;
      setTimeout(trySetup, 10 * retries);
    } else {
      console.warn("== AIRTIME SETUP TOO MANY RETRIES ==");
      _airtimeReadyHolder.reject();
    }
  }
}

const _airtimeReadyHolder = {};
const _airtimeReadyPromise = new Promise((resolve, reject) => {
  _airtimeReadyHolder.resolve = resolve;
  _airtimeReadyHolder.reject = reject;
});

document.addEventListener("airtime-loaded", event => {
  console.debug("== AIRTIME LOADED ==", event, window.airtime);
  setTimeout(trySetup, 10);
});

document.addEventListener("airtime-loaded-error", event => {
  console.warn("== AIRTIME LOADING ERROR ==", event);
  _airtimeReadyHolder.reject();
});
