<template>
  <div>
    <v-dialog
      v-model="showModalAddContact"
      persistent
      max-width="650px"
      :fullscreen="isMobile"
    >
      <v-card>
        <HeaderModals
          :titleModal="$t('profile.addressBook.addNew')"
        ></HeaderModals>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <v-text-field
                  v-model="titel"
                  dense
                  outlined
                  hide-details
                  :label="'Titel'"
                ></v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <v-text-field
                  v-model="first_name"
                  dense
                  outlined
                  hide-details
                  :label="$t('profile.addressBook.firstname') + '*'"
                  required
                  maxLength="20"
                ></v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <v-text-field
                  v-model="last_name"
                  dense
                  outlined
                  hide-details
                  :label="$t('profile.addressBook.lastname') + '*'"
                  required
                  maxLength="20"
                ></v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  hide-details
                  :label="$t('profile.addressBook.email') + '*'"
                  required
                  :rules="emailRules"
                  validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <vue-tel-input
                  v-model="phone"
                  defaultCountry="ES"
                  enabledCountryCode
                  @onInput="onTelInput"
                  name="number"
                  id="numDisplay"
                  class="focusStyle form-control text-center input-sm inputPhone2FA"
                  value
                  placeholder
                  autocomplete="off"
                  :class="{ borderError: !phoneInputValid && phone !== '' }"
                ></vue-tel-input>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="pb-0">
                <v-checkbox
                  class="mt-0"
                  v-model="hasWhatsapp"
                  :label="$t('profile.addressBook.has-whatsapp')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <p v-if="messageFound" class="mb-0 mt-3 text-left errorText">
              {{ $t("profile.addressBook.already-exists") }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              closeModalAddNewContact();
              clearFields();
            "
          >
            {{ $t("paymentModals.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="contactExists()"
            :loading="loading"
            :disabled="disableSaveContact"
          >
            {{ $t("paymentModals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HeaderModals from "./commons/headerModals.vue";
import { isMobile } from "./../../utils";
/* eslint-disable */
export default {
  components: { HeaderModals },
  name: "addContactModal",
  props: ["showModalAddContact", "closeModalAddNewContact", "insertContact", "loading", "contacts"],
  data: () => ({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    emailRules: [
        v => !!v || "E-mail is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail is invalid"
      ],
    creatingLink: false,
    messageFound: false,
    phoneNumberParsed: "",
    phoneInputValid: true,
    hasWhatsapp: false,
    titel: '',
    isMobile: isMobile()
  }),
  methods:{
    onTelInput({ number, isValid, country }) {
        if ( this.phone !== "" ){
            
            if (isValid) {
                this.phone = number.international.replace(/\s/g, "");
            }
            this.phoneInputValid = isValid;
        }else{
            this.phoneInputValid = true;
        }
    },
    clearFields(){
        this.first_name = "";
        this.last_name = "";
        this.email = "";
        this.phone = "";
        this.messageFound = false;
        this.titel = "";
    },
    contactExists(){
        let exists = false;
        this.messageFound = false;
        if ( this.contacts.length ){
            this.contacts.forEach(contact => {
                if ( contact.email == this.email || (this.phone !== '' &&  contact.phone == this.phone) ){
                    exists = true;
                }
            });
        }
        if ( exists ){
            this.messageFound = true;
        }else{
            this.messageFound = false;
            this.insertContact(this.first_name, this.last_name, this.email, this.phone, this.hasWhatsapp, this.titel);
            this.clearFields();
        }
    }
    
  },
  computed:{
    isValidEmail(){
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.email
          )
    },
    user(){
      return this.$store.state.user.user
    },
    disableSaveContact(){
        return this.first_name == "" || this.last_name == "" || this.email == "" || !this.phoneInputValid || !this.isValidEmail;
    },
  }
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
.copiedAlert{
  position: absolute;
  top: 15px;
  right: 36px;
  margin: 0;
  width: auto;
  z-index: 999;
}
.errorText{
    color: red;
}
.borderError {
   border-color: red !important;
}
</style>

<style lang="scss">
#numDisplay{
    .vti__dropdown-list.below{
      width: 277px;
      max-height: 103px;
    }
}

.vue-tel-input{
  font-size: 16px;
}
</style>
