<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            v-if="!isRaMicro"
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>
          <v-img
            v-else
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>

          <!-- <p class="mb-0 headline">
            {{ $t("payPal.cancelRequest") }}
          </p> -->
          <p class="text-center headline" cols="12">
            {{ $t("plans.payments.paymentSuccessful") }}
          </p>
          <v-btn
            :disabled="!enableAcceptButton"
            color="primary"
            @click="$router.push({ path: `/profile` })"
          >
            {{ $t("plans.payments.accept") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { getFirebaseToken } from "../../firebase";
import { isRaMicro } from "../../utils.js";
export default {
  data: () => ({
    retryies: 5,
    enableAcceptButton: false,
    isRaMicro: isRaMicro()
  }),
  name: "StripeSuccess",
  mounted() {
    this.refreshUserPlan();
    this.requestPromise = getFirebaseToken().then(token =>
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/recordUserFirstSubscriptionStat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    );
  },
  components: {},
  methods: {
    refreshUserPlan() {
      getFirebaseToken(true).then(() => {
        const user = this.$store.state.user.user;
        if (user) {
          Promise.all([
            this.$store.dispatch("USER_STRIPE_INVOICE_FETCH", {
              user,
              isRaMicro: this.isRaMicro
            }),
            this.$store.dispatch("USER_STRIPE_SUBSCRIPTIONS_FETCH", {
              user,
              isRaMicro: this.isRaMicro
            })
          ]).then(() => {
            this.enableAcceptButton = true;
          });
        } else {
          if (this.retryies > 0) {
            this.retryies--;
            setTimeout(() => {
              this.refreshUserPlan();
            }, 1000);
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
</style>
