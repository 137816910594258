<template>
  <div class="recoveryPassword">
    <RecoveryPassword />
  </div>
</template>

<script>
// @ is an alias to /src
import RecoveryPassword from "@/components/recoveryPassword.vue";

export default {
  name: "recoveryPassword",
  components: {
    RecoveryPassword
  }
};
</script>
<style scoped lang="scss">
.recoveryPassword {
  background-color: #fff;
  word-break: break-word;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // background-image: url(../assets/d_bg.svg);
  // background-size: auto;
  // background-repeat: no-repeat;
  // background-position: left;
}
</style>
