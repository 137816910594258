<template>
  <v-container class="containerStartPage">
    <!-- <Header /> -->
    <v-row class="text-center">
      <div class="room-selection text-left">
        <p class="mb-6">
          {{ $t("generics.enter-email-for-pw-recover") }}
        </p>
        <v-form v-model="valid" lazy-validation>
          <v-row class="mx-0">
            <v-col cols="12" class="px-0">
              <v-text-field
                :label="$t('generics.email')"
                required
                solo
                dense
                hide-details
                v-model="email"
                @focus="clearErrors"
                :rules="emailRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-btn
                depressed
                color="primary"
                class="w-100"
                :loading="loading"
                @click="resetPassword"
                :disabled="!valid"
              >
                {{ $t("generics.send-email") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="px-0 text-center">
              <p class="mb-0">
                <router-link to="/login">
                  {{ $t("generics.go-back") }}
                </router-link>
              </p>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-alert
                :value="hasError && hasRequestMail"
                type="error"
                class="genericAlerts"
              >
                {{ error }}
              </v-alert>
              <v-alert
                :value="hasSuccess && hasRequestMail"
                type="success"
                class="genericAlerts"
                v-html="$t('generics.forgotPassEmail')"
              >
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { isMobile } from "../utils";
/* eslint-disable */
export default {
  name: "ForgotPassword",
  components: {  },
  data: () => ({
    email: '',
    isMobile: isMobile(),
    success: false,
    valid: false,
    emailRules: [
        v => !!v || "E-mail is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail is invalid"
      ],
    hasRequestMail: false
  }),
  mounted() {
    this.clearErrors();
    this.hasRequestMail = false;
  },
  methods: {
    clearErrors(){
      if (this.error) {
        this.$store.commit("USER_CLEAR_REQUEST");
      }
    },
    resetPassword() {
      this.clearErrors();
      this.hasRequestMail = true;
      let lang = navigator.language || navigator.userLanguage
      this.$store.dispatch("USER_FORGOT_PASSWORD", {
        email: this.email,
        languageCode: lang.slice(0,2),
      });
    }
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.$router.replace("/");
      }
    },
  },
  computed:{
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess(){
      return !!this.$store.state.user.user_success;
    }
  }
};

</script>

<style scoped lang="scss">
.w-100{
  width: 100%;
}
.room-selection {
  margin: 0 auto 0 auto; 
  padding: 1em 1.5em 1.3em 1.5em;
  max-width: 400px;
  position: relative;
  top: 5em;
}
</style>
