<template>
  <div class="help">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container">
          <h1 class="text-left">Help</h1>
          <div class="paypal-tutorial__wrapper">
            <h2 class="paypal-tutorial__title">
              {{ $t("pp-tutorial-title") }}
            </h2>
            <div class="paypal-tutorial__row">
              <div class="paypal-tutorial__items paypal-tutorial__item-1">
                <img src="../../assets/PP-MockUp.png" alt="PayPal" />
              </div>
              <div class="paypal-tutorial__items paypal-tutorial__item-2">
                <div class="paypal-tutorial__item-inner-container">
                  <h3
                    class="text-center"
                    v-html="$t('pp-tutorial-list-title')"
                  ></h3>
                  <p v-html="$t('pp-tutorial-list-1')"></p>
                  <p>{{ $t("pp-tutorial-list-2") }}</p>
                  <p>{{ $t("pp-tutorial-list-3") }}</p>
                  <ul class="paypal-tutorial__list">
                    <li>{{ $t("pp-tutorial-list-4") }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="paypal-tutorial__row">
              <div class="paypal-tutorial__items paypal-tutorial__item-1">
                <ul class="paypal-tutorial__list">
                  <li>{{ $t("pp-tutorial-list-5") }}</li>
                  <li>{{ $t("pp-tutorial-list-6") }}</li>
                </ul>
              </div>
              <div class="paypal-tutorial__items paypal-tutorial__item-2">
                <img
                  src="../../assets/PayPal-App-Activation.jpg"
                  alt="PayPal"
                />
              </div>
            </div>
            <div class="paypal-tutorial__row">
              <div class="paypal-tutorial__items paypal-tutorial__item-1">
                <ul class="paypal-tutorial__list">
                  <li v-html="$t('pp-tutorial-list-7')"></li>
                </ul>
              </div>
              <div class="paypal-tutorial__items paypal-tutorial__item-2">
                <img src="../../assets/PayPal-SCLIP.jpg" alt="PayPal" />
              </div>
            </div>
            <div class="paypal-tutorial__row">
              <div class="paypal-tutorial__items paypal-tutorial__item-1">
                <img src="../../assets/PaymentModal.png" alt="PayPal" />
              </div>
              <div class="paypal-tutorial__items paypal-tutorial__item-2">
                <ul class="paypal-tutorial__list">
                  <li>{{ $t("pp-tutorial-list-8") }}</li>
                </ul>
                <img src="../../assets/PayPal-ClientID.jpg" alt="PayPal" />
              </div>
            </div>
          </div>
          <div class="faq">
            <h2 class="faq__title text-left mb-4">FAQ</h2>
            <h3 class="text-left my-4">{{ $t("faq-security") }}</h3>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4 v-html="$t('faq-security-1-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p
                    class="text-left"
                    v-html="$t('faq-security-1-content-1')"
                  ></p>
                  <p class="text-left">{{ $t("faq-security-1-content-2") }}</p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4 v-html="$t('faq-security-2-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p
                    class="text-left"
                    v-html="$t('faq-security-2-content')"
                  ></p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4>{{ $t("faq-security-3-title") }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p
                    class="text-left"
                    v-html="$t('faq-security-3-content')"
                  ></p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4 v-html="$t('faq-security-4-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p
                    class="text-left"
                    v-html="$t('faq-security-4-content')"
                  ></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <h3 class="text-left my-4">{{ $t("faq-setup") }}</h3>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4>{{ $t("faq-setup-1-title") }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left" v-html="$t('faq-setup-1-content')"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4>{{ $t("faq-setup-2-title") }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left">{{ $t("faq-setup-2-content") }}</p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4>{{ $t("faq-setup-3-title") }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left">{{ $t("faq-setup-3-content") }}</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <h3 class="text-left my-4">{{ $t("faq-usage") }}</h3>
              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py'-2 px-2">
                  <h4 v-html="$t('faq-usage-1-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left" v-html="$t('faq-usage-1-content')"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4 v-html="$t('faq-usage-2-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left" v-html="$t('faq-usage-2-content')"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header class="py-2 px-2">
                  <h4 v-html="$t('faq-usage-3-title')"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content clas="text-left">
                  <p class="text-left" v-html="$t('faq-usage-3-content')"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "help",
  components: {},
  data() {
    return {
      panel: 0
    };
  },
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";

.paypal-tutorial {
  &__row {
    display: flex;
    margin-top: 3rem;
  }
  &__title {
    border-bottom: solid 4px #333;
    max-width: fit-content;
    margin-top: 0;
  }
  &__items {
    flex: 1;
    padding: 0 10px;
  }
  &__item {
    &-1 {
      padding-left: 0;
    }
    &-2 {
      padding-right: 0;
    }
  }
}
.faq__title,
.paypal-tutorial .paypal-tutorial__list {
  font-weight: 600;
}

.v-expansion-panel {
  &-content__wrap {
    padding: 15px;
  }
  &-header {
    background: #4285f4;
    color: #fff;
    &--active {
      background-color: #bdc3c7;
      color: #696f6f;
      min-height: 48px;
    }
  }
}
@media screen and (max-width: 992px) {
  .paypal-tutorial {
    &__row {
      margin-top: 1rem;
      flex-direction: column;
    }
    &__item-1 {
      padding-left: 10px;
    }
    &__item-2 {
      padding-right: 10px;
    }
  }
}
</style>
