var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('notifications',{attrs:{"group":"main","duration":-1,"position":"top left"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('font-awesome-icon',{style:({
          fontSize: 17,
          color: '#ffffff',
          position: 'absolute',
          top: '5px',
          right: '15px',
          cursor: 'pointer'
        }),attrs:{"icon":['far', 'times']},on:{"click":function($event){return close()}}}),_c('div',{staticClass:"my-notification",on:{"click":function($event){_vm.openLink(item.data.link);
          close();}}},[(item.title)?_c('div',{staticClass:"notification-title",domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),_c('div',{staticClass:"notification-content",domProps:{"innerHTML":_vm._s(item.text)}})])]}}])}),_c('v-main',{class:{ inCall: _vm.isInCall }},[(_vm.loading)?_c('v-progress-linear',{attrs:{"absolute":"","color":"primary accent-4","indeterminate":"","rounded":"","height":"5"}}):_vm._e(),_c('ios-banner',{attrs:{"showBanner":_vm.showBanner,"closeBanner":_vm.closeBanner}}),(_vm.showHeader && !_vm.loading)?_c('Header'):_vm._e(),_c('router-view'),(_vm.showFooter)?_c('Footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }