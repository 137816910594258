<template>
  <div class="terms">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container">
          <h1 v-if="!isRaMicro">Terms</h1>

          <section v-if="!isRaMicro">
            <div>
              <h2>General terms and conditions</h2>
              <h3>Last Updated: April 8, 2020</h3>
              <h3>1. SCOPE &amp; APPLICATION</h3>
              <p>
                1.1. These terms of sale (&#8220;Terms&#8221;) apply to all
                purchases of software (&#8220;Software&#8221;) or services
                (&#8220;Services&#8221;) which are sold through this website
                (&#8220;Site&#8221;) by: (a) Us, the Seller,
                <strong>easy</strong>meet (references to &#8220;us&#8221;,
                &#8220;we&#8221; or &#8220;our&#8221; being construed
                accordingly) to (b) you, the purchaser (references to
                &#8220;you&#8221; or &#8220;your&#8221; being construed
                accordingly).
              </p>
              <p>
                1.2. By placing an order on this Site you agree to abide by
                these Terms. If you are a &#8216;consumer&#8217; (purchasing
                outside the scope of your business), then nothing in these Terms
                will reduce your statutory rights relating to faulty or
                misdescribed Products or Services. For further information about
                your statutory rights contact your local authority Trading
                Standards Department or Citizens&#8217; Advice Bureau.
              </p>
              <p>
                1.3. If you are a consumer, you may cancel your order for any
                reason within 14 calendar days of:
              </p>
              <p>1.3.1. Receipt of Product(s).</p>
              <p>
                1.3.2. Our acceptance of your order for Services. But such right
                to cancel shall end or not apply where:
              </p>
              <p>1.3.3. You have received all of the Services ordered; or</p>
              <p>1.3.4. You unseal or use any Software; or</p>
              <p>
                1.3.5. You receive the download link for a digital Product,
                where you have been notified during the purchase process and
                given your consent to your right to cancel ending on receipt of
                the download link; or
              </p>
              <p>
                1.3.6. Bespoke or customised Software are made specifically to
                your order.
              </p>
              <p>
                1.4. To exercise your right to cancel please email us at
                &#x6f;&#102;&#102;&#x69;&#x63;&#101;&#64;&#x6d;&#x61;&#114;&#97;&#x6d;&#x2d;&#99;&#111;&#x6e;&#x73;&#117;&#108;&#x74;&#x69;&#110;&#103;&#x2e;&#x63;&#111;&#109;,
                you may also use the Cancellation Form wherever provided.
              </p>
              <p>
                1.5. Where you have ordered Services and exercise your right to
                cancel after the provision of the Services has commenced then we
                will deduct from your refund a amount in respect of the Services
                provided prior to receiving your notice to cancel proportional
                to the total Services ordered.
              </p>
              <p>
                1.6. If you require more information on your rights, you should
                contact customer support
              </p>
              <h3>2. OFFER, ACKNOWLEDGMENT AND ACCEPTANCE</h3>
              <p>
                2.1. Any prices, quotations and descriptions made or referred to
                on this Site are subject to availability, do not constitute an
                offer and may be withdrawn or revised at any time prior to our
                express acceptance of your order (as described below).
              </p>
              <p>
                2.2. While we make every effort to ensure that digital products
                appearing on the Site are available, we cannot guarantee that
                all digital products are immediately available when you submit
                your order. We may reject your order if we are unable to process
                or fulfil it. If this is the case, we will refund any prior
                payment that you have made for that item.
              </p>
              <p>
                2.3. An order submitted by you (when you click the ëBuyí button)
                constitutes an offer by you to us to purchase Software or
                Services on these Terms and is subject to our subsequent
                acceptance.
              </p>
              <p>
                2.4. You may receive an automatic e-mail acknowledgement of your
                order. This does not constitute our acceptance of your order.
              </p>
              <p>
                2.5. Our acceptance of your order takes place when we agree to
                provide or a third party service provider begins to provide the
                Services to you. (&#8220;Acceptance&#8221;).
              </p>
              <p>
                2.6. In the case of Services please note that we are not the
                provider of the Services purchased from the Site. These Services
                will be provided by either our partner whose name and logo
                appears on the Site or by third party service providers and your
                relationship with them may be subject to additional terms and
                conditions which they will provide to you or require you to
                agree to before you receive the Service.
              </p>
              <h3>3. YOUR REPRESENTATIONS</h3>
              <p>
                3.1. You should take care to ensure that all information that
                you provide when placing your order is up to date, accurate and
                sufficient for us to fulfil your order.
              </p>
              <p>
                3.2. By placing an order you represent that you have legal
                capacity to enter into a contract.
              </p>
              <p>
                3.3. You should ensure that your account information which is
                kept with us is accurate and updated as appropriate. Such
                information (and any passwords given to you for the purposes of
                accessing the Site and/or purchasing Software) should be kept
                secure against unauthorised access
              </p>
              <h3>4. PRICE AND TERMS OF PAYMENT</h3>
              <p>
                4.1. Prices payable for Software or Services are those in effect
                at the time of Acceptance. Prices may be indicated on the Site
                or an order acknowledgement but we will notify you of the final
                sale price at the time of our Acceptance.
              </p>
              <p>
                4.2. We have the right at any time prior to our Acceptance to
                withdraw any discount and/or to revise prices to take into
                account increases in costs including (without limitation) costs
                of any materials, carriage, labour or the increase or imposition
                of any tax, duty or other levy and any variation in exchange
                rates. We also reserve the right to notify you of any mistakes
                in Software descriptions or errors in pricing prior to download
                or Service provision.
              </p>
              <p>
                4.3. If the price of the Software or Service increases or we
                change the description of the Software or Service between the
                time that you place your order and the time of our Acceptance,
                we will notify you, you may cancel your order and you will be
                given a full refund. If you choose to proceed with your order it
                will be delivered to you in accordance with the revised
                description and/or at the increased price.
              </p>
              <p>
                4.3.1. Exclusive of VAT and any other tax or duty which (where
                applicable) must be added to the price payable;
              </p>
              <p>
                4.4. We will charge credit or debit cards prior to making
                available for download of the Software or commencement of
                Services. We reserve the right to verify credit or debit card
                payments prior to Acceptance. Methods of payment accepted by us
                can be found on the Site.
              </p>
              <p>
                4.5. If you are a business customer (purchasing within the scope
                of your business) we may agree to invoice you in advance, or
                extend credit for the purchase of Softwares and/or Services from
                the Site. In such cases the following Terms shall apply to you:
              </p>
              <p>
                4.5.1. Where the payment is invoiced, each invoice shall be due
                on and made in full within thirty (30) days of the date of
                relevant invoice. If at any time you fail to pay any amount due
                on the relevant due date, we may by notice declare all invoiced
                amounts unpaid at that date to be immediately due and payable.
              </p>
              <p>
                4.5.2. No counterclaim or set-off may be deducted by you from
                any payment due without our written consent. We may also take
                action against you for the price of Software at any time after
                payment has become due.
              </p>
              <p>
                4.5.3. Any extension of credit allowed to you may be changed or
                withdrawn at any time. You shall indemnify us on demand against
                any out of pocket expenses incurred in relation to recovery of
                any overdue amounts.
              </p>
              <h3>5. TERMINATION</h3>
              <p>
                If you become bankrupt or insolvent or enter into a voluntary
                arrangement with creditors or you take any similar action or
                such action is taken against you in relation to your debts; or
                you fail to pay any amount by the due date, we may, in respect
                of any Software or Services for which payment has not already
                been received by us:
              </p>
              <p>5.1. Stop or suspend provision of Services; and/or</p>
              <p>
                5.2. By written notice terminate your order and all or any other
                contracts between us and you.
              </p>
              <h3>6. SOFTWARE</h3>
              <p>
                Where any software is provided (&#8220;Software&#8221;), this
                Software is licensed by us or by the relevant licensor/owner
                subject to the relevant end-user licence agreement or other
                licence terms included with Software (&#8220;Licence
                Terms&#8221;). In addition:
              </p>
              <p>
                6.1. If you are a business customer your rights of return and/or
                to a refund under these Terms and any applicable Returns Policy
                do not apply in the event that you open the Software
                shrink-wrap, break the license seal or use the Software.
              </p>
              <p>
                6.2. If you are a business customer and except to the extent
                expressly provided by us in writing or under relevant License
                Terms, Software is provided &#8216;as is&#8217; without any
                warranties, terms or conditions as to quality, fitness for
                purpose, performance or correspondence with description and we
                do not offer any warranties or guarantees in relation to
                Software installation, configuration or error/defect correction.
                You are advised to refer to any License Terms with regards to
                determining your rights against a manufacturer, licensor or
                supplier of the Software.
              </p>
              <h3>7. LIABILITY LIMITATION</h3>
              <p>
                7.1. Our liability under these Terms (whether for negligence,
                breach of contract, misrepresentation or otherwise) shall be
                limited to the price payable for the Software or Services which
                are the subject of that order.
              </p>
              <p>
                7.2. If you are a consumer in addition to our liability under
                7.1 above we will also be liable to you for losses that you
                suffer which are a foreseeable consequence of us breaching these
                Terms. Losses are foreseeable where they could be contemplated
                by you and us at the time of Acceptance.
              </p>
              <p>
                7.3. In no case shall we be liable for incidental, indirect, or
                consequential damages; or any loss of income, revenue, profits,
                contracts, data, goodwill or savings.
              </p>
              <p>
                7.4. Subject to any express provisions to the contrary set out
                in these Terms, all terms, conditions, warranties and
                representations (express or implied by statute, common law or
                otherwise) are hereby excluded.
              </p>
              <p>
                7.5. In no case do we seek to exclude our liability for (1)
                death or personal injury caused by our negligence (or that of
                our employees or authorised representatives); (2) fraud; (3) any
                breach of any obligation as to title to the Software implied by
                statute; or (4) any other liability which cannot as a matter of
                law be limited or excluded.
              </p>
              <h3>8. THIRD PARTY RIGHTS</h3>
              <p>
                You shall be responsible for and reimburse us for all expenses
                which we suffer liabilities, arising as a result of our
                performing Services or carrying out any work on or to the
                Software to your specific requirements or specifications which
                you have requested in the Software or Services which including
                for example where any third party brings a claim or action
                against us alleging infringement of its proprietary rights.
              </p>
              <h3>9. WARRANTY</h3>
              <p>
                9.1. All Software specifications, illustrations, drawings,
                performance data and other information on the Site or made
                available by us are intended to represent no more than a general
                illustration of the Products and do not constitute a warranty or
                representation by us that the Software will conform with the
                same. You must refer to the developer&#8217;s specifications or
                warranty documentation to determine your rights and remedies in
                this regard.
              </p>
              <p>
                9.2. You may have the benefit of a developer&#8217;s,
                licensor&#8217;s or supplier&#8217;s warranty with the Software
                provided and you should refer to the relevant documentation of
                the Software or Service in this regard.
              </p>
              <h3>10. CONSENTS</h3>
              <p>
                If any licence or consent is required for your use of the
                Software, you shall obtain such licence or consent at your own
                expense and if necessary produce evidence of having done so to
                us. Where you are a business customer and you have failed so to
                do so you shall not be entitled you to withhold or delay
                payment. Any additional expenses or charges incurred by us
                resulting from your failure to obtain any necessary licence or
                consent shall be met by you.
              </p>
              <h3>11. NOTICES</h3>
              <p>
                If you wish to contact us for any reason in connection with
                these Terms you may do so using the contact details set out in
                clause 1.4 above.
              </p>
              <p>12.PERSONAL INFORMATION AND YOUR PRIVACY</p>
              <p>
                12.1. We comply with all data protection laws and will use data
                which could be used to identify you personally (&#8220;Personal
                Data&#8221;) only as set out in our Privacy Statement
                (&#8220;Privacy Statement&#8221;).
              </p>
              <p>
                12.2. In relation to security of orders that you place with us:
              </p>
              <p>
                12.2.1. If your bank or card issuer holds you liable for charges
                in relation to unauthorised use of your debit or credit card,
                connected with the use of your credit or debit card on our Site
                we will cover your liability up to Ä50.00 (EURO) provided that
                the unauthorised use of your credit or debit card was not caused
                by your own fraud or negligence (or by someone acting with your
                authority).
              </p>
              <h3>13. GENERAL</h3>
              <p>
                13.1. You may not transfer your rights under these Terms to
                anyone else without our prior written consent.
              </p>
              <p>
                13.2. You recognise that there may be circumstances outside our
                reasonable control which would prevent us from providing you
                with the Software or Services which you have ordered.
                Circumstances beyond our reasonable control may include (but are
                not limited to) any act of God, war, riot, civil commotion,
                government action, explosion, fire, flood, storm, accident,
                strike, lock-out, trade dispute or labour disturbance, breakdown
                of plant or machinery, interruption in the supply of power or
                materials. In such cases, whilst we will do our best to fulfil
                your order, we cannot be responsible for failure or delay in
                doing so and in such event we may elect to cancel your order and
                refund any payments made. You may request that your order be
                cancelled if it has not been fulfilled within 30 days of having
                been made.
              </p>
              <p>
                13.3. These Terms set out all of our obligations in relation to
                of provision of Software and Services to you under any accepted
                order. These Terms prevail over any other terms or conditions
                referred to elsewhere or otherwise implied. We reserve the right
                to modify these Terms from time to time and any order will be
                subject to the Terms that are in place at the time that your
                order is accepted.
              </p>
              <p>
                13.4. No delay by either you or us in enforcing any of these
                Terms shall restrict the rights that either of us has under
                them.
              </p>
              <p>
                13.5. No waiver of any term or condition of these Terms shall be
                effective unless made in writing and signed by us.
              </p>
              <p>
                13.6. If a court of competent jurisdiction finds that any
                provision or portion of these Terms to be illegal,
                unenforceable, or invalid the remainder of these Terms (to the
                fullest extent permitted by law) will continue in full force and
                effect.
              </p>
              <h3>14. GOVERNING LAW AND JURISDICTION</h3>
              <p>
                14.1. These Terms shall be governed by Spanish Law and we both
                agree to the non exclusive jurisdiction of the courts of
                Switzerland over the Terms and any matter related to them, and
                all proceedings shall be conducted in English.
              </p>
              <p>
                14.2. If you are a consumer and you live outside Spain you may
                be entitled to bring court proceedings in your own language and
                in your local courts, a local consumer advice organisation will
                be able to advise you of your rights.
              </p>
              <p></p>
            </div>
          </section>
          <h1 v-if="isRaMicro">Allgemeine Geschäftsbedingungen</h1>
          <section v-if="isRaMicro">
            <p>
              <strong>Nationale und internationale VERTRAGSBEDINGUNGEN</strong>
            </p>
            <p>
              vOffice SE Berlin (vOffice)&nbsp;- veröffentlicht nachfolgend ihre
              Nutzungsbedingungen für ihre Software-und Online-Produkte, kurz
              „<strong>PRODUKTE</strong>“ der vOffice SE (vSystem, vViewer,
              iTrendUp) somit die allgemeinen Geschäftsbedingungen für das
              Zurverfügungstellen von Daten-und Softwarediensten im Internet und
              auf Mobilgeräten und deren Vertrieb auf den inländischen und
              ausländischen Märkten, im Folgenden kurz
              „<strong>vOffice-DIENSTE</strong>“ genannt.
            </p>
            <p>Datum der letzten Überprüfung: Oktober 2017</p>
            <p>
              Diese Vereinbarung enthält die Bedingungen, die für Ihren Zugriff
              und Ihre Nutzung der &nbsp;PRODUKTE, wie in dieser Vereinbarung
              definiert gelten, soweit nicht durch Spezialregelungen bei den
              einzelnen Produkten ausdrücklich die hier vorliegenden
              Vertragsbedingungen ganz oder teilweise geändert sind. Durch die
              Nutzung von PRODUKTE stimmen Sie den Bedingungen dieser
              Vereinbarung sowie den Datenschutzbestimmungen der vOffice&nbsp;in
              der jeweils geltenden Fassung (siehe Änderungen unten) zu.
            </p>
            <p>1 . Akzeptieren der Bedingungen</p>
            <p>
              Durch die Nutzung der im Rahmen der vOffice-DIENSTE enthaltenen
              Informationen, Tools, Software, Komponenten und Funktionen stimmen
              Sie dieser Vereinbarung verbindlich zu und werden als „Mitglied"
              (d. h., Sie haben sich bei PRODUKTE mit der vOffice-ID
              registriert) angesehen. Der Begriff „Sie" oder Benutzer bezieht
              sich auf ein Mitglied. Wenn Sie ein Mitglied werden und
              vOffice-Dienste nutzen möchten, müssen Sie diese Vereinbarung
              lesen und im Laufe des Registrierungsprozesses ihr Einverständnis
              erklären.
            </p>
            <p>
              Wenn Sie das Mindestalter für den Abschluss eines bindenden
              Vertrages mit vOffice&nbsp;noch nicht erreicht haben, erklären und
              bestätigen Sie, dass Ihr Elternteil oder Erziehungsberechtigter
              diese Nutzungsbedingungen in Ihrem Namen akzeptiert.
            </p>
            <p>
              Durch Akzeptieren dieser Vereinbarung erklären Sie, dass Sie
              geschäftsfähig sind und diesen Vertrag schließen dürfen oder,
              falls Sie im Namen eines Unternehmens oder einer juristischen
              Person handeln, befugt sind, diese juristische Person zu
              verpflichten. Bevor Sie fortfahren, sollten Sie diese Vereinbarung
              ausdrucken und zu Ihren Unterlagen nehmen oder eine Kopie dieser
              Vereinbarung lokal speichern.
            </p>
            <p>2. Datenschutz und Ihre persönlichen Informationen</p>
            <p>
              Informationen zu den Datenschutzpraktiken von vOffice&nbsp;finden
              Sie in den Datenschutzbestimmungen von vOffice&nbsp;(siehe
              Abschnitt 18, Sonstiges). In dieser Richtlinie wird der Umgang
              seitens vOffice&nbsp;mit Ihren persönlichen Informationen
              erläutert, wenn Sie auf PRODUKTE zugreifen und die vOffice-Dienste
              nutzen. Die Richtlinie kann von Zeit zu Zeit nach Ermessen von
              vOffice&nbsp;aktualisiert werden. Änderungen werden mit ihrer
              Bekanntgabe auf der vOffice-Website (www.bittube-int.com) wirksam.
              Im Falle von PRODUKTE-Werbeaktionen sind wir berechtigt, die
              Benutzerkennung und Nutzungsinformationen mit Dritten zu teilen,
              so wie es in Verbindung mit solchen Werbeaktionen kommuniziert
              wird.
            </p>
            <p>3. Beschreibung des vOffice-Dienstes</p>
            <p>
              Die vOffice-Dienste bestehen in einem persönlichen Portal, das es
              Mitgliedern ermöglicht, PRODUKTE als Schnittstelle zu nutzen, um
              automatisch mit dem Benutzerkonto und Kennwort auf
              unterschiedliche Websites und Online-vOffice-Dienste zuzugreifen.
              Abhängig von Ihren Einstellungen ist PRODUKTE in der Lage, auf
              Informationen, die Sie im Rahmen dieser verschiedenen Websites und
              vOffice-Dienste gespeichert haben, zuzugreifen. Auf der Grundlage
              dieser Informationen kann vOffice-Dienste auch Informationen zu
              Produkten oder Dienstleistungen Dritter anbieten.
            </p>
            <p>
              vOffice-Dienste werden Ihnen von vOffice&nbsp;zum persönlichen
              Gebrauch bereitgestellt. vOffice&nbsp;behält sich das Recht vor,
              PRODUKTE entgeltlich oder unentgeltlich zu vertreiben und ein
              Entgelt für die weitere Nutzung des RvOfficeI-Dienstes sowie für
              erweiterte Funktionalitäten, die vOffice&nbsp;von Zeit zu Zeit zur
              Verfügung stellen kann, zu erheben. Die vOffice-Dienste soll Ihnen
              den Zugang und die Nutzung von Daten der PRODUKTE erleichtern. Die
              Zielsetzung von vOffice hinsichtlich des vOffice-Dienstes PRODUKTE
              besteht darin, für einen nahtlosen und transparenten Zugriff auf
              Ihre Daten und sonstige Internetdienste zu sorgen, sobald Sie sich
              bei PRODUKTE anmelden und unabhängig vom verwendeten
              Zugriffsgerät.
            </p>
            <p>4. Kontoinformationen von Drittanbieterwebsites</p>
            <p>
              Mit vOffice-Dienste können Mitglieder gegebenenfalls je nach
              Produkt auch die Funktion Single Sign-On nutzen, um auf
              Informationen, die für sie online von Drittanbieterdiensten, mit
              denen eine Kundenbeziehung besteht oder bei denen Konten
              existieren, verwaltet werden, zuzugreifen oder diese abzurufen.
              Zum Abruf dieser Kontoinformationen kann vOffice mit einem oder
              mehreren Online-vOffice-Dienste-Providern zusammenarbeiten.
              vOffice unternimmt keinerlei Anstrengungen, um die
              Kontoinformationen zu welchem Zweck auch immer zu überprüfen,
              insbesondere im Hinblick auf Richtigkeit, Gesetzmäßigkeit oder die
              Nichtverletzung von Rechten Dritter. vOffice ist nicht
              verantwortlich für die Produkte oder Dienstleistungen, die von,
              auf oder über Drittanbieterwebsites angeboten werden.
            </p>
            <p>
              vOffice kann technische oder anderweitige Schwierigkeiten, die den
              Zugriff unmöglich machen, zum Verlust von Daten oder
              Personalisierungseinstellungen führen oder in sonstigen
              vOffice-Diensteunterbrechungen resultieren können, nicht immer
              vorhersehen oder abwenden. vOffice kann keine Verantwortung für
              die Aktualität, Richtigkeit, Löschung, Unzustellbarkeit oder das
              Unvermögen übernehmen, Benutzerdaten, Mitteilungen oder
              Personalisierungseinstellungen zu speichern.
            </p>
            <p>5. Ihre Registrierungsinformationen</p>
            <p>
              Ihnen ist bewusst und Sie sind damit einverstanden, dass Sie für
              die Aufrechterhaltung der Vertraulichkeit Ihrer
              Kontoinformationen, einschließlich Ihres Kennworts, mit dem Sie
              zusammen mit Ihrer vOffice-ID, E-Mail-Adresse und weiteren
              Sicherheitsfunktionen (wie beispielsweise, aber nicht
              ausschließlich, Sicherheitsfragen - gemeinsam als
              „Sicherheitsdaten" bezeichnet) auf vOffice-Dienste zugreifen
              können, selbst verantwortlich sind. Diese Sicherheitsdaten bilden
              zusammen (gegebenenfalls auch mit Ihrer Mobilfunknummer oder
              sonstigen von Ihnen bereitgestellten Kontaktinformationen) Ihre
              „Registrierungsinformationen". Indem Sie uns Ihre E-Mail-Adresse
              mitteilen, stimmen Sie dem elektronischen Erhalt aller
              erforderlichen Mitteilungen an diese E-Mail-Adresse zu. Ihnen
              obliegt gegebenenfalls die Aktualisierung oder Änderung dieser
              Adresse. Mitteilungen werden im HTML-Format (oder, falls Ihr
              System HTML nicht unterstützt, im Textformat) im Text der E-Mail
              oder über einen Link zur entsprechenden Seite auf unserer Website,
              die über jeden gewöhnlichen und handelsüblichen lnternetbrowser
              aufgerufen werden kann, zur Verfügung gestellt.
            </p>
            <p>
              Sollten Sie von einer unbefugten Benutzung Ihrer
              Registrierungsinformationen Kenntnis erlangen, verpflichten Sie
              sich, vOffice unverzüglich über die E-Mail-Adresse
              <a
                href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#x69;&#x6E;&#102;&#111;&#x40;&#x72;&#97;&#x2D;&#x6D;&#x69;&#99;&#x72;&#x6F;&#45;&#x69;&#110;&#116;&#x65;&#x72;&#x6E;&#97;&#116;&#105;&#111;&#x6E;&#x61;&#x6C;&#x2E;&#99;&#x6F;&#109;"
                >&#x69;&#x6E;&#102;&#111;&#x40;&#x72;&#97;&#x2D;&#x6D;&#x69;&#99;&#x72;&#x6F;&#45;&#x69;&#110;&#116;&#x65;&#x72;&#x6E;&#97;&#116;&#105;&#111;&#x6E;&#x61;&#x6C;&#x2E;&#99;&#x6F;&#109;</a
              >
              davon zu unterrichten.
            </p>
            <p>6. Ihre Nutzung des vOffice-Dienstes</p>
            <p>
              Ihr Recht, auf vOffice-Dienste zuzugreifen und diese zu nutzen,
              ist auf Sie persönlich beschränkt und darf von Ihnen nicht an eine
              andere natürliche oder juristische Person („Person") übertragen
              werden, soweit nichts Abweichendes von vOffice bei den einzelnen
              Produkten ausdrücklich bestimmt ist. Sie dürfen PRODUKTE
              ausschließlich zu rechtmäßigen Zwecken aufrufen und nutzen. Sie
              sind verpflichtet, wahre, fehlerfreie, aktuelle und vollständige
              Anmeldeinformationen (dies gilt ggf., je nach Produkt auch für
              Ihre bei anderen Websites unterhaltenen Konten, sogenannte
              Drittanbieterkonten. Sie dürfen bei den Anmeldeinformationen keine
              unrichtigen Angaben machen. Damit vOffice-Dienste PRODUKTE
              erfolgreich funktioniert, müssen Sie zudem dafür Sorge tragen,
              dass Ihre Anmeldeinformationen korrekt und auf dem aktuellen Stand
              sind. Falls Sie dies unterlassen, wird die Fehlerfreiheit und
              Leistungsfähigkeit von PRODUKTE negativ beeinträchtigt, da
              PRODUKTE dann nicht in der Lage sein wird, gegebenenfalls aus
              betroffenen Drittanbieterkonten aktuelle Informationen zu
              übermitteln.
            </p>
            <p>
              Zugriff und Nutzung von PRODUKTE können zeitweilig aus einem von
              mehreren Gründen unterbrochen sein, einschließlich, aber nicht
              begrenzt auf die Störung der Ausrüstung oder des vOffice-Dienstes,
              Softwarefehler, regelmäßige Aktualisierung, Wartung oder Reparatur
              von PRODUKTE oder aufgrund sonstiger Maßnahmen, die vOffice nach
              alleinigem Ermessen ergreifen kann.
            </p>
            <p>7. Rechte, die Sie uns einräumen</p>
            <p>
              Durch die Übermittlung von Informationen, Daten, Kennwörtern,
              Benutzernamen, PINs, sonstigen Anmeldeinformationen, Materialien
              und anderer Inhalte an vOffice über die bei einzelnen Produkten
              gegebenenfalls genutzten vOffice-Dienste für Single Sign-On
              erteilen Sie vOffice eine Lizenz zur Nutzung dieser Inhalte
              ausschließlich zu dem Zweck, diese „Single
              Sign-On"-vOffice-Dienste bereitzustellen. vOffice darf diese
              Inhalte nutzen und speichern, jedoch nur, um Ihnen die
              vOffice-Dienste von PRODUKTE bereitzustellen. Durch die
              Übermittlung dieser Inhalte an vOffice versichern Sie, dass Sie
              berechtigt sind, diese Inhalte an vOffice zur vertragsgemäßen
              Verwendung zu übermitteln, ohne dass vOffice zur Zahlung von
              Gebühren verpflichtet ist oder sonstige Beschränkungen bestehen,
              einschließlich der Rechte Dritter, die durch die Übermittlung
              dieser Inhalte an vOffice verletzt würden.
            </p>
            <p>
              Bei Nutzung eines vOffice-Produkts mit Drittanbieterkonten und
              vOffice-Diensten über Single Sign-On, erteilen Sie vOffice die
              ausdrückliche Genehmigung, in Ihrem Namen als Ihr Bevollmächtigter
              auf Informationen von Drittanbieterkonten, die von ausgewiesenen
              Dritten unterhalten werden, zuzugreifen. Im Falle einer Nutzung
              der Funktion „Anmeldedaten" des automatischen Anmeldedienstes
              werden Sie direkt mit der Website des von Ihnen benannten
              Drittanbieterkontos verbunden. vOffice übermittelt von Ihnen
              bereitgestellte Informationen wie beispielsweise Benutzernamen und
              Kennwörter, um Sie auf der Website anzumelden und Informationen
              aus dem Drittanbieterkonto für den Zugriff über vOffice zu
              übermitteln. Sie erteilen vOffice hiermit die Genehmigung und
              Erlaubnis zur Nutzung und Speicherung der von Ihnen an den
              automatischen Anmeldedienst übermittelten Informationen (wie
              beispielsweise Kontokennwörter und Benutzernamen), um die
              vorgenannten Handlungen vorzunehmen und den automatischen
              Anmeldedienst so zu konfigurieren, dass er mit den Websites der
              Drittanbieterkonten, für die Sie Ihre Informationen übermitteln,
              kompatibel ist. Zur Verwirklichung des Vertragszweckes dieser
              Vereinbarung und lediglich zur Bereitstellung der
              Kontoinformationen an Sie im Rahmen des vOffice-Dienstes gewähren
              Sie vOffice eine eingeschränkte Vollmacht, verknüpft mit einem
              Rechtsanspruch, und ernennen vOffice zu Ihrem Bevollmächtigten und
              Vertreter, um auf Websites Dritter zuzugreifen und Ihre
              Informationen abzurufen und zu nutzen, und zwar mit der
              uneingeschränkten Befugnis, alle notwendigen Maßnahmen im
              Zusammenhang mit derartigen Aktivitäten zu ergreifen, so wie Sie
              dies auch persönlich tun könnten. Sie erkennen an und sind damit
              einverstanden, dass vOffice beim Zugriff auf und Abruf von
              Kontoinformationen aus Websites von Drittanbieterkonten in Ihrem
              Namen handelt und nicht als Vertreter oder im Namen des Dritten.
              Ihnen ist bewusst und Sie sind damit einverstanden, dass der
              vOffice-Dienste nicht von Dritten, die über PRODUKTE verfügbar
              sind, gesponsert oder unterstützt werden darf.
            </p>
            <p>8. Gewerbliche Schutzrechte</p>
            <p>
              Die Inhalte von PRODUKTE, einschließlich seines besonderen „Look
              and Feel" (z. B. Text, Grafiken, Bilder, Logos und Schaltflächen),
              Fotografien, Funktionen, redaktionelle Inhalte, Anordnung,
              Zusammenstellung, Organisation, Mitteilungen, Software
              (einschließlich HTML-basierter Computerprogramme), Systeme zum
              Zugriff auf Drittanbieterkonten und alle sonstigen zugehörigen
              Materialien, einschließlich damit verbundenem geistigen Eigentum
              welcher Art auch immer, sind ausschließlich Eigentum von vOffice
              und/oder seinen Lizenzgebern oder Lieferanten und sind gemäß den
              anwendbaren Urheberrechts-, Marken- und sonstigen Gesetzen der
              Bundesrepublik Deutschland und anderer Länder geschützt. Die
              Inhalte von PRODUKTE sind Eigentum von vOffice oder seinen
              Software- oder Inhaltsanbietern oder wurden an diese lizenziert.
              vOffice gewährt Ihnen das eingeschränkte Recht PRODUKTE
              entsprechend diesen Bedingungen anzuzeigen und zu nutzen. Sie
              können eine Kopie der auf PRODUKTE zur Verfügung gestellten
              Informationen ausschließlich zu Ihrem persönlichen, internen und
              nicht kommerziellen Gebrauch herunterladen oder ausdrucken. Die
              Verbreitung, der erneute Ausdruck oder die elektronische
              Vervielfältigung jeglicher Inhalte aus PRODUKTE, ganz oder
              teilweise, mit Ausnahme der Daten, die Ihnen gehören und die für
              Ihren Gebrauch über den vOffice-Dienste rechtmäßig aufgerufen und
              gesammelt wurden, sind ohne die vorherige schriftliche Zustimmung
              von vOffice zu einem anderen Zweck ausdrücklich verboten. Alle
              Rechte, die im Rahmen dieser Vereinbarung nicht ausdrücklich
              Benutzern eingeräumt wurden, sind ausschließlich vOffice
              vorbehalten.
            </p>
            <p>9. Zugriff, Störungen und Rechte Dritter</p>
            <p>
              Sie erklären sich damit einverstanden, weder direkt noch indirekt,
              allein oder zusammen mit einer anderen Person:
            </p>
            <p>
              A. Roboter, Spider, Scraper, Deep-Link oder andere ähnliche
              automatisierten Tools, Programme, Algorithmen oder Methoden zur
              Datenerfassung oder -extraktion zu verwenden, um ohne die
              ausdrückliche schriftliche Zustimmung von vOffice, welche nach dem
              alleinigen Ermessen von vOffice vorbehalten werden kann, PRODUKTE
              oder Teile der mit dem vOffice-Dienste in Verbindung stehenden
              Software aufzurufen, sich anzueignen, zu kopieren oder zu
              überwachen;
            </p>
            <p>
              B. Module, Software, Tools, Agents oder sonstige Geräte oder
              Mechanismen (insbesondere Browser, Spider, Roboter, Avatare oder
              intelligente Agents) zu verwenden oder dies zu versuchen, um durch
              PRODUKTE zu navigieren oder PRODUKTE zu durchsuchen, sofern es
              sich dabei nicht um Suchmaschinen und Suchagenten handelt, die vom
              vOffice-Dienste zur Verfügung gestellt werden, oder Webbrowser von
              Drittanbietern, die allgemein verfügbar sind;
            </p>
            <p>
              C. Mit Viren, Würmern, Trojanern infizierte Dateien oder Dateien,
              die sonstige schadhafte oder zerstörerische Funktionen enthalten
              oder auf eine andere Weise die ordnungsgemäße Funktionsweise von
              PRODUKTE oder vOffice-Dienste oder die Nutzung des
              vOffice-Dienstes durch einen Dritten beeinträchtigen, zu posten
              oder zu übertragen;
            </p>
            <p>
              D. Zu versuchen, Software, die einen Teil von PRODUKTE oder des
              vOffice-Dienstes umfasst oder in welcher Weise auch immer
              darstellt, zu entschlüsseln, zu dekompilieren, auseinanderzunehmen
              oder zurückzuentwickeln; oder
            </p>
            <p>
              E. Die vOffice-Dienste in irgendeiner Weise zu nutzen oder in
              irgendeiner Weise auf PRODUKTE zuzugreifen, die nach vernünftigem
              Ermessen nahelegt, dass vOffice-Dienste, PRODUKTE oder die Nutzung
              des vOffice-Dienstes oder von PRODUKTE durch andere Benutzer und
              Gäste gestört wird.
            </p>
            <p>
              F. Sie verpflichten sich, Inhalte, für die Sie nicht über die
              entsprechenden Rechte verfügen, nicht auf oder über vOffice,
              einschließlich unsere Anwendung für Dateidienste, hochzuladen,
              herunterzuladen oder zu posten oder Handlungen auf oder über
              vOffice oder Dateidienste vorzunehmen, die die Rechte Dritter
              verletzen, einschließlich gewerblicher Schutzrechte, oder in
              sonstiger Weise gegen anwendbares Recht verstoßen.
            </p>
            <p>10. Ausschluss von Gewährleistungen und Garantien</p>
            <p>
              Die Inhalte und sämtliche Dienstleistungen und Produkte, die im
              Zusammenhang mit PRODUKTE stehen oder über den vOffice-Dienste
              bereitgestellt werden (ob gesponsert oder nicht), werden Ihnen
              ,,wie besehen" und „wie verfügbar" zur Verfügung gestellt. vOffice
              gibt keine Zusicherungen oder Gewährleistungen jeglicher Art, ob
              ausdrücklich oder stillschweigend, bezüglich des Inhalts oder
              Betriebs von PRODUKTE oder des vOffice-Dienstes oder der weiteren
              Verfügbarkeit des vOffice-Dienstes. Sie sind ausdrücklich damit
              einverstanden, dass Ihre Nutzung des vOffice-Dienstes auf eigenes
              Risiko erfolgt.
            </p>
            <p>
              vOffice gibt keine Zusicherungen, Gewährleistungen oder Garantien,
              weder ausdrücklich noch stillschweigend, bezüglich der
              Fehlerfreiheit, Zuverlässigkeit oder Vollständigkeit der Inhalte
              auf PRODUKTE oder des vOffice-Dienstes (ob gesponsert oder nicht)
              und schließt ausdrücklich jegliche Haftung für die Nichtverletzung
              von Rechten Dritter oder die Eignung für einen bestimmten Zweck
              aus. vOffice übernimmt keine Verantwortung, Gewährleistung oder
              Garantie dafür, dass die Inhalte, die über den vOffice-Dienste
              verfügbar sind, frei von Viren oder anderem Code oder sonstigen
              Computerprogrammierroutinen mit schädlichem oder vernichtendem
              Charakter sind oder die dazu bestimmt sind, ein System, Daten oder
              persönliche Informationen zu beschädigen, heimlich abzufangen oder
              zu enteignen.
            </p>
            <p>
              11. Nicht verantwortlich für Daten aus oder den Zugriff auf
              Drittanbieterkonten
            </p>
            <p>
              Für den Fall der Nutzung von Produkten mit SSO-Schnittstelle
              greift vOffice für seine Benutzer auf deren eigene persönliche
              Daten von Drittanbieterkonten zu, sammelt diese und präsentiert
              sie seinen Benutzern. vOffice haftet einem Benutzer oder einer
              anderen Person gegenüber nicht für die Inhalte des
              Drittanbieterkontos des Benutzers oder für Daten, die mithilfe von
              PRODUKTE für seine Benutzer gesammelt werden, oder für
              Sicherheitsverletzungen oder den unberechtigten Zugriff auf oder
              die Beschränkung von Inhalten oder Daten von Drittanbieterkonten.
            </p>
            <p>12. Beschränkungen der Haftung von vOffice</p>
            <p>
              Soweit gesetzlich zulässig, ist vOffice in keinem Fall Ihnen oder
              einem Dritten gegenüber, sei es aufgrund von Vertrag,
              Gewährleistung, einer unerlaubten Handlung (einschließlich
              Fahrlässigkeit) oder anderweitig, verantwortlich oder haftbar für
              mittelbare, konkrete, beiläufig entstandene und Folgeschäden,
              verschärften Schadensersatz, Vertragsstrafen oder Entschädigungen
              mit Strafcharakter, einschließlich, aber nicht beschränkt auf
              entgangene Gewinne, Einnahmen oder Geschäfte, die ganz oder
              teilweise auf Ihren Zugriff (oder mangelnden Zugriff) auf
              PRODUKTE, Ihre Nutzung des vOffice-Dienstes oder diese
              Vereinbarung zurückzuführen sind, selbst wenn vOffice auf die
              Möglichkeit derartiger Schäden hingewiesen wurde oder sich derer
              bewusst hätte sein sollen. Unbeschadet etwaiger in dieser
              Vereinbarung enthaltenen anderslautenden Bestimmungen, ist die
              Haftung von vOffice Ihnen gegenüber für Ihre tatsächlichen Schäden
              aus welchem Grund auch immer und ungeachtet der Form der Klage
              stets wie folgt beschränkt: Im Falle eines Ausfalls oder der
              Nichtleistung des vOffice-Dienstes, einschließlich dazugehöriger
              Software oder sonstiger Materialien, die im Zusammenhang mit
              vOffice-Dienste bereitgestellt werden, ist Ihr Anspruch darauf
              begrenzt, dass vOffice wirtschaftlich angemessene Anstrengungen
              unternimmt, um Nachbesserungen oder eine Reparatur des
              vOffice-Dienstes vorzunehmen, wobei die Art der Anstrengungen in
              zumutbarer Weise davon abhängt, ob der Ausfall oder die
              Nichtleistung einen einzelnen Benutzer, mehrere Benutzer oder die
              Benutzer im Allgemeinen betrifft.
            </p>
            <p>13. Ausschluss der Verjährung</p>
            <p>
              Soweit gesetzlich zulässig, erklären sich damit einverstanden,
              sämtliche Ansprüche oder Klagegründe aufgrund oder in Zusammenhang
              mit der Nutzung des vOffice-Dienstes oder der Vereinbarung
              innerhalb eines (1) Jahres nach der Handlung oder dem Eintritt der
              Situation, die den Anspruch oder den Klagegrund begründet, zu
              stellen; andernfalls gelten diese als verjährt.
            </p>
            <p>14. Ihre Freistellung von vOffice</p>
            <p>
              Sie stellen vOffice und seine leitenden Angestellten, Direktoren,
              Gesellschafter, Mitarbeiter und professionellen Berater von
              sämtlichen Ansprüchen und Kosten, insbesondere Anwaltsgebühren,
              frei, die ganz oder teilweise auf eine Ihnen zuzuschreibende
              Verletzung dieser Vereinbarung oder einer hierin gemachten
              Zusicherung zurückzuführen sind.
            </p>
            <p>
              Dies gilt desweiteren auch bei Ansprüchen von Drittanbietern und
              bei der Nutzung der single –sign-on Schnittstelle.
            </p>
            <p>15. Beendigung Ihrer Beziehung mit vOffice&nbsp;</p>
            <p>
              Diese Vereinbarung gilt so lange, bis sie gemäß den nachstehenden
              Bestimmungen von Ihnen oder vOffice beendet wird.
            </p>
            <p>(1) Beendiqunq durch den Benutzer</p>
            <p>
              Sie können Ihre rechtsverbindliche Vereinbarung mit vOffice
              beenden, indem Sie Ihre verschiedenen im Laufe der Nutzung des
              vOffice-Dienstes erstellten und im vOffice-Dienste aufgeführten
              Konten schließen.
            </p>
            <p>(2) Beendiqunq durch vOffice</p>
            <p>
              vOffice ist berechtigt, seine rechtsverbindliche Vereinbarung mit
              Ihnen und Ihre Nutzung von und Ihren Zugriff auf PRODUKTE
              jederzeit zu beenden:
            </p>
            <p>
              (a) falls Sie eine Bestimmung dieser Vereinbarung verletzt haben
              (oder sich in einer Weise verhalten haben, aus der eindeutig
              geschlossen werden kann, dass Sie nicht beabsichtigen oder unfähig
              sind, die Bestimmungen dieser Vereinbarung einzuhalten);
            </p>
            <p>
              (b) falls vOffice nach alleinigem Ermessen davon überzeugt ist,
              dass dies gesetzlich erforderlich ist (beispielsweise wenn die
              Bereitstellung des vOffice-Dienstes oder von Teilen davon an Sie
              ungesetzlich ist oder wird); oder
            </p>
            <p>
              (c) mit sofortiger Wirkung nach Bekanntgabe an die von Ihnen im
              Rahmen Ihrer Registrierungsinformationen genannte E-Mail-Adresse.
              Bei Beendigung Ihrer Nutzung des vOffice-Dienstes durch vOffice
              werden, soweit das Gesetz nicht etwas anderes erfordert, sämtliche
              Informationen bezüglich Ihres PRODUKTE-Kontos von vOffice
              gelöscht.
            </p>
            <p>16. Änderungen&nbsp;</p>
            <p>
              vOffice ist berechtigt, diese Vereinbarung von Zeit zu Zeit
              abzuändern. Sämtliche Änderungen dieser Vereinbarung werden auf
              der Website von vOffice bekanntgegeben. Darüber hinaus enthält die
              Vereinbarung immer das Datum der letzten Überarbeitung.
            </p>
            <p>17. Anwendbares Recht und Gerichtsstand für Streitigkeiten</p>
            <p>
              Auf diese Vereinbarung und Ihre Beziehung zu vOffice im Rahmen
              dieser Vereinbarung findet das materielle Recht der Bundesrepublik
              Deutschland, Gerichtsstand Berlin-Charlottenburg ohne Bezugnahme
              auf Rechtswahlbestimmungen Anwendung. Ihnen ist bewusst, dass
              vOffice für die Zustimmung zu den Bestimmungen dieses Abschnitts
              im Gegenzug die vOffice-Dienste zu den ausgewiesenen Bedingungen
              anbieten kann und dass Ihr Einverständnis mit dieser Bestimmung
              für diese Vereinbarung unabdingbar ist.
            </p>
            <p>
              Sie erkennen zudem an und verstehen, dass im Hinblick auf
              Streitigkeiten mit vOffice, seinen leitenden Angestellten,
              Direktoren, Mitarbeitern, Bevollmächtigten oder verbundenen
              Unternehmen aufgrund oder in Zusammenhang mit Ihrer Nutzung des
              vOffice-Dienstes oder dieser Vereinbarung:
            </p>
            <p>
              - Sie auf Ihr Recht auf ein Zivilgerichtsverfahren gegen
              vorgenannte Personen verzichten, sofern diese Verzichtserklärung
              nicht gegen geltendes Recht verstößt; und
            </p>
            <p>
              - Sie auf Ihr Recht verzichten, als Vertreter im Sinne eines
              „Private Attorney General" (Privatkläger, der eine Klage im
              öffentlichen Interesse erhebt) oder in einer anderweitigen
              Vertretungsfunktion für oder als Mitglied einer Gemeinschaft von
              Klägern an einem Prozess oder sonstigen Verfahren in Verbindung
              mit einer derartigen Streitigkeit teilzunehmen.
            </p>
            <p>
              18. Unzulässige rassistische oder Gewalt verherrlichenden oder
              diskriminierenden Inhalte
            </p>
            <p>
              Sie gewährleisten, dass die von Ihnen mitgeteilten und editierten
              Daten nicht gegen geltendes Recht, gegen Rechte Dritter oder gegen
              andere anwendbare Rechtsvorschriften verstoßen, und dass sie frei
              von jugendgefährdenden, pornografischen, rassistischen oder Gewalt
              verherrlichenden Inhalten sind. Dies gilt insbesondere auch für
              rechtswidrige Verstöße gegen die Gleichberechtigung von
              Geschlechtern, diskriminierenden Äußerungen gegen Minderheiten und
              überhaupt jegliche Äußerungen, die gegen die Menschenrechte, gegen
              internationales Recht, gegen EU-Recht und nationales Recht in den
              möglichen Verbreitungsgebieten verstoßen könnte.&nbsp;
            </p>
            <p>
              19. Sharen oder posten oder sonstige Weitergabe und Weiterleitung
              von QL-Inhalten
            </p>
            <p>
              Soweit Sie bei über PRODUKTE–Inhalte und Daten sharen oder posten,
              auch durch sonstige Weitergabe oder Weiterleitung zwischen Ihnen
              und Dritten erfolgt, gelten hinsichtlich der Haftung für die
              Übermittlung dieser Inhalte die in dieser AGB enthaltenen
              Haftungs-Regelungen entsprechend; d.h. unmittelbar Haftender sind
              ausschließlich Sie.
            </p>
            <p>20. Sonstiges</p>
            <p>
              Sollte ein Teil dieser Vereinbarung von einem Schiedsrichter oder
              zuständigen Gericht als rechtswidrig, nichtig oder undurchsetzbar
              erachtet werden, so wird die Vereinbarung im Ganzen nicht als
              rechtswidrig, nichtig oder undurchsetzbar angesehen, sondern es
              wird nur der rechtswidrige, nichtige oder undurchsetzbare Teil
              dieser Vereinbarung aus dieser Vereinbarung gestrichen.
            </p>
            <p>
              Sie erklären sich damit einverstanden, dass in Fällen, in denen
              vOffice einen Rechtsanspruch oder Rechtsbehelf, der in der
              Vereinbarung vorgesehen ist (oder der vOffice nach anwendbarem
              Recht zusteht), nicht geltend macht oder durchsetzt, dies nicht
              als formelle oder stillschweigende Verzichtserklärung hinsichtlich
              der Rechte von vOffice gewertet wird und dass diese Ansprüche oder
              Rechtsbehelfe von vOffice noch immer geltend gemacht werden
              können.
            </p>
            <p>
              Diese Vereinbarung soll in gerechter Weise ausgelegt werden und
              nicht strikt gegen oder für eine der Parteien. Diese Vereinbarung
              stellt die Gesamtheit der im Hinblick auf den Vertragsgegenstand
              getroffenen Vereinbarungen dar und ersetzt alle früheren oder
              gleichzeitigen elektronischen, mündlichen oder schriftlichen
              Absprachen und Angebote zwischen vOffice und dem Benutzer von
              PRODUKTE. Die Überschriften in dieser Vereinbarung dienen
              lediglich der besseren Übersichtlichkeit und stellen keine
              Einschränkung, Festlegung oder Ergänzung der Bedeutung der
              Bestimmungen dar.
            </p>
            <p>
              Informationen zu den Datenschutzbestimmungen von vOffice erhalten
              Sie unter: <br /><a href="datenschutz.html"
                >http://voffice-se.com/datenschutz.html</a
              >
            </p>
            <p>&nbsp;</p>
          </section>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isRaMicro } from "@/utils.js";
export default {
  name: "terms",
  components: {},
  data() {
    return {
      isRaMicro: isRaMicro()
    };
  },
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
</style>
