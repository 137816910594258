<template>
  <div v-if="showBanner" class="iosPWA d-flex">
    <v-btn x-small @click="closeBanner" icon class="closeBannerIcon">
      <font-awesome-icon
        :icon="['far', 'times']"
        :style="{ fontSize: 15, color: '#cccccc' }"
      />
    </v-btn>
    <div class="pl-1 pr-2 py-1">
      <v-img
        src="../assets/easymeet_icon.svg"
        max-height="25"
        max-width="25"
        class="d-inline-block vertcal-align-middel imgShare"
        contain
      ></v-img>
    </div>
    <div class="pr-2">
      Install this WebApp on your iPhone: tap
      <v-img
        src="../assets/shareIOSIcon.png"
        max-height="20"
        max-width="20"
        class="d-inline-block vertcal-align-middel imgShare"
        contain
      ></v-img>
      and then Add to homescreen.
    </div>
  </div>
</template>
<script>
export default {
  name: "iosBanner",
  props: ["showBanner", "closeBanner"],
  components: {},
  data: () => ({}),
  methods: {},
  computed: {}
};
</script>
<style scoped lang="scss">
.closeBannerIcon {
  position: absolute;
  right: 5px;
  top: 5px;
}
.vertcal-align-middel {
  vertical-align: middle;
  &.imgShare {
    top: -2px;
    position: relative;
  }
}
</style>
