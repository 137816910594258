<template>
  <div>
    <v-dialog
      v-model="showPayLinkModal"
      persistent
      max-width="600px"
      attach=".call"
    >
      <v-card>
        <HeaderModals :titleModal="$t('payLinkModal.titel')"></HeaderModals>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-combobox
                  v-model="clientName"
                  :search-input.sync="contactSearch"
                  :items="contacts"
                  class="pb-6"
                  outlined
                  item-text="fullName"
                  item-value="email"
                  hide-details="auto"
                  :label="$t('payLinkModal.clientName')"
                  select
                  dense
                  @change="handlerChangeContact"
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template v-slot:append>
                    <font-awesome-icon
                      :icon="['fal', 'address-book']"
                      :style="{
                        fontSize: 16,
                        color: '#ffffff',
                        position: 'relative',
                        top: 3,
                        cursor: 'pointer'
                      }"
                    />
                  </template>
                  <template v-slot:item="data">
                    <div>
                      <span
                        class="text-left fontSizeItem"
                        v-text="` ${data.item.fullName}`"
                      ></span>
                    </div>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="clientEmail"
                  dense
                  outlined
                  :label="$t('payLinkModal.email')"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="white--text text-left">
              <v-col cols="12" class="mt-n6 mb-n3 subtitle-1">
                {{ $t("payLinkModal.optional") }}:
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  v-model="productName"
                  dense
                  outlined
                  hide-details
                  :label="$t('paymentModals.product')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="productPrice"
                      dense
                      outlined
                      type="number"
                      :min="5"
                      :rules="[numberRule]"
                      :label="$t('paymentModals.price')"
                    >
                      <template v-slot:append>
                        <font-awesome-icon
                          :icon="['far', 'euro-sign']"
                          :style="{
                            fontSize: 16,
                            color: '#ffffff',
                            position: 'relative',
                            top: 3
                          }"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <span>Minimum 10 Euro</span>
                </v-tooltip>
              </v-col>
              <!-- <v-col cols="12" class="pt-0" v-if="showAddressBookSuggestion">
                <p class="mb-0 whiteText text-left">
                  If you want to add this contact to your book address
                  <span
                    class="primary--text cursorPointer"
                    @click="addContact()"
                  >
                    <span v-if="!addingContact">click here </span>
                    <v-progress-circular
                      v-if="addingContact"
                      indeterminate
                      color="primary"
                      class="progressLanding"
                    ></v-progress-circular>
                  </span>
                </p>
              </v-col> -->
              <v-col cols="12" class="pt-0" v-if="showContactSaved">
                <p class="mb-0 whiteText text-left">Contact has been saved</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closePayLinkModal">
            {{ $t("paymentModals.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="createLink"
            :disabled="disablePayLink"
            :loading="creatingLink"
          >
            {{ $t("paymentModals.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import { getFirebaseToken } from "@/firebase";
import { isMobile, shareLinkOnMobile, copyLink } from "../../utils";
import HeaderModals from "./commons/headerModals.vue";
import FingerprintJS from "fingerprintjs";
export default {
  name: "settingsModal",
  components: { HeaderModals },
  props: ["showPayLinkModal", "closePayLinkModal", "handleShowPaymentLinkInProgress", "contactPayLink"],
  data: () => ({
    isMobile: isMobile(),
    shareLinkOnMobile: shareLinkOnMobile,
    copyLink: copyLink,
    productName: '',
    productPrice: '',
    clientName: '',
    clientEmail: '',
    link: '',
    roomUrl: '',
    emailRules: [
        v => !!v || "E-mail is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail is invalid"
      ],
    numberRule: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 10) return true;
      return 'Minimum 10 Euro';
    },
    creatingLink: false,
    contacts: [],
    contactSearch: null,
    // showAddressBookSuggestion: false,
    showContactSaved: false,
    addingContact: false
  }),
  mounted(){
    this.getContacts();
  },
  methods:{
    async createLink(){
      if (this.productPrice) {
        try{
          this.creatingLink = true;
          let token = await getFirebaseToken();

          let response = await fetch(
            `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + (token),
                "Content-Type": "application/json;charset=UTF-8"
              }
            })
          let account = await response.json()
          if(!account.success) {
            console.log("error in fetchStripeConnectAccount")
            return
          }

          let fingerPrint = new FingerprintJS().get();
          let fingerPrintString = "?f=" + fingerPrint + "&ts=" + Date.now();

          response = await fetch(
            `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/createStripeMerchantCheckoutSession`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + (token),
                "Content-Type": "application/json;charset=UTF-8"
              },
              body: JSON.stringify({
                productName: this.productName,
                amount: this.productPrice * 100, // Amount in smallest currency unit (cents)
                currency: 'eur',
                connectId: account.account.id,
                room: this.$route.params.roomid + '/' + this.$route.params.subRoom + fingerPrintString,
                clientName: this.clientName,
                email: this.clientEmail,
                returnBaseUrl: window.location.origin,
              })
            })
            let url = await response.json();
            if(!url.success) {
              console.log("error in createStripeMerchantCheckoutSession")
              return
            }

            this.link = url.url;
            let emailSuccess = await this.sendEmail()
            if (emailSuccess) {
              this.creatingLink = false;
              this.closePayLinkModal();
              this.handleShowPaymentLinkInProgress(this.link, url.paymentIntentId);
            } else {
              this.creatingLink = false;
              console.log("error sending email")
            }
        }catch(err){
          console.log('Error creating link', err)
        }
      } else {
        this.creatingLink = true;
        let emailSuccess = await this.sendEmail()
        this.creatingLink = false;
        if (emailSuccess) {
          this.closePayLinkModal();
          this.handleShowPaymentLinkInProgress(this.link, url.paymentIntentId);
        } else {
          console.log("error sending email")
        }
      }
    },
    async addContact(){
      this.addingContact = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveContact`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          first_name: this.clientName,
          last_name: this.clientName,
          email: this.clientEmail,
          phone: "",
          hasWhatsapp: false
        })
      })
      .then(response => response.json())
      .then(response => {
        this.addingContact = false;
        if (response.success && response.contacts) {
          const tmpArray = [];
          response.contacts.forEach(contact => {
            contact.fullName =  contact.first_name + ' ' + contact.last_name;
            tmpArray.push(contact);
          })
          this.contacts = tmpArray;
          
          // this.showAddressBookSuggestion = false;
          // this.showContactSaved = true;
          setTimeout(() => {
            this.showContactSaved = false;
          }, 4000)
        }
      });
    },
    async getContacts() {
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/getAddressBook`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.success && response.contacts) {
            const tmpArray = [];
            response.contacts.forEach(contact => {
              console.log('contact >>>> ', contact)
              contact.fullName = contact.titel + " " + contact.first_name + ' ' + contact.last_name;
              tmpArray.push(contact);
            })
            this.contacts = tmpArray;

            // this.contacts = response.contacts;
            if ( this.contactPayLink ){
              this.contacts.forEach(contact => {
                if ( contact.email == this.contactPayLink ){
                  this.clientName = contact.first_name;
                  this.clientEmail = contact.email;
                }
              })
            }
          }
        });
    },
    async sendEmail() {
      let token = await getFirebaseToken();
      let subject;
      let message;

      if (this.productPrice) {
        subject =this.$t("payLinkModal.email-subject", [this.user.displayName]);
        message = this.$t("payLinkModal.email-body", [this.clientName, this.user.displayName, this.productPrice, this.productName, this.link]);
      } else {
        subject =this.$t("profile.addressBook.email-subject", [this.user.displayName]);
        message = this.$t("profile.addressBook.email-body", ['', this.clientName, this.user.displayName]) + window.location.origin + window.location.pathname
      }

      let response = await fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/sendemail`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (token),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            email: this.clientEmail,
            subject: subject,
            message: message,
          })
        })

      let responseJson = await response.json()
      if (responseJson.success) {
        return true
      } else {
        return false
      }
    },
    handlerChangeContact(data){
      if ( typeof data == 'object' ){
        this.clientName = data.fullName;
        this.clientEmail = data.email;
      }else{
        let exists = false;
        this.contacts.forEach(contact => {
          if ( contact.first_name == this.clientName ){
            exists = contact;
          }
        });
        if ( exists ){
          this.clientName = exists.first_name;
          this.clientEmail = exists.email;
        }else{
          this.clientEmail = "";
        }
      }
    },
    isValidEmail() {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.clientEmail);
    }
    // checkContact(){
    //   if ( this.clientName !== "" && this.clientEmail !== "" && this.isValidEmail() ){
    //     let exists = false;
    //     this.contacts.forEach(contact => {
    //       if ( contact.email == this.clientEmail ){
    //         exists = true;
    //       }
    //     });
    //     if ( !exists ){
    //       this.showAddressBookSuggestion = true;
    //     }
    //   }
    // }
  },
  computed:{
    disablePayLink(){
      if (this.clientName && this.isValidEmail()) {
        if (!this.productPrice || (this.productPrice && this.productName && this.productPrice >= 10)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    user(){
      return this.$store.state.user.user
    },
    hasSettings(){
      return this.$store.state.user.user.hasSettings
    },
  }
};

</script>
<style scoped lang="scss">
.progressLanding{
    height: 15px !important;
    width: 15px !important;
    margin-left: 5px !important;
}
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
.copiedAlert{
  position: absolute;
  top: 15px;
  right: 36px;
  margin: 0;
  width: auto;
  z-index: 999;
}
.whiteText{
  color: #ffffff;
}
.cursorPointer{
  cursor: pointer;
}
.fontSizeItem{
  font-size: 14px;
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>
