<template>
  <v-card>
    <div class="d-flex align-center height-100">
      <v-row>
        <v-col class="text-center" cols="12">
          <v-img
            v-if="!isRaMicro"
            class="logo mb-2"
            contain
            src="../assets/easymeet_logo-b.svg"
          ></v-img>
          <v-img
            v-else
            class="logo mb-2"
            contain
            src="../assets/easymeet_logo-b.svg"
          ></v-img>
        </v-col>

        <v-col class="text-center" cols="12">
          <v-btn depressed color="primary" @click="initCall">
            <font-awesome-icon
              class="mr-3"
              :icon="['fas', 'phone-alt']"
              :style="{ fontSize: 15 }"
            />
            {{ $t("startnow") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
/* eslint-disable */
import { isRaMicro, randomString } from "../utils.js";
import FingerprintJS from "fingerprintjs";
  export default {
    name: "StartCall",
    props: [],
    components: {
    },
    data: () => ({
      isRaMicro: isRaMicro()
    }),
    created(){},
    mounted(){
     
    },
    methods: {
      async initCall(){
        const roomId = randomString(9);
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room: roomId
          })
        })
        .then(response => response.json())
        .then(response => { 
          if (response.success) {
            let fingerPrint = new FingerprintJS().get();
            let ts = Date.now();
            this.$router.push({ path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}` });
            this.showCallComponent = true;
          }
          else {
            // Try again with a different room
            this.initCall()
          }
        })
      },
    },
    computed: {
    },
  };
</script>
<style scoped lang="scss">
.logo{
  max-width: 350px;
  margin:0 auto;
}
.height-100 {
  height:100vh;
}
.textInput {
  max-width:350px;
  margin: 0 auto;
}

</style>