<template>
  <div class="imprint">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container">
          <h1>{{ $t("links.imprint") }}</h1>

          <section>
            <div v-if="!isRaMicro">
              <p v-html="$t('service-of')"></p>
              <p>
                <b>Maram Strategy Consulting S.L.</b><br />
                <span>Carretera San Jeronimo 15 / Piso 2</span><br />
                <span>{{ $t("footer.col-1-adress") }}</span
                ><br />
                <span>{{ $t("footer.col-1-land") }}</span>
              </p>
              <p>
                Email. <a href="mailto:hello@easymeet.me">hello@easymeet.me</a>
              </p>
              <p>
                CIF: B88379102<br />
                VAT-ID: ESB88379102
              </p>
            </div>
            <div v-else>
              <p>Easymeet Deutschland</p>
              <div>
                <strong>de.easymeet.me by vOffice SE</strong><br /><br />
                <div class="d-flex">
                  <v-img
                    width="60"
                    max-width="60"
                    class="mr-4"
                    src="../../assets/voffice-logo.png"
                    contain
                  ></v-img>
                  <div>
                    <span v-html="$t('imprint.address')"></span>
                  </div>
                </div>
              </div>
              <p v-html="$t('imprint.email')"></p>
              <p v-html="$t('imprint.phone')"></p>
              <p v-html="$t('imprint.director')"></p>
              <p v-html="$t('imprint.chairman')"></p>
              <p v-html="$t('imprint.drb')"></p>
              <p v-html="$t('imprint.commercial-register')"></p>
              <p v-html="$t('imprint.place-of-jurisdiction')"></p>
              <p v-html="$t('imprint.tax-id')"></p>
              <p v-html="$t('imprint.terms')"></p>
            </div>
          </section>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isRaMicro } from "../../utils.js";
export default {
  name: "imprint",
  components: {},
  data() {
    return {
      isRaMicro: isRaMicro()
    };
  },
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";

.containerStartPage {
  height: 100vh;
}
</style>
