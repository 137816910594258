<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-row class="rowPills">
      <v-col cols="12">
        <h1 class="pb-2">
          {{ $t("profile.roomHistory.titel") }}
        </h1>
        <p>{{ $t("profile.roomHistory.subtitel") }}</p>
      </v-col>
      <!-- <v-col cols="3"></v-col> -->
      <v-col cols="12" style="text-align:left;" class="px-0">
        <div class="bordered pa-5">
          <h2 class="pb-1 title">
            {{ $t("profile.roomHistory.history") }}
          </h2>

          <div>
            <v-data-table
              :headers="getTableHeaders"
              :footer-props="{
                'items-per-page-text': footerText,
                'items-per-page-all-text': allText
              }"
              :items="roomHistory"
              :loading="loadingDataTable"
              loading-text=""
              mobile-breakpoint="750"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :header-props="{
                'sort-by-text': sortByText
              }"
            >
              <template slot="no-data">
                {{ $t("profile.roomHistory.noHistory") }}
              </template>

              <template #item.roomName="{ item }">
                <span class="links" @click="goToRoom(item.roomName)">
                  {{ item.roomName }}
                </span>
              </template>
              <template #item.date="{ item }">
                {{ moment(item.date).format("DD.MM.YYYY") }}
              </template>
              <template #item.time="{ item }">
                {{ moment(item.date).format("HH:mm") }}
              </template>
            </v-data-table>
            <!-- <div class="alpha-quickSelect">
                <span v-for="letter in getQuickSelect" :key="letter" @click="filterContacts(letter)">{{letter}}</span>
            </div> -->
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import FingerprintJS from "fingerprintjs";
import { getFirebaseToken } from "../../firebase";
import { isRaMicro, isMobile } from "../../utils";
import moment from "moment";
export default {
  name: "AddressBook",
  props: ["showSection"],
  components: {},
  data: () => ({
    roomHistory: [],
    tableHeaderRoom: [],
    showDeleteContact: false,
    loading: false,
    loadingDataTable: true,
    baseUrl: window.location.origin,
    isRaMicro: isRaMicro(),
    isMobile: isMobile(),
    moment: moment,
    sortBy: "date",
    sortDesc: true
  }),
  created() {},
  mounted() {
    this.getHistoryRoom();
  },
  methods: {
    async getHistoryRoom() {
      const tmpArray = [];
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/getRoomHistory`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          console.log("response", response);
          this.loadingDataTable = false;
          let lastWeek = new Date();
          lastWeek.setDate(lastWeek.getDate() - 7);
          if (response.success && response.history) {
            for (const item of Object.keys(response.history)) {
              console.log(response.history[item]);
              const obj = response.history[item];
              obj.roomName = obj.roomName.replace("_", "/");
              if (response.history[item].date > lastWeek.getTime()) {
                tmpArray.push(obj);
              }
            }
            tmpArray.sort(function(a, b) {
              var keyA = new Date(a.date),
                keyB = new Date(b.date);
              // Compare the 2 dates
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
            });

            this.roomHistory = tmpArray;
            console.log("RoomH", this.roomHistory);
          }
        });
    },
    async goToRoom(room) {
      this.$router.replace("/c/" + room + this.fingerPrintString());
    },
    fingerPrintString() {
      let fingerPrint = new FingerprintJS().get();
      let string = "?f=" + fingerPrint + "&ts=" + Date.now();
      if (this.token && !this.isRoomOwner) {
        string += "&token=" + this.token;
      }
      console.log(string);
      return string;
    }
  },
  computed: {
    footerText() {
      return this.$t("profile.subscriptions.rows-per-page");
    },
    sortByText() {
      return this.$t("profile.subscriptions.sort-by-text");
    },
    allText() {
      return this.$t("profile.subscriptions.allText");
    },
    getTableHeaders() {
      var tableHeaderRoom = [
        {
          text: this.$t("profile.roomHistory.room"),
          align: "start",
          sortable: false,
          value: "roomName"
        },
        {
          text: this.$t("profile.roomHistory.date"),
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("profile.roomHistory.time"),
          align: "start",
          sortable: true,
          value: "time"
        },
        {
          text: this.$t("profile.roomHistory.email"),
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t("profile.roomHistory.name"),
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("profile.roomHistory.tel"),
          align: "right",
          sortable: false,
          value: "tel"
        },
        {
          text: this.$t("profile.roomHistory.paid"),
          align: "right",
          sortable: false,
          value: "paid_value"
        },
        {
          text: this.$t("profile.roomHistory.tax"),
          align: "right",
          sortable: false,
          value: "mwst"
        }
      ];
      return tableHeaderRoom;
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}

.alpha-quickSelect {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3%;
  text-align: center;
  span {
    color: #1876d1;
    cursor: pointer;
  }
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.titlePill {
  width: calc(100% - 20px);
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.bordered {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}
.dropDownButton {
  width: 100%;
  color: black;
  .v-icon {
    position: absolute;
    right: -10px;
  }
}
.buttonAddNew {
  float: right;
}

@media screen and (max-width: 480px) {
  .buttonAddNew {
    float: unset;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
<style lang="scss">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 7px !important;
  max-width: 10vw;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.v-data-table__wrapper > table > thead > tr > th {
  padding: 0 7px !important;
  white-space: nowrap;
}
</style>
