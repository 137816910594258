<template>
  <div v-if="!user.sellerId">
    <v-card class="cardProfile" flat>
      <span
        v-if="isMobile"
        class="text-right closeButtonPosition links"
        @click="showSection('home')"
        >x</span
      >
      <v-progress-linear
        absolute
        v-if="isLoading"
        color="primary accent-4"
        indeterminate
        rounded
        height="5"
      ></v-progress-linear>
      <v-row class="rowPills">
        <v-col cols="12">
          <h1 class="pb-2">
            {{ $t("profile.affiliate.title") }}
          </h1>
          <p>
            {{ $t("profile.affiliate.subtitle-start1") }}
            {{ $t("profile.affiliate.subtitle-start2") }}
          </p>
        </v-col>
        <v-text-field
          v-model="sellerIdInput"
          v-on:keyup.enter="setSellerId"
          dense
          outlined
          label="Refferal ID"
          required
          @input="sellerIdInput = sellerIdInput.toUpperCase()"
        >
        </v-text-field>
        <v-btn
          :disabled="!sellerIdInput"
          color="primary"
          depressed
          class="ml-5 mt-0"
          @click="setSellerId"
        >
          {{ $t("paymentModals.save") }}
        </v-btn>
      </v-row>
      <v-alert :value="showError" type="error" class="genericAlerts">
        {{ errorMessage }}
      </v-alert>
    </v-card>
  </div>
  <div v-else>
    <v-card class="cardProfile" flat>
      <span
        v-if="isMobile"
        class="text-right closeButtonPosition links"
        @click="showSection('home')"
        >x</span
      >
      <v-row class="rowPills">
        <v-col cols="12">
          <h1 class="pb-2">
            {{ $t("profile.affiliate.title") }}
          </h1>
          <p>
            {{ $t("profile.affiliate.subtitle") }}
          </p>
          {{ $t("profile.affiliate.your-ref-link") }}:
          <a :href="baseUrl + '?ref=' + user.sellerId"
            >{{ baseUrl }}?ref={{ user.sellerId }}</a
          >
          <a
            target="_blank"
            @click="copyToClipboard(baseUrl + '?ref=' + user.sellerId)"
            class="pl-2"
          >
            <font-awesome-icon :icon="['fal', 'copy']" />
          </a>
        </v-col>
        <v-switch
          v-model="isViewingMontlyImpressionChart"
          :label="impressionChartSwitchLabal"
        ></v-switch>
        <v-chart
          v-if="!isViewingMontlyImpressionChart"
          class="chart"
          :autoresize="true"
          :option="dailyImpressionChartOption"
          :loading="isLoadingData"
          :loading-options="{ spinnerRadius: 50, lineWidth: 3, text: '' }"
          theme="macarons"
        />
        <v-chart
          v-if="isViewingMontlyImpressionChart"
          class="chart"
          :autoresize="true"
          :option="monthlyImpressionChartOption"
          :loading="isLoadingData"
          :loading-options="{ spinnerRadius: 50, lineWidth: 3, text: '' }"
          theme="macarons"
        />
        <v-chart
          class="chart"
          :autoresize="true"
          :option="subscriptionPieChart"
          :loading="isLoadingData"
          :loading-options="{ spinnerRadius: 50, lineWidth: 3, text: '' }"
          theme="macarons"
        />
        <div class="text-left">
          {{ $t("profile.affiliate.total-ref") }}:
          {{ salesStats.activeSubs + salesStats.nonActiveSubs || 0 }} <br />
          {{ $t("profile.affiliate.active-sub") }}:
          {{ salesStats.activeSubs || 0 }}
        </div>
        <v-col cols="12" style="text-align:left;" class="px-0"> </v-col>
        <v-alert :value="showError" type="error" class="genericAlerts">
          {{ errorMessage }}
        </v-alert>
        <v-alert
          :value="showSuccessMessage"
          type="success"
          class="genericAlerts"
        >
          {{ successMessage }}
        </v-alert>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import { TitleComponent, GridComponent } from "echarts/components";
import VChart from "vue-echarts";
import "@/assets/echart-themes/macarons";
import { fetchUserRefClickStats, getFirebaseToken } from "@/firebase";
import { isMobile } from "@/utils.js";

use([CanvasRenderer, BarChart, PieChart, TitleComponent, GridComponent]);

export default {
  name: "Affiliate",
  props: ["showSection"],
  components: { VChart },
  data() {
    return {
      baseUrl: window.location.origin,
      isMobile: isMobile(),
      sellerIdInput: "",
      showError: false,
      errorMessage: "",
      showSuccessMessage: false,
      successMessage: "",
      isLoading: false,
      isLoadingData: true,
      dailyImpressionStats: "",
      salesStats: {},
      isViewingMontlyImpressionChart: false,
      dailyImpressionChartOption: {
        title: {
          text: this.$t("profile.affiliate.daily-impression"),
          left: "center"
        },
        legend: {
          show: true,
          top: 20
        },
        xAxis: {
          data: []
        },
        yAxis: {
          type: "value",
          splitNumber: 1
        },
        toolbox: {
          show: true,
          right: 50,
          top: "top",
          feature: {
            saveAsImage: {
              title: this.$t("generics.save-image")
            }
          }
        },
        series: [
          {
            name: this.$t("profile.affiliate.impression"),
            type: "bar",
            data: []
          }
        ]
      },
      monthlyImpressionChartOption: {
        title: {
          text: this.$t("profile.affiliate.monthly-impression"),
          left: "center"
        },
        legend: {
          show: true,
          top: 20
        },
        xAxis: {
          data: []
        },
        yAxis: {
          type: "value",
          splitNumber: 1
        },
        toolbox: {
          show: true,
          right: 50,
          top: "top",
          feature: {
            saveAsImage: {
              title: this.$t("generics.save-image")
            }
          }
        },
        series: [
          {
            name: this.$t("profile.affiliate.impression"),
            type: "bar",
            data: []
          }
        ]
      },
      subscriptionPieChart: {
        title: {
          text: this.$t("sidebar.subscriptions"),
          left: "center"
        },
        tooltip: {
          backgroundColor: "rgba(256,256,256,1)",
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
          show: true,
          right: 50,
          top: "top",
          feature: {
            saveAsImage: {
              title: this.$t("generics.save-image")
            }
          }
        },
        series: [
          {
            name: this.$t("sidebar.subscriptions"),
            type: "pie",
            data: []
          }
        ]
      }
    };
  },
  created() {},
  mounted() {
    if (this.user.sellerId) {
      this.fetchUserStats();
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.successMessage = this.$t("profile.manageRooms.copiedToClipboard");
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 2500);
    },
    async setSellerId() {
      this.isLoading = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/setSellerId`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          sellerId: this.sellerIdInput.toUpperCase()
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.user,
              userInfo: {
                sellerId: this.sellerId
              }
            });
            this.$store.dispatch("USER_FETCH", {
              user: this.user
            });
            this.fetchUserStats();
          } else if (response.errorType === "duplicate_id") {
            this.showError = true;
            this.errorMessage = this.$t("profile.affiliate.ref-taken");
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async fetchUserStats() {
      this.isLoadingData = true;
      this.dailyImpressionStats = await fetchUserRefClickStats(this.user);
      console.log(this.dailyImpressionStats);
      if (!this.dailyImpressionStats) {
        this.dailyImpressionStats = {};
      }
      this.setDailyImpressionChart();
      this.setMonthlyImpressionChart();
      this.salesStats = await this.getSalesStats();
      this.setSubscriptionPieChart();
      this.isLoadingData = false;
    },
    setDailyImpressionChart() {
      this.dailyImpressionChartOption.xAxis.data = [];
      this.dailyImpressionChartOption.series[0].data = [];
      let now = new Date();

      now.setDate(now.getDate() - 30);
      for (let i = 0; i < 30; i++) {
        now.setDate(now.getDate() + 1);
        this.dailyImpressionChartOption.xAxis.data.push(
          now.toISOString().substr(5, 5)
        );
        this.dailyImpressionChartOption.series[0].data.push(
          this.dailyImpressionStats[now.toISOString().substr(0, 10)] || 0
        );
      }
    },
    setMonthlyImpressionChart() {
      this.monthlyImpressionChartOption.xAxis.data = [];
      this.monthlyImpressionChartOption.series[0].data = [];
      let now = new Date();

      now.setMonth(now.getMonth() - 12);
      for (let i = 0; i < 12; i++) {
        now.setMonth(now.getMonth() + 1);
        this.monthlyImpressionChartOption.xAxis.data.push(
          now.toLocaleString("default", { month: "short" }).toUpperCase()
        );

        let monthlyTotal = 0;
        for (const key in this.dailyImpressionStats) {
          if (key.substr(0, 7) === now.toISOString().substr(0, 7)) {
            monthlyTotal += this.dailyImpressionStats[key];
          }
        }
        this.monthlyImpressionChartOption.series[0].data.push(monthlyTotal);
      }
    },
    async getSalesStats() {
      let res = await fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/getaffiliatesalestats`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            sellerid: this.user.sellerId
          })
        }
      );
      return res.json();
    },
    async setSubscriptionPieChart() {
      this.subscriptionPieChart.series[0].data = [
        {
          value: this.salesStats.nonActiveSubs,
          name: this.$t("profile.affiliate.non-active")
        },
        { value: this.salesStats.paypalSubs, name: "PayPal" },
        { value: this.salesStats.stripeSubs, name: "Stripe" },
        { value: this.salesStats.coinbaseSubs, name: "Coinbase Commerce" },
        {
          value: this.salesStats.airtimeSubs,
          name: "Bittube Airtime Extension"
        },
        {
          value: this.salesStats.cccheckoutSubs,
          name: "Cryptocurrency Checkout"
        }
      ];
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    impressionChartSwitchLabal() {
      if (this.isViewingMontlyImpressionChart) {
        return this.$t("profile.affiliate.monthly");
      }
      return this.$t("profile.affiliate.daily");
    }
  }
};
</script>
<style scoped lang="scss">
.chart {
  height: 400px;
  width: 100%;
}
.d-inlineb {
  display: inline-block;
  &.qrDiv {
    vertical-align: middle;
  }
  & .base32Code {
    font-size: 14px;
  }
}
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.titlePill {
  width: calc(100% - 20px);
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.bordered {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}
.dropDownButton {
  width: 100%;
  color: black;
  .v-icon {
    position: absolute;
    right: -10px;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
