<template>
  <v-dialog v-model="showModalAddPhoto" persistent max-width="600px">
    <v-card>
      <v-progress-linear
        absolute
        v-if="isUploading"
        color="primary accent-4"
        indeterminate
        rounded
        height="5"
      ></v-progress-linear>
      <HeaderModals
        :titleModal="$t('profile.addPhoto.changePic')"
      ></HeaderModals>
      <v-tabs class="px-4">
        <v-tabs-slider color="blue"></v-tabs-slider>
        <v-tab>
          {{ $t("profile.addPhoto.selectImage") }}
        </v-tab>
        <v-tab v-if="!isRaMicro">
          {{ $t("profile.addPhoto.chooseAvatar") }}
        </v-tab>
        <v-tab-item>
          <v-card-text>
            <v-container>
              <v-row v-if="!isCropping">
                <v-col cols="12">
                  <img
                    class="imageModal"
                    v-if="user.customPhotoURL"
                    :src="user.customPhotoURL"
                  />
                  <img
                    class="imageModal"
                    v-else-if="user.photoURL"
                    :src="user.photoURL"
                  />
                  <v-avatar
                    color="primary"
                    size="150"
                    v-else-if="
                      !user.photoURL && !user.customPhotoURL && isRaMicro
                    "
                  >
                    <span class="white--text" style="font-size: 50px">{{
                      getAvatar
                    }}</span>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    v-show="!isCropping"
                    color="primary"
                    id="pick-avatar"
                    >{{ $t("profile.addPhoto.uploadPhoto") }}</v-btn
                  >
                  <div class="text-center">
                    <avatar-cropper
                      :upload-handler="uploadPhoto"
                      :cropper-options="cropperOptionTag()"
                      :labels="labelTag()"
                      trigger="#pick-avatar"
                      inline
                      class="avatar-cropper"
                      @changed="handlerCrop()"
                      @cancel="isCropping = false"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <RandomAvatar v-if="showRandomAvatar" id="avatarSvg" />
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="randomizeAvatar" class="mr-2 mb-2">
                <font-awesome-icon
                  :icon="['far', 'sync']"
                  :style="{ fontSize: 15, color: '#ffffff' }"
                />
                &#8203;&emsp;{{ $t("profile.addPhoto.random") }}
              </v-btn>
              <v-btn color="primary" class="mb-2" @click="selectAvatar">{{
                $t("profile.addPhoto.select")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="
            closeModal();
            isCropping = false;
          "
        >
          {{ $t("paymentModals.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RandomAvatar from "@/components/randomAvatar.vue";
import AvatarCropper from "vue-avatar-cropper";
import { svgAsPngUri } from "save-svg-as-png";
import { uploadFile } from "@/firebase";
import { isRaMicro } from "../../../utils";
import HeaderModals from "../../modals/commons/headerModals.vue";
export default {
  name: "AddPhotoModal",
  props: ["showModalAddPhoto", "closeModal"],
  components: { RandomAvatar, AvatarCropper, HeaderModals },
  data() {
    return {
      isUploading: false,
      showRandomAvatar: true,
      isRaMicro: isRaMicro(),
      isCropping: false
    };
  },
  methods: {
    handlerCrop() {
      this.isCropping = true;
    },
    labelTag() {
      return {
        submit: this.$t("profile.addPhoto.select"),
        cancel: this.$t("paymentModals.cancel")
      };
    },
    cropperOptionTag() {
      return {
        aspectRatio: 1,
        autoCropArea: 1,
        viewMode: 1,
        movable: true,
        zoomable: true
      };
    },
    async uploadPhoto(cropper) {
      this.isUploading = true;
      this.isCropping = false;
      let filename = "profile_pic/" + this.user.uid + ".png";
      cropper.getCroppedCanvas({ width: 256, height: 256 }).toBlob(blob => {
        uploadFile(blob, filename)
          .then(url => {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.$store.state.user,
              userInfo: {
                customPhotoURL: url
              }
            });
            this.isUploading = false;
          })
          .catch(error => {
            console.log("error uploading file: " + error);
            this.isUploading = false;
          });
      }, "image/png");
    },
    randomizeAvatar() {
      this.showRandomAvatar = false;
      this.$nextTick(() => {
        this.showRandomAvatar = true;
      });
    },
    async selectAvatar() {
      this.isUploading = true;
      let filename = "profile_pic/" + this.user.uid + ".png";
      svgAsPngUri(document.getElementById("avatarSvg"), {
        width: 256,
        height: 256
      })
        .then(uri => {
          return fetch(uri);
        })
        .then(res => {
          return res.blob();
        })
        .then(blob => {
          return uploadFile(blob, filename);
        })
        .then(url => {
          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              customPhotoURL: url
            }
          });
          this.isUploading = false;
        })
        .catch(error => {
          console.log("error uploading file: " + error);
          this.isUploading = false;
        });
    }
  },
  computed: {
    getAvatar() {
      let avatarN = "";
      let stringToLoop;
      if (this.user.displayName) {
        stringToLoop = this.user.displayName;
      } else {
        stringToLoop = this.user.email;
      }
      for (var i = 0; i < stringToLoop.length; i++) {
        if (avatarN.length < 2 && /^[a-z0-9]+$/i.test(stringToLoop.charAt(i))) {
          avatarN += stringToLoop.charAt(i);
        }
      }
      return avatarN.toUpperCase();
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
#avatarSvg {
  height: 150px;
  width: 150px;
  margin-top: 20px;
}
.imageModal {
  width: 150px;
  max-width: 150px;
}
</style>

<style lang="scss">
.avatar-cropper-btn {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  color: #fff !important;
  height: 36px !important;
  min-width: 64px !important;
  font-size: 0.875rem !important;
  padding: 0 8px !important;
  margin: 0px 5px;
  border-radius: 4px;
}
.avatar-cropper-container {
  min-width: 400px;
  box-shadow: none !important;
  .cropper-bg {
    margin: 0 auto;
  }
  .cropper-modal {
    background-color: white !important;
  }
  .avatar-cropper-image-container {
    margin-bottom: 20px;
  }
}
</style>
