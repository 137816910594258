<template>
  <div class="privacy">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container" v-if="!isRaMicro">
          <h1>Privacy Policy</h1>

          <section>
            <p></p>
            <p>
              <strong>easy</strong>meet is a consulting agency, which offers
              comprehensive IT business solutions. We are committed to earning
              and keeping your trust, while giving you control over the
              information that is personal to you. Being accountable to you is
              our responsibility for which we hope to build a lasting
              relationship. Our commitment to privacy, including processing and
              protecting personal data, is demonstrated in this privacy policy
              (&#8220;Privacy Policy&#8221;).
            </p>
            <h3>Contents</h3>
            <ul>
              <li>Key Terms</li>
              <li>
                Data Controller, How to Contact Us, and Processing of Your Data
              </li>
              <li>Lawful Basis for Processing of Data</li>
              <li>Scope of this Privacy Policy</li>
              <li>How We Protect Your Information</li>
              <li>Collection of Information, including Use of Cookies</li>
              <li>Use and Sharing of Information</li>
              <li>Your Choices</li>
              <li>Children&#8217;s Privacy</li>
            </ul>
            <h3>Key Terms</h3>
            <p>The following key terms shall be used in this Privacy Policy:</p>
            <ul>
              <li>
                Information that can no longer be used to identify a particular
                person (&#8220;Anonymous Information&#8221;).
              </li>
              <li>
                Independent data controller has the same meaning as
                &#8220;controller&#8221; under Directive 95/46/EC as of October
                24th, 1995 or any subsequent data protection legislation such as
                Regulation (EU) 2016/679 as of May 25th, 2018, and is the
                natural person or legal entity, which alone or jointly with
                others, carries out processing of personal data (as defined
                below) as well as determines the purpose and means of the
                processing of personal data, composition and kinds of personal
                data to be processed, and determines actions (operations) with
                the personal data (&#8220;Data Controller&#8221; or
                &#8220;Controller&#8221;).
              </li>
              <li>
                Data where certain identifiers are removed to prevent a
                person&#8217;s identity from being directly connected with the
                information (such as masking certain personal identifiers)
                (&#8220;De-identified Data&#8221;).
              </li>
              <li>
                Processing is permitted if it is necessary for the purposes of
                legitimate interests pursued by the Controller (or by a third
                party), except where the Controllers&#8217; interests are
                overridden by the interests, fundamental rights or freedoms of
                the affected end-users which require protection
                (&#8220;Legitimate Interests&#8221;).
              </li>
              <li>
                Information that can be used to identify you, either alone or in
                combination with other information available to us, such as your
                name, shipping and billing address, e-mail address, phone
                number, identification number (where applicable), date of birth
                (where applicable), time and location data, bank account
                information, transaction and payment records, delivery records
                and other information associated with your account, which is
                collected by us during delivery of our business solution
                (&#8220;Personal Data&#8221;).
              </li>
              <li>
                <strong>easy</strong>meet may resell products through
                transactions via its business solution, and will generally be
                the seller and merchant of record for such transactions
                (&#8220;Seller and Merchant of Record&#8221;).
              </li>
              <li>
                Special categories of data require a heightened level of
                protection under data protection legislation in some
                jurisdictions, such as processing that reveals health, racial or
                ethnic origin (&#8220;Special Categories of Data&#8221;).
              </li>
              <li>
                Any applicable data protection safeguards, as may be amended or
                replaced, for the transfers of data to Controllers established
                outside of the EU/EEA that do not ensure an adequate level of
                protection (&#8220;Standard Contractual Clauses&#8221;).
              </li>
              <li>
                Supervisory Authority (or its equivalent under any data
                protection legislation) is the independent public authority to
                whom consumers may lodge a complaint (&#8220;Supervisory
                Authority&#8221;).
              </li>
            </ul>
            <h3>
              Data Controller, How to Contact Us, and Processing of Your Data
            </h3>
            <ul>
              <li>
                Controller(s) Responsible for Your Data. The
                <strong>easy</strong>meet entity that is the Data Controller
                will vary depending on the site you visit, your transaction with
                the Website and contractual language.
              </li>
              <li>
                How to Contact Us. If you have a question about this Privacy
                Policy or requests related to your Personal Data pursuant to
                applicable laws, no matter where you are in the world, please
                email us at
                &#x70;&#114;i&#x76;&#97;c&#x79;&#64;m&#x61;&#114;a&#x6d;&#45;c&#x6f;&#110;s&#x75;&#108;t&#x69;&#110;g&#x2e;&#99;o&#x6d;,
                and we will route your question appropriately. If you prefer to
                contact us by regular mail, please refer to the contact
                information as found on the &#8220;Contact Us&#8221; section of
                our Website.
              </li>
              <li>
                Processing of Your Data. Your business request, and therefore
                your Personal Data, will be optimally processed when you
                initiated your session.
              </li>
              <li>
                Appropriate Safeguards for Cross-border Transfer of Your Data.
                If you are located in the EU/EEA, any transfer of your Personal
                Data outside of the EU/EEA is protected under Standard
                Contractual Clauses, or any subsequent data protection
                safeguards. Your Personal Data may be transferred to
                <strong>easy</strong>meet servers. Your Personal Data may also
                be transmitted by us to other <strong>easy</strong>meet entities
                for the purposes set out in this Privacy Policy, for company
                administration purposes, and to other third party data
                processors.
              </li>
              <li>
                <strong>easy</strong>meet SL as the Controller. Maram Strategy
                Consulting SLU, including our subsidiaries
                (&#8220;<strong>easy</strong>meet,&#8221; &#8220;we,&#8221;
                &#8220;us,&#8221; and &#8220;our&#8221;) owns and operates this
                website, which may include a software application, our products
                and services, and any other related business solution where this
                Privacy Policy appears (&#8220;Website&#8221;). Generally, any
                Personal Data collected by visiting this Website is controlled
                by Maram Strategy Consulting SLU, Carretera San Jeronimo 15 / 2
                28014 Madrid CIF B88379102 (&#8220;Data Controller&#8221;).
              </li>
            </ul>
            <h3>Lawful Basis for Processing of Data</h3>
            <p>
              In certain jurisdictions, the processing of your Personal Data is
              lawful only if it is permitted under data protection legislation.
              In this case, we have a lawful basis for each of our processing
              activities (unless an exemption applies) as outlined below:
            </p>
            <ul>
              <li>
                Consent. By using our Website or otherwise submitting your
                Personal Data to us, you consent to our collection, use and
                sharing of your information as described in this Privacy
                Policyii. If you do not consent to this Privacy Policy, please
                do not use this Website or submit your information through this
                Website.
              </li>
              <li>
                Legitimate Interest. We will process your data based on
                Legitimate Interest, and particularly where it is used for
                marketing purposes, such as to collect data from public sources
                and to contact prospects to start a conversation. We may also
                process your data based on Legitimate Interest in the employment
                contextiii.
              </li>
              <li>
                Other Lawful Basis. We may also process your data based on
                another lawful basis such as where the processing is necessary
                to enter into or perform a contract with you.
              </li>
            </ul>
            <h3>Scope of this Privacy Policy</h3>
            <p>
              BACKGROUND AND PURPOSE<br />
              This Privacy Policy applies to each Website operated by
              <strong>easy</strong>meet where this Privacy Policy appears. If
              you are purchasing products through a <strong>easy</strong>meet
              subsidiary, please note that this Website is hosted and/or
              operated by <strong>easy</strong>meet under an agreement with the
              subsidiary who is listed as the seller of products in the terms of
              sale on this Website. The purpose of this Privacy Policy is to
              provide you with information on how we:
            </p>
            <ul>
              <li>
                collect Personal Data and other information when you use this
                Website;
              </li>
              <li>use, handle and disclose collected information; and</li>
              <li>
                choices you have about the ways we collect and use the
                information.
              </li>
            </ul>
            <p>
              CHANGES TO THIS PRIVACY POLICY<br />
              We may update this Privacy Policy to communicate changes in our
              data handling practices. When we make changes to this Privacy
              Policy, we will modify the Effective Date. Our changes will become
              effective immediately upon posting. A link to our previous policy
              is included at the end of this Privacy Policy.
            </p>
            <p>
              SUPPLEMENTAL PRIVACY NOTICES<br />
              This Privacy Policy may be supplemented or amended by other
              privacy notices appearing on the Website. Such privacy notices
              will explain how we handle information specific to where that
              privacy notice appears, and should be read together with this
              Privacy Policy.
            </p>
            <p>
              PARTNER OR OTHER THIRD PARTY PRIVACY POLICIES<br />
              This Privacy Policy only applies to the use of information we
              collect from you through this Website and any other website on
              which this Privacy Policy appears. Our Website may be co-branded
              with the information of the company whose product you are
              purchasing (&#8220;Partner&#8221;). However, this Privacy Policy
              does not apply to information collected directly by our Partners
              or any other third party as we do not control these other privacy
              policies. Please carefully review each privacy policy posted on
              each website you visit.
            </p>
            <p>
              TERMS OF SALE<br />
              Using or submitting Personal Data through the Website, shall also
              be governed by our Terms of Sale.
            </p>
            <h3>How We Protect Your Information</h3>
            <p>
              <strong>easy</strong>meet takes reasonable steps to provide a
              level of security appropriate to the sensitivity of the
              information we collect. We have implemented physical, technical
              and administrative security practices and safeguards reasonably
              designed to protect your Personal Data from unauthorised access
              and disclosureiv. When we collect or transmit sensitive
              information such as a financial account number, we use industry
              standard methods to protect that information as required by
              applicable laws. Although we use reasonable measures to protect
              your information, we cannot guarantee the security of information
              provided over the Internet or stored in our databases. You are
              also responsible for taking reasonable steps to protect your
              information against unauthorised disclosure or misuse, for
              example, by protecting your password and logging off when finished
              using a shared computer or mobile device. We leverage industry
              best practices to mitigate potential compromises. If the Personal
              Data under the custody of <strong>easy</strong>meet is (or is
              likely to be) divulged, damaged or lost, we will immediately take
              remedial measures, inform the users in a timely manner and report
              to the competent government agencies as required by applicable
              laws.
            </p>
            <h3>Collection of Information, including Use of Cookies</h3>
            <p>
              INFORMATION YOU MANUALLY PROVIDE<br />
              We collect Personal Data from you when you use this Website such
              as:
            </p>
            <ul>
              <li>
                when you provide information during a purchase of a product or
                service,
              </li>
              <li>
                when you request information about or otherwise use the Website,
              </li>
              <li>
                when you enter into a contest or promotion, sign-up for a
                newsletter or related services,
              </li>
              <li>
                when you submit an order or purchase, download or register
                products, or request additional services,
              </li>
              <li>
                when you call our customer service, you may voluntarily provide
                information to our representatives.
              </li>
            </ul>
            <p>
              SPECIAL CATEGORIES OF DATA<br />
              Generally, Special Categories of Data will not be collected or
              processedv. However, there could be a situation (such as for
              employment purposes) where such data may be processed. In this
              case, we will be sure to comply with applicable data protection
              legislation, including relying on the necessary legal grounds to
              process the data (e.g., processing health data for an insurance
              contract). We shall use reasonable security practices and
              procedures to safeguard your Special Categories of Data.
            </p>
            <p>
              INFORMATION FROM YOUR BROWSER OR DEVICE<br />
              We may also receive information from your web browser. This may
              include information that does not identify you personally (such as
              the date and time of your visit). The information we receive
              depends on the settings on your web browser. If you created a user
              identity during a visit to this Website, this shall be regarded as
              giving your consent for processing your Personal Data, if any, and
              we may link the information provided by your browser to
              information that identifies you personally, as described below.
              Please review the settings on your web browser to learn how to
              change your browser settings. We may also collect limited Personal
              Data (e-mail address) through web technologies in connection with
              your use of the Website, as described below.
            </p>
            <p>
              COOKIES AND OTHER SIMILAR TECHNOLOGIES<br />
              The information we collect using cookies and similar technologies
              is not typically personally identifiable, but we may link it to
              Personal Data that you provide. We use cookies and other similar
              technologies to collect information and support certain features
              of this Website. As the Internet evolves, we may use different
              types of technologies for this purpose. For example, we may use
              these technologies to collect information about the ways visitors
              use this Website (such as which pages they visit, links they use).
              We analyse this information to better understand our
              visitors&#8217; interests, needs and to improve the content and
              functionality of the Website. We also use the information to
              support Website features by not asking you to re-enter information
              already in our database. We also use this information to
              personalise your experience when you use the Website and to
              improve our marketing efforts, including through use of targeted
              advertising.<br />
              If you do not wish to receive cookies, you may set your browser to
              reject cookies or to alert you when a cookie is placed on your
              computer. Although you are not required to accept cookies, you may
              be unable to use all of the Website functionality if your browser
              rejects our cookies.
            </p>
            <p>
              INFORMATION ABOUT THIRD-PARTY COOKIES AND OTHER TECHNOLOGIES<br />
              Web technologies may also be placed on this Website by third party
              providers. Also, we or third party providers may place web
              technologies on behalf of the Partner whose branding appears on
              this Website or its service providers. In this way, our Partner
              would be allowed to use the data collected. This information is
              used to enhance your experience with the Website, for marketing
              analysis, for targeted and display advertising, and for quality
              improvement. We may collect your IP address through web
              technologies.<br />
              We and our Partners use third party service providers in
              connection with this Website, which may include Alexa Metrics and
              Google Analytics. These and other service providers may collect
              information about your visits to websites, your interaction with
              that website, and the products and services offered by us, our
              Partners and our suppliers. Their collection and use of
              information is subject to their own privacy policies. Please visit
              the applicable third party service provider if you would like to
              opt-out per below:<br />
              If you would like to opt-out of Google Ads categories associated
              with your cookies for display advertising purposes, please visit
              www.google.com/settings/ads , and
            </p>
            <p>
              DO NOT TRACK REQUESTS<br />
              If you opt-out of having your information collected through
              cookies and other web technologies, your existing display
              advertising cookie(s) will be deleted and a new cookie will
              attempt to be placed that instructs service providers not to track
              your future activities when that cookie is detected (a
              &#8220;no-track&#8221; cookie). If your browsers are configured to
              reject cookies when you visit our opt-out page, a
              &#8220;no-track&#8221; cookie cannot be set on your computer.
              Also, if you subsequently erase &#8220;no-track&#8221; cookies,
              use a different computer or change web browsers, you will need to
              opt-out again. We currently do not respond to browser
              &#8220;no-track&#8221; signals.
            </p>
            <p>
              INFORMATION FROM THIRD PARTIES<br />
              We may also receive certain information about you from third
              parties such as through your use of a social network
              authentication to sign-in to this Website, or through other online
              data sources, according to the rules of the use and processing of
              such information established by the respective online data
              sources. If any Special Categories of Data are freely available or
              accessible in the public domain, we will not have any obligations
              to you regarding the same.
            </p>
            <p>
              LOCATION INFORMATION<br />
              When you use a smart phone, mobile device or computer to access
              our Website or use our mobile application, we may collect
              information about your physical location. We may combine this
              information with other location-based information (e.g., your IP
              address and billing/postal code), to provide you with other
              services on your mobile device. We share your location information
              only with third parties that help us provide you with services.
              You can opt-out of sharing this information by changing the
              permissions in your mobile device.
            </p>
            <h3>Use and Sharing of Information</h3>
            <p>
              Your Personal Data provided will be processed on our computer
              servers. In some cases (such as where it is necessary to fulfill
              our contractual obligations to you) the information may also be
              made available to third parties providing services to
              <strong>easy</strong>meet.
            </p>
            <p>
              HOW WE USE YOUR DATA<br />
              We may use your Personal Data for the following purposes:
            </p>
            <ul>
              <li>
                to contact you if you have requested communication from us,
              </li>
              <li>
                to determine the country where you are located such as for trade
                compliance, security and fraud prevention,
              </li>
              <li>to otherwise verify compliance with applicable laws,</li>
              <li>to help verify that data we have about you is accurate,</li>
              <li>
                to provide you with product updates, special offers and other
                promotional information, either on our own behalf or on the
                behalf of a Partner, where you have consented to receive it and
                in accordance with applicable law,
              </li>
              <li>
                in connection with keys, access codes or other information so
                you can access the websites or services of our Partners to
                receive products or services,
              </li>
              <li>
                as identified in a supplemental privacy notice posted on the
                Website, and
              </li>
              <li>
                for Human Resources such as to collect your email address if you
                are applying for a position online.
              </li>
            </ul>
            <p>
              We may also use your Personal Data for the following purposes
              where you visit the Website to complete a transaction:
            </p>
            <ul>
              <li>
                to process your order, process payment, verify your tax status,
                contest chargebacks, determine your eligibility for a line of
                credit, or notify you of the status of your order,
              </li>
              <li>for fraud monitoring and prevention purposes,</li>
              <li>to provide you with a personalised shopping experience,</li>
              <li>
                to register your purchase with the manufacturer or service
                provider for warranty, technical support or similar purposes,
              </li>
              <li>
                to provide notice of your purchase to the provider of an online
                service for which you purchase a service use right from us,
              </li>
              <li>
                to establish an account for future purchases that you have
                consented to,
              </li>
              <li>
                to facilitate the renewal of subscriptions for products or
                services, and
              </li>
              <li>
                to provide you with an effective experience and support (which
                may, as allowable by law, include contacting visitors who start
                a checkout process to follow-up on the incomplete session or to
                see if there was a problem with their use of the Website).
              </li>
            </ul>
            <p>
              We will take reasonable steps to ensure any person or entity
              receiving Personal Data for the purposes described above, are
              obligated to keep the Personal Data secure. If any Special
              Categories of Data are being collected, received or retained by an
              external entity, details of such entity will be shared with you if
              required by applicable law. Our obligations will not, apply to
              information shared with any Government entity mandated under law
              to obtain such information or by an order under law for the time
              being in force.
            </p>
            <p>
              AUTOMATED PROCESSING<br />
              In limited situations, we may use your Personal Data to help us
              make automated decisions to further improve our business. For
              example, in the context of marketing, we could use data to segment
              and target users with personalised messages. For example, we may
              use a job title to determine the users&#8217; persona and alter
              the content accordingly. We may also use that users&#8217;
              geographic location and company data. In some circumstances, for
              account-based marketing, this could include &#8220;likes&#8221;
              that we find in the public domain.<br />
              Also, we rely on fraud monitoring to help us reduce the need to
              manually review orders for fraud. We could also rely on automated
              processing in the context of employment and to ensure our
              employees are adhering to our internal security policies. You have
              the right to avoid any decisions based on automated data
              processing where they can be characterised as profiling. However,
              in this case, we reserve the right not to complete your
              transaction.
            </p>
            <p>
              OUR USE OF ANONYMOUS INFORMATION<br />
              We may use Anonymous Information collected by us. In this case,
              the following data elements will be anonymised such as first and
              last name, street address, phone number, email address and other
              data elements in accordance with applicable law. The Anonymous
              Information may be used as described here:
            </p>
            <ul>
              <li>
                to personalise and support your use of this Website and/or the
                services of our Partners,
              </li>
              <li>
                to improve this Website, the customer experience, our
                advertising systems, and our products and services,
              </li>
              <li>
                for fraud prevention purposes, such as device fingerprinting,
              </li>
              <li>
                to identify transactions as originating through an affiliate
                marketing or referral program,
              </li>
              <li>
                to deliver targeted advertisements on this Website and other
                commerce solutions,
              </li>
              <li>
                to provide reporting to our current and prospective Partners and
                service providers, and
              </li>
              <li>
                for other historical, statistical, research and analysis
                purposes.
              </li>
            </ul>
            <p>
              We may also use De-identified Data for the purposes mentioned
              above, and related purposes as allowed by applicable law.
            </p>
            <p>
              OUR PARTNERS<br />
              We will provide certain Personal Data, Anonymous Information or
              De-identified Data to our Partners to fulfill our obligations to
              you, and otherwise assist <strong>easy</strong>meet&#8217;s use of
              Personal Data as described in the &#8220;How We Use Your
              Data&#8221; section:
            </p>
            <ul>
              <li>for reporting purposes,</li>
              <li>
                to allow our Partner or its service provider to register your
                purchase,
              </li>
              <li>
                to enable your access to products or services provided by our
                Partner or its suppliers,
              </li>
              <li>
                to facilitate warranty, technical support or after-sales
                service,
              </li>
              <li>
                to allow our Partner or subcontractor to send email or direct
                mail communications if you have consented to receive them,
              </li>
              <li>
                to allow our Partner or its service provider to provide services
                in connection with this Website (such as customer support or
                single sign-on functionality), or
              </li>
              <li>
                for similar purposes in order to fulfill obligations to you.
              </li>
            </ul>
            <p>
              OUR SERVICE PROVIDERS<br />
              We utilise other companies to provide certain services to us or on
              our behalf to help us operate our business such as:
            </p>
            <ul>
              <li>to host or maintain this Website,</li>
              <li>to process credit card payments,</li>
              <li>to provide fraud monitoring or detection services,</li>
              <li>to perform data integrity checks,</li>
              <li>to offer you a line of credit,</li>
              <li>to provide optimisation services,</li>
              <li>to fulfill your order,</li>
              <li>for payment collection,</li>
              <li>
                to deliver advertisements on this Website and third party
                websites,
              </li>
              <li>to send mail or e-mail,</li>
              <li>
                for purposes related to employment and Human Resources, and/or
              </li>
              <li>to provide customer service.</li>
            </ul>
            <p>
              We will not share your Personal Data with our service providers in
              connection with their offering of services to us, unless it is
              required for the purposes of our Legitimate Interests and purposes
              of fulfilling our obligations as to delivery of our business
              solution to you. These service providers may be contractually
              required to use any information we share with them only to perform
              services to us or on our behalf and to protect the confidentiality
              of your Personal Data. We may also allow these companies to use
              Anonymous Information for their business purposes. We will not
              share your Personal Data with our affiliates or unrelated third
              parties to use for their own marketing purposes without your
              consent.
            </p>
            <p>
              TO COMPLY WITH LEGAL REQUIREMENTS, PREVENT FRAUD AND CRIMES<br />
              As permitted by applicable law, we may disclose Personal Data or
              Special Categories of Data about you when we believe disclosure is
              appropriate to:
            </p>
            <ul>
              <li>to comply with the law or a regulatory requirement,</li>
              <li>
                to comply with governmental, administrative or judicial process,
                requirement or order (such as a court order),
              </li>
              <li>
                to cooperate with law enforcement or other governmental
                investigations or report any activities that may be in violation
                of legal or regulatory requirements,
              </li>
              <li>
                to prevent or investigate a possible crime (such as fraud or
                identity theft) or breaches (also suspected breaches) of
                security or of this Privacy Policy,
              </li>
              <li>to enforce a contract,</li>
              <li>
                to protect the legal rights, Website or safety of
                <strong>easy</strong>meet and our employees, Partners, third
                parties, the public in general or youvi.
              </li>
            </ul>
            <h3>Your Choices</h3>
            <p>
              We respect your right to make choices about the ways we collect,
              use and share your Personal Data.
            </p>
            <p>
              ACCESS TO YOUR INFORMATION<br />
              If you would like to review, correct, restrict the processing
              thereof, object to direct marketing, or request erasure of your
              Personal Data or Special Categories of Data, please send us a
              request using the information in the &#8220;How to Contact
              Us&#8221; section of this Privacy Policy. For example, if there is
              any error in the Personal Data that you have submitted to us,
              please contact us and we will take appropriate measures to correct
              the error.<br />
              If you contact us we may ask you for additional information to
              verify your identity. We reserve the right to limit or deny your
              request if you have failed to provide sufficient information to
              verify your identity or to satisfy our legal and business
              requirements as noted in our data retention policy. When we delete
              your Personal Data or Special Categories of Data, it will be
              disposed of in a manner that prevents loss, theft, misuse, or
              unauthorised access in-line with our data retention policy.<br />
              You have the right to report to your local Supervisory Authority
              where you feel as though your rights under data protection
              legislation have not been appropriately considered. However,
              before doing so, please contact us directly as we are committed to
              working with you to help resolve any concerns about your privacy
            </p>
            <p>
              DATA RETENTION POLICY<br />
              <strong>easy</strong>meet may retain your Personal Data for as
              long as you continue to use our Website, and thereafter as
              permitted or required by applicable law or to satisfy our business
              requirements.
            </p>
            <p>
              MARKETING AND RELATED-COMMUNICATIONS<br />
              When you provide Personal Data, we may offer you a choice as to
              whether you would like to receive further marketing and related
              communications from us or our Partners. These communications could
              be in the form of direct mail, email or telephone and will contain
              information to inform you of our products and services. You may
              opt-out of receiving these communications by e-mailing us at
              &#112;&#x72;&#105;&#x76;a&#x63;y&#64;&#x6d;&#97;&#x72;&#97;&#x6d;-&#x63;o&#110;&#x73;&#117;&#x6c;t&#x69;n&#x67;&#x2e;&#99;&#x6f;&#109;,
              or by following any unsubscribe instructions in e-mail
              communications.<br />
              If you give us consent to add your contact information to our
              Partner&#8217;s mailing list and later withdraw this permission,
              you must contact our Partner (or follow the unsubscribe
              instructions in the e-mails from our Partner) to have your name
              removed from our Partner&#8217;s mailing lists.
            </p>
            <h3>Children&#8217;s Privacy</h3>
            <p>
              This Website is not directed at nor targeted to children. If you
              have not reached the age of majority or are not able to enter into
              legally binding agreements in your country, you may not use this
              Website unless necessary parental consent has been obtained. If
              you believe that we have received information from a person
              protected under child protection laws where necessary parental
              consent was not obtained, please notify us immediately, and we
              will take reasonable steps to securely remove that information.
            </p>
          </section>
        </div>
        <div class="container" v-else>
          <h1>Datenschutzerklärung</h1>

          <h3>Verantwortliche Stelle für die Verarbeitung nach DSGVO</h3>
          <p>
            Verantwortlicher im Sinne der Datenschutz-Grundverordnung und
            sonstiger in den Mitgliedstaaten der Europäischen Union geltenden
            Datenschutzgesetze und anderer Bestimmungen mit
            datenschutzrechtlichem Charakter ist die:
          </p>
          <p>vOffice SE<br />Am Borsigturm 56<br />13507 Berlin</p>
          <h3>Datenschutzerklärung</h3>
          <p>
            Wir begrüßen Sie auf unseren Webseiten und freuen uns über Ihr
            Interesse. Der Schutz Ihrer personenbezogenen Daten ist uns ein
            wichtiges Anliegen. Deshalb betreiben wir unsere Aktivitäten in
            Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz
            personenbezogener Daten und zur Datensicherheit. Daher möchten wir
            Sie nachfolgend darüber informieren, welche Daten Ihres Besuchs zu
            welchen Zwecken verwendet werden. Sollten darüber hinaus noch Fragen
            zum Umgang mit Ihren personenbezogenen Daten offenbleiben, können
            Sie sich gerne an unseren Datenschutzbeauftragten wenden:
          </p>
          <p>
            Nils Möllers<br />Keyed GmbH<br />Siemensstrasse 12<br />48341
            Altenberge<br /><a
              href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#x66;&#x6F;&#64;&#107;&#101;&#x79;&#x65;&#x64;&#46;&#100;&#x65;"
              >&#105;&#110;&#x66;&#x6F;&#64;&#107;&#101;&#x79;&#x65;&#x64;&#46;&#100;&#x65;</a
            >
          </p>
          <h3>1. Was sind personenbezogene Daten?</h3>
          <p>
            Der Begriff der personenbezogenen Daten ist im
            Bundesdatenschutzgesetz und in der EU-DSGVO definiert. Danach sind
            dies Einzelangaben über persönliche oder sachliche Verhältnisse
            einer bestimmten oder bestimmbaren natürlichen Person. Darunter
            fallen also beispielsweise Ihr bürgerlicher Name, Ihre Anschrift,
            Ihre Telefonnummer oder Ihr Geburtsdatum.
          </p>
          <h3>2. Umfang der anonymen Datenerhebung und Datenverarbeitung</h3>
          <p>
            Soweit in den folgenden Abschnitten nicht anderweitig dargestellt,
            werden bei Nutzung unserer Webseiten grundsätzlich keine
            personenbezogenen Daten erhoben, verarbeitet oder genutzt. Wir
            erfahren aber über den Einsatz von Analyse- und Trackingtools
            bestimmte technische Informationen anhand der von Ihrem Browser
            übermittelten Daten (beispielsweise Browsertyp/-version, verwendetes
            Betriebssystem, bei uns besuchte Webseiten inkl. Verweildauer, zuvor
            besuchte Webseite). Diese Informationen werten wir nur zu
            statistischen Zwecken aus.
          </p>
          <h3>
            3. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
          </h3>
          <p>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
            Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
            lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für
            die Verarbeitung personenbezogener Daten.
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
            eines Vertrages, dessen Vertragspartei die betroffene Person ist,
            erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
            Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
            Durchführung vorvertraglicher Maßnahmen erforderlich sind.
          </p>
          <p>
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
            rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
            unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person
            oder einer anderen natürlichen Person eine Verarbeitung
            personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
            lit. d DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
            unseres Unternehmens oder eines Dritten erforderlich und überwiegen
            die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
            erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO
            als Rechtsgrundlage für die Verarbeitung.
          </p>
          <h3>4. Verwendung von Cookies</h3>
          <p>
            Die Internetseiten der vOffice SE verwenden Cookies. Bei Cookies
            handelt es sich um Daten, die vom Internetbrowser auf dem
            Computersystem des Nutzers gespeichert werden. Die Cookies können
            beim Aufruf einer Seite an diese übermittelt werden und ermöglichen
            somit eine Zuordnung des Nutzers. Cookies helfen dabei, die Nutzung
            von Internetseiten für die Nutzer zu vereinfachen. In diesen Zwecken
            liegt auch unser berechtigtes Interesse in der Verarbeitung der
            personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO. Wir
            verwenden auf unserer Website darüber hinaus Cookies, die eine
            Analyse des Surfverhaltens der Nutzer ermöglichen.
          </p>
          <p>(1) Eingegebene Suchbegriffe</p>
          <p>(2) Häufigkeit von Seitenaufrufen</p>
          <p>(3) Inanspruchnahme von Website-Funktionen</p>
          <p>
            Es ist jederzeit möglich der Setzung von Cookies durch entsprechende
            Änderung der Einstellung im Internetbrowser zu wiedersprechen.
            Gesetze Cookies können gelöscht werden. Es wird darauf hingewiesen,
            dass bei Deaktivierung von Cookies möglicherweise nicht alle
            Funktionen unserer Internetseite vollumfänglich genutzt werden
            können. Die auf diese Weise erhobenen Daten der Nutzer werden durch
            technische Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung
            der Daten zum aufrufenden Nutzer nicht mehr möglich. Die Daten
            werden nicht gemeinsam mit sonstigen personenbezogenen Daten der
            Nutzer gespeichert.
          </p>
          <p>
            Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner
            über die Verwendung von Cookies zu Analysezwecken informiert und auf
            diese Datenschutzerklärung verwiesen. Es erfolgt in diesem
            Zusammenhang auch ein Hinweis darauf, wie die Speicherung von
            Cookies in den Browsereinstellungen unterbunden werden kann.
          </p>
          <p>
            Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            unter Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit.
            f DSGVO. Die Rechtsgrundlage für die Verarbeitung personenbezogener
            Daten unter Verwendung von Cookies zu Analysezwecken ist bei
            Vorliegen einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs.
            1 lit. a DSGVO.
          </p>
          <h3>5. Erstellung von Logfiles</h3>
          <p>
            Bei jedem Aufruf der Internetseite erfasst die vOffice SE durch ein
            automatisiertes System Daten und Informationen. Diese werden in den
            Logfiles des Servers gespeichert. Die Daten werden ebenfalls in den
            Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten
            zusammen mit anderen personenbezogenen Daten des Nutzers findet
            nicht statt.
          </p>
          <p>Folgende Daten können hierbei erhoben werden:</p>
          <p>
            (1) Informationen über den Browsertyp und die verwendete Version
          </p>
          <p>(2) Das Betriebssystem des Nutzers</p>
          <p>(3) Den Internet-Service Provider des Nutzers</p>
          <p>(4) Die IP-Adresse des Nutzers</p>
          <p>(5) Datum und Uhrzeit des Zugriffs</p>
          <p>
            (6) Webseiten, von denen das System des Nutzers auf unsere
            Internetseite gelangt (Referrer)
          </p>
          <p>
            (7) Webseiten, die vom System des Nutzers über unsere Webseite
            aufgerufen werden
          </p>
          <h3>6. Möglichkeiten zur Kontaktaufnahme</h3>
          <p>
            Auf den Internetseiten von vOffice SE ist ein Kontaktformular
            vorhanden, dass für die elektronische Kontaktaufnahme genutzt werden
            kann. Alternativ ist eine Kontaktaufnahme über die bereitgestellte
            E-Mail-Adresse möglich. Nimmt die betroffene Person über einen
            dieser Kanäle Kontakt mit dem für die Verarbeitung Verantwortlichen
            auf, so werden die von der betroffenen Person übermittelten
            personenbezogenen Daten automatisch gespeichert. Die Speicherung
            dient allein zu Zwecken der Bearbeitung oder der Kontaktaufnahme zur
            betroffenen Person. Eine Weitergabe der Daten an Dritte findet nicht
            statt. Rechtsgrundlage für die Verarbeitung der Daten ist bei
            Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
          </p>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
            Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit.
            f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages
            ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6
            Abs. 1 lit. b DSGVO.
          </p>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Für die
            personenbezogenen Daten aus der Eingabemaske des Kontaktformulars
            und diejenigen, die per E-Mail übersandt wurden, ist dies dann der
            Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
            Beendet ist die Konversation dann, wenn sich aus den Umständen
            entnehmen lässt, dass der betroffene Sachverhalt abschließend
            geklärt ist.
          </p>
          <p>
            Die während des Absende-Vorgangs zusätzlich erhobenen
            personenbezogenen Daten werden spätestens nach einer Frist von
            sieben Tagen gelöscht.
          </p>
          <h3>
            7. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
          </h3>
          <p>
            Der für die Verarbeitung Verantwortliche verarbeitet und speichert
            personenbezogene Daten der betroffenen Person nur solange, wie dies
            zur Erreichung des Speicherungszwecks erforderlich ist. Eine
            Speicherung kann darüber hinaus dann erfolgen, soweit dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der für
            die Verarbeitung verantwortliche unterliegt, vorgesehen wurde.
          </p>
          <p>
            Sobald der Speicherungszweck entfällt oder eine durch die genannten
            Vorschriften vorgeschriebene Speicherfrist abläuft, werden die
            personenbezogenen Daten routinemäßig gesperrt oder gelöscht.
          </p>
          <h3>8. Rechte der betroffenen Person</h3>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            Betroffener i.S.d. DS-GVO und es stehen Ihnen folgende Rechte
            gegenüber dem Verantwortlichen zu:
          </p>
          <h3>8.1 Auskunftsrecht</h3>
          <p>
            Sie können von dem Verantwortlichen eine Bestätigung darüber
            verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
            verarbeitet werden.
          </p>
          <p>
            Liegt eine solche Verarbeitung vor, können Sie von dem
            Verantwortlichen über folgende Informationen Auskunft verlangen:
          </p>
          <p>
            a. die Zwecke, zu denen die personenbezogenen Daten verarbeitet
            werden;
          </p>
          <p>
            b. die Kategorien von personenbezogenen Daten, welche verarbeitet
            werden;
          </p>
          <p>
            c. die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
            die Sie betreffenden personenbezogenen Daten offengelegt wurden oder
            noch offengelegt werden;
          </p>
          <p>
            d. die geplante Dauer der Speicherung der Sie betreffenden
            personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
            möglich sind, Kriterien für die Festlegung der Speicherdauer;
          </p>
          <p>
            e. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
            der Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung;
          </p>
          <p>
            f. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          </p>
          <p>
            g. alle verfügbaren Informationen über die Herkunft der Daten, wenn
            die personenbezogenen Daten nicht bei der betroffenen Person erhoben
            werden;
          </p>
          <p>
            h. das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DS-GVO und –
            zumindest in diesen Fällen – aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
          </p>
          <p>
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
            betreffenden personenbezogenen Daten in ein Drittland oder an eine
            internationale Organisation übermittelt werden. In diesem
            Zusammenhang können Sie verlangen, über die geeigneten Garantien
            gem. Art. 46 DS-GVO im Zusammenhang mit der Übermittlung
            unterrichtet zu werden.
          </p>
          <h3>8.2 Recht auf Berichtigung</h3>
          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            gegenüber dem Verantwortlichen, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder
            unvollständig sind. Der Verantwortliche hat die Berichtigung
            unverzüglich vorzunehmen.
          </p>
          <h3>8.3 Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der
            Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
          </p>
          <p>
            a. wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
            für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht,
            die Richtigkeit der personenbezogenen Daten zu überprüfen;
          </p>
          <p>
            b. die Verarbeitung unrechtmäßig ist und Sie die Löschung der
            personenbezogenen Daten ablehnen und stattdessen die Einschränkung
            der Nutzung der personenbezogenen Daten verlangen;
          </p>
          <p>
            c. der Verantwortliche die personenbezogenen Daten für die Zwecke
            der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigen, oder
          </p>
          <p>
            d. wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
            DS-GVO eingelegt haben und noch nicht feststehen, ob die
            berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
            überwiegen.
          </p>
          <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
            eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen
            – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
            anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines
            Mitgliedstaats verarbeitet werden.
          </p>
          <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g.
            Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
            unterrichtet bevor die Einschränkung aufgehoben wird.
          </p>
          <h3>8.4 Recht auf Löschung</h3>
          <h3>
            8.4.1 Sie können von dem Verantwortlichen verlangen, dass die Sie
            betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
            und der Verantwortliche ist verpflichtet diese Daten unverzüglich zu
            löschen, sofern einer der folgenden Gründe zutrifft:
          </h3>
          <p>
            a. Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
            für die sie erhoben oder auf sonstige Weise verarbeitet wurden,
            nicht mehr notwendig.
          </p>
          <p>
            b. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
            gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DS-GVO stützte,
            und es fehlt an einer anderweitigen Rechtsgrundlage für die
            Verarbeitung.
          </p>
          <p>
            c. Sie legen gem. Art. 21 Abs. 1 DS-GVO Widerspruch gegen die
            Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
            für die Verarbeitung vor, oder die Sie legen gem. Art. 21 Abs. 2
            DS-GVO Widerspruch gegen die Verarbeitung ein.
          </p>
          <p>
            d. Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
            verarbeitet.
          </p>
          <p>
            e. Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
            Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
            dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
            unterliegt.
          </p>
          <p>
            f. Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
            angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
            DS-GVO erhoben.
          </p>
          <h3>
            8.4.2 Hat der Verantwortliche die Sie betreffenden personenbezogenen
            Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DS-GVO zu
            deren Löschung verpflichtet, so trifft er unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene
            Maßnahmen, auch technischer Art, um für die Datenverarbeitung
            Verantwortliche, die die personenbezogenen Daten verarbeiten,
            darüber zu informieren, dass Sie als betroffene Person von ihnen die
            Löschung aller Links zu diesen personenbezogenen Daten oder von
            Kopien oder Replikationen dieser personenbezogenen Daten verlangt
            hat.
          </h3>
          <h3>
            8.4.3 Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
            erforderlich ist
          </h3>
          <p>
            a. zur Ausübung des Rechts auf freie Meinungsäußerung und
            Information;
          </p>
          <p>
            b. zur Erfüllung einer rechtlichen Verpflichtung, die die
            Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem
            der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung
            einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde;
          </p>
          <p>
            c. aus Gründen des öffentlichen Interesses im Bereich der
            öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art.
            9 Ab. 3 DS-GVO;
          </p>
          <p>
            d. für im öffentlichen Interesse liegende Archivzwecke,
            wissenschaftliche oder historische Forschungszwecke oder für
            statistische Zwecke gem. Art. 89 Abs. 1 DS-GVO, soweit das in Abs. 1
            genannte Recht voraussichtlich die Verwirklichung der Ziele dieser
            Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
          </p>
          <p>
            e. zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </p>
          <h3>8.5 Recht auf Unterrichtung</h3>
          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
            dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
            Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
            es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden.
          </p>
          <p>
            Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
            Empfänger unterrichtet zu werden.
          </p>
          <h3>8.6 Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das das Recht, die Sie betreffenden personenbezogenen
            Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Außerdem haben Sie das Recht diese Daten einem anderen
            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
            sofern
          </p>
          <p>
            a. die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
            DS-GVO oder Art. 9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gem.
            Art. 6 Abs. 1 lit. b DS-GVO beruht und
          </p>
          <p>b. die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht zu erwirken,
            dass die Sie betreffenden personenbezogenen Daten direkt von einem
            Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist. Freiheiten und Rechte anderer
            Personen dürfen hierdurch nicht beeinträchtigt werden.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
            personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in
            Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
            übertragen wurde.
          </p>
          <h3>8.7 Widerspruchsrecht</h3>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
            lit. e oder f DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch
            für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>
            Der Verantwortliche verarbeitet die Sie betreffenden
            personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die die Ihre
            Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
            dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </p>
          <p>
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </p>
          <p>
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
            werden die Sie betreffenden personenbezogenen Daten nicht mehr für
            diese Zwecke verarbeitet.
          </p>
          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
            Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
            2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
            auszuüben, bei denen technische Spezifikationen verwendet werden.
          </p>
          <h3>
            8.8 Recht auf Widerruf der datenschutzrechtlichen
            Einwilligungserklärung
          </h3>
          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
            der Einwilligung wird die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          </p>
          <h3>
            8.9 Automatisierte Entscheidung im Einzelfall einschließlich
            Profiling
          </h3>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche
            Wirkung entfaltet oder sie in ähnlicher Weise erheblich
            beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
          </p>
          <p>
            a. für den Abschluss oder die Erfüllung eines Vertrags zwischen
            Ihnen und dem Verantwortlichen erforderlich ist,
          </p>
          <p>
            b. aufgrund von Rechtsvorschriften der Union oder der
            Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist
            und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer
            Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten
            oder
          </p>
          <p>c. mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
          <p>
            Allerdings dürfen diese Entscheidungen nicht auf besonderen
            Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DS-GVO
            beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und
            angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie
            Ihrer berechtigten Interessen getroffen wurden.
          </p>
          <p>
            Hinsichtlich der in a. und c. genannten Fälle trifft der
            Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
            sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
            Recht auf Erwirkung des Eingreifens einer Person seitens des
            Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
            Anfechtung der Entscheidung gehört.
          </p>
          <h3>8.10 Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
            betreffenden personenbezogenen Daten gegen die DS-GVO verstößt.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
            unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
            der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
            Rechtsbehelfs nach Art. 78 DS-GVO.
          </p>
          <h3>9. Einbindung von übrigen Diensten und Inhalten Dritter</h3>
          <p>
            Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte
            Dritter, wie zum Beispiel Videos von YouTube, Kartenmaterial von
            Google-Maps, RSS-Feeds oder Grafiken von anderen Webseiten
            eingebunden werden. Dies setzt immer voraus, dass die Anbieter
            dieser Inhalte (nachfolgend bezeichnet als „Dritt-Anbieter“) die
            IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse, könnten
            sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden.
            Die IP-Adresse ist damit für die Darstellung dieser Inhalte
            erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
            jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
            Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls
            die Dritt-Anbieter die IP-Adresse z.B. für statistische Zwecke
            speichern. Soweit dies uns bekannt ist, klären wir die Nutzer
            darüber auf.
          </p>
          <h3>10. Dauer der Speicherung personenbezogener Daten</h3>
          <p>
            Personenbezogene Daten werden für die Dauer der jeweiligen
            gesetzlichen Aufbewahrungsfrist gespeichert. Nach Ablauf der Frist
            erfolgt eine routinemäßige Löschung der Daten, sofern nicht eine
            Erforderlichkeit für eine Vertragsanbahnung oder die
            Vertragserfüllung besteht.
          </p>
          <h3>11. Karriere (Ausbildung &amp; Stellenangebote)</h3>
          <p>
            Sie können sich auch auf elektronischem Wege bei unserem Unternehmen
            bewerben. Ihre Angaben werden wir selbstverständlich ausschließlich
            zur Bearbeitung Ihrer Bewerbung verwenden und nicht an Dritte
            weitergeben. Bitte beachten Sie, dass unverschlüsselt übersandte
            E-Mails nicht zugriffsgeschützt übermittelt werden.
          </p>
          <h3>12. Sicherheit</h3>
          <p>
            Wir haben umfangreiche technische und betriebliche
            Schutzvorkehrungen getroffen, um Ihre Daten vor zufälligen oder
            vorsätzlichen Manipulationen, Verlust, Zerstörung oder dem Zugriff
            unberechtigter Personen zu schützen. Unsere Sicherheitsverfahren
            werden regelmäßig überprüft und dem technologischen Fortschritt
            angepasst. Darüber hinaus wird der Datenschutz bei uns fortlaufend
            gewährt, durch ständige Auditierung und Optimierung der
            Datenschutzorganisation.
          </p>
          <p>&nbsp;</p>
          <p>
            Diese Datenschutzerklärung wurde erstellt am 16.05.2018 von Nils
            Möllers. vOffice SE behält sich sämtliche Rechte vor um Änderungen
            und Aktualisierungen dieser Datenschutzerklärung vorzunehmen.
          </p>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isRaMicro } from "@/utils";

export default {
  name: "privacy",
  components: {},
  data: () => ({
    isRaMicro: isRaMicro()
  }),
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
</style>
