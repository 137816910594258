<template>
  <v-dialog v-model="showModalEditInfo" persistent max-width="600px">
    <v-card class="pb-3 pt-2">
      <v-card-title>{{ getTranslations() }}</v-card-title>
      <v-card-text>
        <v-row class="mt-0">
          <v-col cols="12">
            <v-text-field
              v-model="personalInfo"
              dense
              outlined
              hide-details
              :label="getTranslations()"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn class="mx-2" depressed color="primary" @click="closeModal">
          {{ $t("paymentModals.close") }}
        </v-btn>
        <v-btn class="mx-2" depressed color="primary" @click="savePersonalInfo">
          {{ $t("paymentModals.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "EditInfoModal",
  props: ["showModalEditInfo", "closeModal", "targetField"],
  components: {},
  data: () => ({
    personalInfo: ""
  }),
  mounted() {
    this.personalInfo = "";
  },
  methods: {
    savePersonalInfo() {},
    getTranslations() {
      switch (this.targetField) {
        case "name":
          return this.$t(`profile.personalInfo.name`);
        case "language":
          return this.$t(`profile.personalInfo.language`);
        case "company":
          return this.$t(`profile.personalInfo.company`);
        case "street":
          return this.$t(`profile.personalInfo.street`);
        case "address":
          return this.$t(`profile.personalInfo.address`);
        case "country":
          return this.$t(`profile.personalInfo.country`);
        case "taxNo":
          return this.$t(`profile.personalInfo.taxNo`);
      }
    }
  }
};
</script>
