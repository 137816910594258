<template>
  <div>
    <v-dialog
      v-model="showModalSettings"
      persistent
      max-width="600px"
      attach=".call"
    >
      <v-card>
        <HeaderModals :titleModal="$t('paymentModals.settings')"></HeaderModals>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="clientID"
                  dense
                  outlined
                  hide-details
                  :label="$t('paymentModals.clientID')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="clientSecret"
                  dense
                  outlined
                  hide-details
                  :label="$t('paymentModals.clientSecret')"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeModal">
            {{ $t("paymentModals.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading || loadingButton"
            @click="saveSettings"
            :disabled="disableSaveSettings"
          >
            {{ $t("paymentModals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert
      v-if="showSettingsSaved"
      border="left"
      class="alertSuccess genericAlerts"
      colored-border
      dense
      color="green accent-4"
      elevation="2"
    >
      Settings saved
    </v-alert>
    <v-alert
      v-if="hasError"
      border="left"
      class="alertSuccess genericAlerts"
      colored-border
      dense
      color="red accent-4"
      elevation="2"
    >
      {{ error }}
    </v-alert>
  </div>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
export default {
  components:{ HeaderModals },
  name: "settingsModal",
  props: ["showModalSettings", "closeModal"],
  data: () => ({
    clientID: '',
    clientSecret: '',
    loadingButton: false,
    showSettingsSaved: false,
  }),
  watch:{
    hasSuccess:{
      inmediate: true,
      handler: function(value){
        if ( value ){
          this.showSettingsSaved = true;
          setTimeout(() => {
            this.showSettingsSaved = false;
          }, 3000)
        }
      }
    }
  },
  mounted(){
    if ( this.user && this.$store.state.user.user.hasSettings ){
      this.clientID = this.$store.state.user.user.clientID;
      this.clientSecret = this.$store.state.user.user.clientSecret;
    }
    if ( !this.user ){
      let localStorageSettings = localStorage.getItem('userSettings');
      if ( localStorageSettings ){
        localStorageSettings = JSON.parse(localStorageSettings);
        this.clientID = localStorageSettings.clientID;
        this.clientSecret = localStorageSettings.clientSecret;
      }
    }
  },
  methods:{
    saveSettings(){
      if ( this.clientID !== '' && this.clientSecret !== '' ){
      // TODO SAVE SETTINGS ON FIREBASE
        if ( this.user ){
          this.$store.dispatch("SAVE_USER_API_SETTINGS", {
            user: this.$store.state.user,
            settings: {
              clientID: this.clientID,
              clientSecret: this.clientSecret,
              hasSettings: true
            }
          });
        }else{
          this.loadingButton = true;
          const settingsObj = {
            clientID: this.clientID,
            clientSecret: this.clientSecret,
            hasSettings: true
          }
          localStorage.setItem('userSettings', JSON.stringify(settingsObj));
          this.loadingButton = false;
          this.closeModal();
          this.showSettingsSaved = true;
          setTimeout(() => {
            this.showSettingsSaved = false;
          }, 3000)
        }
        
      }
    }
  },
  computed:{
    disableSaveSettings(){
      return this.clientID == '' || this.clientSecret == ''
    },
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess(){
      return !!this.$store.state.user.user_success;
    }
  }
};

</script>
<style scoped lang="scss">
.alertSuccess {
  background-color: black;
  color: white;
  max-width: 170px;
  top: 15px;
  left: calc(100% - 170px);
  padding: 5px;
  font-size: 14px;
}
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

