<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            v-if="!isRaMicro"
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>
          <v-img
            v-else
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>

          <!-- <p class="mb-0 headline">
            {{ $t("payPal.cancelRequest") }}
          </p> -->
          <p class="text-center headline" cols="12">
            {{ $t("plans.payments.stripeCancel") }}
          </p>
          <p class="text-center">
            {{ $t("plans.payments.stripeCancel2") }}
          </p>
          <v-btn color="primary" @click="$router.push({ path: `/profile` })">
            {{ $t("plans.payments.accept") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { isRaMicro } from "../../utils.js";
export default {
  data: () => ({
    isRaMicro: isRaMicro()
  }),
  name: "StripeCanceled",
  components: {}
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
</style>
