import Vue from "vue";
import VueI18n from "vue-i18n";
import {
  defaultLocale,
  messages,
  availableLocales
} from "../_locales/index.js";

Vue.use(VueI18n);

const navigatorLanguage = () => {
  let lang = window.navigator.language || window.navigator.userLanguage;
  return lang.slice(0, 2).toLowerCase();
};

const getLanguage = () => {
  const language =
    window.localStorage.getItem("language") ||
    navigatorLanguage() ||
    defaultLocale;
  const supported = language && availableLocales.includes(language);
  if (supported) return language;
  return defaultLocale;
};

const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: defaultLocale,
  messages
});

Vue.prototype.$locale = {
  change(language) {
    i18n.locale = language;
    window.localStorage.setItem("language", language);
  },
  current() {
    return i18n.locale;
  }
};

Vue.prototype.$t = (key, ...params) => i18n.t(key, ...params);

export default i18n;
