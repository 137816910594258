<template>
  <v-container class="containerStartPage">
    <v-dialog v-model="showResendDialog" max-width="500">
      <v-card>
        <HeaderModals
          :titleModal="$t('generics.verificationLinkSent')"
        ></HeaderModals>
        <v-card-text
          class="text-left mt-2"
          v-html="$t('generics.thank-for-registration')"
        >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="sendVerificationEmail" color="primary">{{
            $t("generics.resend-email")
          }}</v-btn>
          <v-btn @click="goToLogin" color="primary">Log in</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="d-flex align-center height-100"
      v-if="($route.query.mode = 'verifyEmail')"
    >
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <span v-if="isVerificationReady && !isSuccess">
            {{ $t("verifyemail.emailNotVerified") }}
            <span v-if="user" class="link" @click="sendVerificationEmail">
              <br />
              {{ $t("verifyemail.clickResend") }}
            </span>
          </span>
        </v-col>
      </v-row>
    </div>
    <v-row class="text-center" v-if="$route.query.mode == 'resetPassword'">
      <div class="room-selection text-left">
        <p class="mb-6" v-if="!hasError">
          {{ $t("recoverPassword.enterPassword") }}
        </p>
        <v-form v-model="valid" lazy-validation>
          <v-row class="mx-0">
            <v-col cols="12" class="px-0" v-if="!hasError">
              <v-text-field
                :label="$t('generics.password')"
                required
                solo
                dense
                hide-details
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                @focus="clearErrors"
                :rules="passRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0" v-if="!hasError">
              <v-text-field
                :label="$t('profile.changePassword.confirmPassword')"
                required
                solo
                dense
                hide-details
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="confirmPassword"
                @focus="clearErrors"
                :rules="passRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-btn
                v-if="!hasError"
                color="primary"
                class="w-100"
                :loading="loading"
                @click="resetPassword"
                :disabled="!valid || !password || password !== confirmPassword"
              >
                {{ $t("recoverPassword.resetPassword") }}
              </v-btn>
              <router-link to="/login" v-if="hasError">
                <v-btn color="primary" class="w-100" :loading="loading">
                  {{ $t("generics.go-back") }}
                </v-btn>
              </router-link>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-alert :value="hasError" type="error" class="genericAlerts">
                {{ error }}
              </v-alert>
              <v-alert :value="hasSuccess" type="success" class="genericAlerts">
                {{ $t("recoverPassword.passwordChanged") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { verifyEmail, sendEmailVerification } from "@/firebase";
import HeaderModals from "@/components/modals/commons/headerModals.vue";
export default {
  data: () => ({
    isVerificationReady: false,
    isSuccess: false,
    showResendDialog: false
  }),
  name: "VerifyEmail",
  mounted() {
    let actionCode = this.$route.query.oobCode;
    verifyEmail(actionCode)
      .then(() => {
        this.isVerificationReady = true;
        this.isSuccess = true;
        if (this.user) {
          this.$router.replace("/profile");
        } else {
          this.$router.replace("/login");
        }
      })
      .catch(error => {
        console.log(error);
        this.isVerificationReady = true;
        this.isSuccess = false;
      });
  },
  components: { HeaderModals },
  methods: {
    sendVerificationEmail() {
      this.showResendDialog = true;
      if (this.user) {
        let lang = navigator.language || navigator.userLanguage;
        sendEmailVerification({ languageCode: lang.slice(0, 2) });
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
.link {
  cursor: pointer;
}
</style>
