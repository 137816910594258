<template>
  <v-container class="containerStartPage">
    <!-- <Header /> -->
    <v-row class="text-center">
      <div class="room-selection text-left">
        <p class="mb-6" v-if="!hasError">
          {{ $t("recoverPassword.enterPassword") }}
        </p>
        <v-form v-model="valid" lazy-validation>
          <v-row class="mx-0">
            <v-col cols="12" class="px-0" v-if="!hasError">
              <v-text-field
                :label="$t('generics.password')"
                required
                solo
                dense
                hide-details
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                @focus="clearErrors"
                :rules="passRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0" v-if="!hasError">
              <v-text-field
                :label="$t('profile.changePassword.confirmPassword')"
                required
                solo
                dense
                hide-details
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="confirmPassword"
                @focus="clearErrors"
                :rules="passRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-btn
                v-if="!hasError"
                color="primary"
                class="w-100"
                :loading="loading"
                @click="resetPassword"
                :disabled="!valid || !password || password !== confirmPassword"
              >
                {{ $t("recoverPassword.resetPassword") }}
              </v-btn>
              <router-link to="/login" v-if="hasError">
                <v-btn color="primary" class="w-100" :loading="loading">
                  {{ $t("generics.go-back") }}
                </v-btn>
              </router-link>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-alert :value="hasError" type="error" class="genericAlerts">
                {{ error }}
              </v-alert>
              <v-alert :value="hasSuccess" type="success" class="genericAlerts">
                {{ $t("recoverPassword.passwordChanged") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { isMobile } from "../utils";
/* eslint-disable */
export default {
  name: "ForgotPassword",
  components: {  },
  data: () => ({
    password: '',
    confirmPassword: '',
    mode: '',
    actionCode: '',
    isMobile: isMobile(),
    passRules: [v => !!v || "Password is required"],
    valid: false,
    showPassword: false
  }),
  mounted() {
    this.clearErrors()
    const url_string = window.location.href
    const url = new URL(url_string);
    this.mode = url.searchParams.get('mode');
    this.actionCode = url.searchParams.get('oobCode');
    this.$store.dispatch("CHECK_ACTION_CODE", {
      code: this.actionCode
    });
      
  },
  methods: {
    clearErrors(){
      if (this.error) {
        this.$store.commit("USER_CLEAR_REQUEST");
      }
    },
    resetPassword() {
      this.clearErrors();
      this.$store.dispatch("USER_CHANGE_PASSWORD", {
        code: this.actionCode,
        password: this.password
      });
    },
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.$router.replace("/");
      }
    },
  },
  computed:{
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess(){
      return !!this.$store.state.user.user_success;
    }
  }
};

</script>

<style scoped lang="scss">
.w-100{
  width: 100%;
}
.room-selection {
  margin: 0 auto 0 auto; 
  padding: 1em 1.5em 1.3em 1.5em;
  max-width: 400px;
  position: relative;
  top: 5em;
}
</style>
