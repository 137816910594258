<template>
  <v-dialog
    v-model="showRequestedPayment"
    persistent
    max-width="600px"
    attach=".call"
  >
    <v-card>
      <HeaderModals
        :titleModal="$t('paymentModals.requestPayment')"
      ></HeaderModals>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" v-if="isRaMicro" class="text-left whiteColor">
              {{ $t("paymentModals.topRequestedModal") }}
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="getProduct"
                dense
                outlined
                hide-details
                readonly
                :tabindex="-1"
                :label="
                  !isRaMicro
                    ? $t('paymentModals.product')
                    : $t('paymentModals.product-ra')
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="getProductPrice"
                dense
                outlined
                readonly
                hide-details
                :tabindex="-1"
                :label="
                  !isRaMicro
                    ? $t('paymentModals.price')
                    : $t('paymentModals.price-ra')
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="text-left whiteColor" v-if="getTaxesCheckbox">
                {{
                  !isRaMicro
                    ? $t("paymentModals.checkbox-easy")
                    : $t("paymentModals.checkbox-ra")
                }}
              </p>
              <v-text-field
                v-model="payerName"
                dense
                outlined
                hide-details
                :label="$t('paymentModals.cardHolder')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div id="card-element" />
            </v-col>
            <v-col cols="12" class="text-left whiteColor" v-if="isRaMicro">
              {{ $t("paymentModals.bottomRequestedModal") }}
            </v-col>
            <v-col cols="12" v-if="showErrorMessage" style="color:white">
              {{ errorMessage }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loadingPay"
          @click="submitStripePayment"
        >
          {{ $t("paymentModals.pay") }}
        </v-btn>
        <v-btn color="primary" @click="closeModal">
          {{ $t("paymentModals.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { isRaMicro } from "@/utils";
import HeaderModals from "./commons/headerModals.vue";
export default {
  name: "settingsModal",
  components:{ HeaderModals },
  props: ["showRequestedPayment", "closeModal", "cancelFunction"],
  data: () => ({
    stripe: null,
    card: null,
    payerName: "",
    showErrorMessage: false,
    errorMessage: "",
    isRaMicro: isRaMicro(),
    loadingPay: false
  }),
  mounted(){
    if (!this.isRaMicro) {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBKEY);
    }
    else {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBKEY_RA);
    }
    const style = {
      base: {
        color: "#cccccc",
        fontSize: '16px',
        fontFamily: 'Muli, sans-serif',
        fontWeight: '100',
        '::placeholder': {
          color: '#cccccc',
          fontWeight: '100',
        },
      }
    }
    this.card = this.stripe.elements().create("card", { style: style })
    this.card.mount("#card-element");
  },
  computed:{
    getProduct(){
      return this.showRequestedPayment ? this.showRequestedPayment.productName : '';
    },
    getProductPrice(){
      return this.showRequestedPayment ? "€ " + Number(this.showRequestedPayment.productPrice).toFixed(2) : '';
    },
    getClientSecret(){
      return this.showRequestedPayment ? this.showRequestedPayment.clientSecret : '';
    },
    getTaxesCheckbox(){
      return this.showRequestedPayment ? this.showRequestedPayment.taxesCheckbox : false;
    }
  },
  methods:{
    submitStripePayment() {
      this.loadingPay = true;
      this.stripe.confirmCardPayment(this.getClientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.payerName
          }
        }
        }).then((result) => {
          this.loadingPay = false;

          if (result.error) {
            this.errorMessage = result.error.message
            this.showErrorMessage = true
            setTimeout(() => {
              this.showErrorMessage = false
            }, 5000);
          } 
          else if (result.paymentIntent.status === 'succeeded') {
            this.$emit('payment-success', {paymentIntent: result.paymentIntent, price: this.getProductPrice})
          }
        })
    }
  },
};

</script>
<style scoped lang="scss">
.StripeElement {
    padding-top: 0.55rem;
    padding-bottom: 0.5rem;
    border: 1px solid #cccccc;
    padding-left: 12px;
    border-radius: 4px;
    padding-right: 15px;
    color: white;
}

.StripeElement--focus {
    border-color: #1976d2;
    outline:0;
    // box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.whiteColor{
  color: white;
}

.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">

.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>
