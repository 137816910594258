<template>
  <v-dialog
    v-model="showPaymentLinkInProgress"
    persistent
    max-width="600px"
    attach=".call"
  >
    <v-card>
      <HeaderModals :titleModal="$t('callComponent.payment')"></HeaderModals>
      <v-col cols="12" class="pt-0" v-if="!isPaymentSuccess">
        <v-card-text class="text-left">
          <p>{{ $t("paymentModals.paymentInProgress") }}</p>
          <v-text-field
            class="mt-7"
            v-model="link"
            readonly
            dense
            outlined
            hide-details
            :label="'Link'"
            id="inputLink"
          >
            <template v-slot:append>
              <font-awesome-icon
                v-if="!isMobile"
                @click="copyPayLink()"
                :icon="['fal', 'copy']"
                :style="{
                  fontSize: 18,
                  color: iconColor,
                  position: 'relative',
                  top: 3,
                  cursor: 'pointer'
                }"
              />
              <font-awesome-icon
                v-else
                @click="shareLinkOnMobile(link)"
                :icon="['far', 'share']"
                :style="{
                  fontSize: 18,
                  position: 'relative',
                  top: 3,
                  color: iconColor,
                  cursor: 'pointer'
                }"
              />
            </template>
          </v-text-field>
        </v-card-text>
      </v-col>
      <v-col cols="12" class="pt-0" v-if="isPaymentSuccess">
        <v-card-text class="text-left">
          <p>{{ $t("paymentModals.paymentSuccessPayLink") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="unlockSubroom">
            {{ $t("paymentModals.close") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
import { getFirebaseToken } from "../../firebase";
import { isMobile, shareLinkOnMobile, copyLink } from "../../utils";
export default {
  name: "settingsModal",
  components: { HeaderModals },
  props: ["showPaymentLinkInProgress", "link", "paymentIntentId", "closeModalPaymentLinkInProgress"],
  data: () => ({
    isMobile: isMobile(),
    shareLinkOnMobile: shareLinkOnMobile,
    copyLink: copyLink,
    iconColor: "white",
    isPaymentSuccess: false,
  }),
  methods:{
    copyPayLink(){
      var selBox2 = document.getElementById('inputLink')
      selBox2.focus();
      selBox2.select();
      document.execCommand("copy");
      selBox2.blur();
      this.iconColor = 'green';
      setTimeout(() => {
              this.iconColor = 'white';
      }, 2000);
    },
    pollForInvoice() {
      // Poll database every 15s for invoice
      let pollId = setInterval(() => {
        getFirebaseToken().then((token) => {
          return fetch(
            `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/checkIfMerchantInvoicePaid`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + (token),
                "Content-Type": "application/json;charset=UTF-8"
              },
              body: JSON.stringify({
                paymentIntentId: this.paymentIntentId,
              })
            })
        }).then(response => response.json())
        .then(response => { 
          if (response.success) {
            this.isPaymentSuccess = response.paid
            console.log("isPaymentSuccess", this.isPaymentSuccess)
            if (response.paid) {

              clearInterval(pollId);
            }
          } else {
            console.log("error in checkIfMerchantInvoicePaid")
          }
        })
      }, 10000);

      // Stops polling after 5min
      setTimeout(() => {
        clearInterval(pollId);
      }, 300000);
    },
    async unlockSubroom() {
      let savedRooms = this.$store.state.user.user.savedRooms.slice() || [];
      console.log('Saved rooms !', savedRooms)
      let subRoomId = this.$route.params.subRoom;
      let roomId = this.$route.params.roomid;
      let index = savedRooms.findIndex(room => {
        if ((room.name === roomId) || (room.name === subRoomId)) {
          return true;
        }
      });
      if (index != -1) {
        savedRooms[index].locked = false;
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.$route.params.roomid,
            online: true,
            subroom: savedRooms
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  savedRooms: savedRooms
                }
              });
              this.$store.state.user.user.savedRooms = savedRooms;
            }
          })
          .catch(error => {
            console.warn("Error on saveSubrooms", error);
          });
      }
      if ( this.closeModalPaymentLinkInProgress ){
          this.closeModalPaymentLinkInProgress();
      }
    }
  },
  computed:{},
  mounted() {
    this.pollForInvoice()
  }
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

