<template>
  <div :style="{ position: 'fixed', bottom: '10px', padding: '0 20px' }">
    <v-btn
      color="primary"
      fab
      large
      dark
      bottom
      left
      class="v-btn--beacon"
      :style="{ fontSize: 15, color: '#333' }"
    >
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Hello beacon"
    }
  }
};
</script>
