<template>
  <div class="home">
    <!-- <Header v-if="!loading"/> -->
    <StartPage v-if="!loading" msg="Welcome to easymeet.me :)" />
  </div>
</template>

<script>
// @ is an alias to /src
import StartPage from "@/components/startPage.vue";

export default {
  name: "home",
  components: {
    StartPage
  },
  computed: {
    loading() {
      return !!this.$store.state.user.loading;
    }
  }
};
</script>
<style scoped lang="scss">
// @media only screen and (max-width: 768px) {
//   .home {
//     background-size: 80% auto !important;
//   }
// }
// @media only screen and (max-width: 414px) {
//   .home {
//     background-size: 100% auto !important;
//   }
// }
.home {
  background-color: #fff;
  word-break: break-word;
  height: 100%;
  margin: 0;
  padding: 0;
  // background: url(../assets/bg4.png);
  // background-size: 60% auto;
  // background-repeat: no-repeat;
  // background-position: bottom 10px right 4%;
}
</style>
