<template>
  <v-dialog v-model="showModalChangePassword" persistent max-width="600px">
    <v-card v-if="!showPasswordChangeSuccessMessage" class="pb-3 pt-2">
      <v-card-title>{{ $t("profile.personalInfo.password") }}</v-card-title>
      <v-card-text style="text-align: left;">
        {{ $t("profile.changePassword.passwordSubtitle") }}
        <v-row class="mt-0">
          <v-col cols="12">
            <v-text-field
              v-on:keyup.enter="setPassword"
              tabindex="1"
              dense
              outlined
              :label="$t('profile.changePassword.oldPassword')"
              :hint="$t('profile.changePassword.oldPasswordHint')"
              required
              :rules="[isOldPasswordValid]"
              :value="oldPassword"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @input="_ => (oldPassword = _)"
              autocomplete="false"
            >
              ></v-text-field
            >
            <v-text-field
              v-on:keyup.enter="setPassword"
              tabindex="2"
              dense
              outlined
              :label="$t('profile.changePassword.newPassword')"
              :hint="$t('profile.changePassword.newPasswordHint')"
              required
              :rules="[isNewPasswordValid]"
              :value="newPassword"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @input="_ => (newPassword = _)"
              autocomplete="false"
            >
              ></v-text-field
            >
            <v-text-field
              v-on:keyup.enter="setPassword"
              tabindex="3"
              dense
              outlined
              :label="$t('profile.changePassword.confirmPassword')"
              :hint="$t('profile.changePassword.confirmPasswordHint')"
              required
              :rules="[isConfirmPasswordValid]"
              :value="confirmPassword"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @input="_ => (confirmPassword = _)"
              autocomplete="false"
            >
              ></v-text-field
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2"
          depressed
          color="primary"
          @click="
            closeModal();
            resetFields();
          "
        >
          {{ $t("paymentModals.close") }}
        </v-btn>
        <v-btn class="mx-2" depressed color="primary" @click="setPassword">
          {{ $t("profile.changePassword.changePassword") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="showPasswordChangeSuccessMessage" class="pb-3 pt-2">
      <v-card-title>{{ $t("profile.personalInfo.password") }}</v-card-title>
      <v-card-text style="text-align: left;">
        {{ $t("profile.changePassword.passwordChangeSuccess") }}
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2"
          depressed
          color="primary"
          @click="
            closeModal();
            resetFields();
          "
        >
          {{ $t("paymentModals.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getUser, reauthenticateUser } from "@/firebase";
export default {
  name: "ChangePasswordModal",
  props: ["showModalChangePassword", "closeModal"],
  components: {},
  data: () => ({
    showPassword: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    isOldPasswordValid: true,
    isNewPasswordValid: true,
    isConfirmPasswordValid: true,
    isFormValid: false,
    showPasswordChangeSuccessMessage: false
  }),
  mounted() {},
  methods: {
    async setPassword() {
      this.validateForm();
      if (this.isFormValid) {
        reauthenticateUser(this.oldPassword)
          .then(() => {
            getUser()
              .updatePassword(this.newPassword)
              .then(() => {
                this.showPasswordChangeSuccessMessage = true;
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            if (error.code === "auth/wrong-password") {
              this.isOldPasswordValid = this.$t(
                "profile.changePassword.oldPasswordIncorrect"
              );
            }
          });
      }
    },
    resetFields() {
      this.showPassword = false;
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.isOldPasswordValid = true;
      this.isNewPasswordValid = true;
      this.isConfirmPasswordValid = true;
      this.isFormValid = false;
    },
    validateForm() {
      this.isOldPasswordValid = true;
      this.isNewPasswordValid = true;
      this.isConfirmPasswordValid = true;
      if (this.oldPassword.length < 1) {
        this.isOldPasswordValid = this.$t(
          "profile.changePassword.oldPasswordInvalid"
        );
      }
      if (this.newPassword.length < 8) {
        this.isNewPasswordValid = this.$t(
          "profile.changePassword.newPasswordInvalid"
        );
      }
      if (this.newPassword != this.confirmPassword) {
        this.isConfirmPasswordValid = this.$t(
          "profile.changePassword.confirmPasswordInvalid"
        );
      }

      if (
        this.isOldPasswordValid === true &&
        this.isNewPasswordValid === true &&
        this.isConfirmPasswordValid === true
      ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    }
  }
};
</script>
