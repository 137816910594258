<template>
  <div class="cookies">
    <!-- <Header /> -->
    <div class="bg__wrapper">
      <div class="bg__items bg__item-1"></div>
      <div class="bg__items bg__item-2">
        <div id="container">
          <h1>Cookie Policy</h1>
          <section>
            <h2>Cookies</h2>
            <p>
              When you visit this website, your computer may receive one or more
              "cookies" in order to help personalise and support your use of
              this website, our services, and/or the services of our partners. A
              "cookie" is a small text file that is transferred to your
              computerís web browser by a web server, and can be read by a web
              server in the same domain at a later time. If you do not wish to
              receive cookies or wish to manage when you accept cookies in
              general, you may set your browser to reject cookies or to alert
              you when a cookie is placed on your computer. We provide more
              information on this in the "Your Choices" section, below. Although
              you are not required to accept our cookies, if you set your
              browser to reject cookies, you may not be able to use all of the
              features and functionalities of this website.
            </p>
            <p>
              Cookies allow a website to customise information that is presented
              to you through your use of this website (such as to display
              personalised content and targeted advertising to you on your
              future visits to this website), and to save time by storing
              information so you do not need to re-enter it during a later use
              of our website. We may place cookies on this site directly, or on
              behalf of the partner whose advertisement appears on this website
              or its service providers. In general, cookies do not identify you
              personally. However if you have created a user identity on one of
              your visits to this website, we may link the cookie to information
              that identifies you personally. We may use a combination of
              "session cookies" and "persistent cookies" on this website,
              further described below.
            </p>
            <h3>Session Cookies.</h3>
            <p>
              A "session cookie" is a temporary cookie that expires when you
              close your browser. A session cookie assigns a randomly-generated,
              unique identification number to your computer when you access this
              website. Assigning your computer a number facilitates the proper
              functioning of the features of our websites by permitting us to
              maintain a persistent "state" for your session, such as
              maintaining the contents of your shopping cart during checkout. We
              also use session cookies to collect anonymous information (i.e.,
              information that does not identify you personally) about the ways
              visitors use this website ñ which pages they visit, which links
              they use, and how long they stay on each page. We analyse this
              information (known as "click-stream information") to better
              understand our visitorsí interests and needs and to improve the
              content and functionality of this website.
            </p>
            <p>
              Persistent Cookies. Unlike a session cookie, a "persistent cookie"
              does not expire when you close your browser. It stays on your
              computer until the expiration date set in the cookie (for example,
              at the end of a calendar month) or until you delete it. Persistent
              cookies can be used to "tag" your computer so that the next time
              you visit (or someone using your computer visits), our server will
              recognise you, not by name, but by the "tag" on your computer.
              This will enable us to provide you with a personalised experience
              even if we do not know who you are. It will also allow us to
              collect more accurate information about the ways people use this
              website, for example, how people use this website on their first
              visit and how often they return. Using persistent cookies permits
              us to provide you with a more personalised shopping experience
              and, in some cases, may save you the trouble of re-entering
              information already in our database.
            </p>
            <h3>Web Beacons</h3>
            <p>
              When you visit this website, your computer may receive one or more
              "web beacons" in order to assist us with delivering cookies on
              this website, to collect anonymous information about the use of
              this website by our visitors, and to deliver customised or
              targeted content to you on this website and through our partners.
              Web beacons (also referred to as "tracking pixels," "1&#215;1
              gifs," "single-pixel gifs," "pixel tags," or "action tags") are
              graphic images, usually no larger than a 1&#215;1 pixel, placed at
              various locations on this website. They also help us identify
              browser types, search terms that bring visitors to our website,
              and the domain names of websites that refer traffic to us. We may
              place web beacons on this site directly, and may place web beacons
              on behalf of the partner whose branding appears on this website or
              its service providers. They also help us identify your IP address.
              The information collected by web beacons does not identify you
              personally. However, if you have created a user identity on one of
              your visits to this website, for example, by making a purchase, we
              may link the information we collect using web beacons to the
              information that identifies you personally and use it in the same
              ways as we use the information we collect using cookies. We also
              use web beacons to provide us with more information on any emails
              we send out. In particular, a web beacon in an email communication
              will send us information to let us know that you have received,
              opened, or acted upon an email you have chosen to receive from us.
            </p>
            <p>
              If you accept a session or persistent cookie, you can delete it at
              any time through your web browser (e.g., as soon as you leave our
              website). If you do not wish to receive cookies or wish to manage
              when you accept cookies in general, you may set your browser to
              reject cookies or to alert you when a cookie is placed on your
              computer. Although you are not required to accept our cookies, if
              you set your browser to reject cookies, you may not be able to use
              all of the features and functionalities of this website.
            </p>
            <p>
              We and our partners use third party service providers, which may
              include but are not limited to Google Analytics, to serve ads on
              our behalf across the Internet, and sometimes on this website.
              These ads may collect anonymous information about your visits to
              the website on which such ad is placed, your interaction with
              these ads and the products and services offered by us, our
              partners and our suppliers through the use of a pixel tag or other
              web technologies. We and our partners may use this anonymous
              information to provide targeted advertisements to you for goods
              and services.
            </p>
            <p>
              If you opt out of having your information collected through
              cookies, web beacons and other tools, your existing display
              advertising cookie(s) will be deleted and new cookie will attempt
              to be placed that instructs service providers not to track your
              future activities when that cookie is detected (a "no-track"
              cookie). If your browsers are configured to reject cookies when
              you visit our opt-out page, a "no-track" cookie cannot be set on
              your computer. Also, if you subsequently erase "no-track" cookies,
              use a different computer or change web browsers, you will need to
              opt-out again.
            </p>
          </section>
        </div>
      </div>
      <div class="bg__items bg__item-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cookies",
  components: {},
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
</style>
