<template>
  <div>
    <v-dialog v-model="showDeleteContact" persistent max-width="600px">
      <v-card>
        <HeaderModals
          :titleModal="$t('profile.addressBook.deleteContact')"
        ></HeaderModals>
        <v-card-text class="text-left">
          {{ $t("profile.addressBook.areYouSure") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeModalDeleteContact">
            {{ $t("paymentModals.cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="loading" @click="deleteContact">
            {{ $t("generics.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import HeaderModals from './commons/headerModals.vue';
export default {
  components: {HeaderModals},
  name: "addContactModal",
  props: ["showDeleteContact", "closeModalDeleteContact", "deleteContact", "loading"],
  data: () => ({}),
  methods:{},
  computed:{
    user(){
      return this.$store.state.user.user
    },
  }
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
.copiedAlert{
  position: absolute;
  top: 15px;
  right: 36px;
  margin: 0;
  width: auto;
  z-index: 999;
}
</style>

<style lang="scss">
</style>
