<template>
  <v-container fluid class="containerStartPage mt-16">
    <template>
      <v-row
        class="mt-10 mb-10"
        v-for="(block, i) in blockText"
        :class="block.imageOnLeft ? 'colReverse' : ''"
        :key="i"
      >
        <v-col cols="12" sm="6" class="orderXS">
          <div class="d-flex align-center height-30">
            <div class="textContainer">
              <h2 class="text-justify pb-4">{{ block.title }}</h2>
              <p class="text-justify" v-html="block.text"></p>
              <router-link
                style="float: left;"
                :to="block.link"
                v-if="block.hasLink"
                v-html="$t('startPage.linkToPlans')"
              >
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="height-30 mb-10"
          :class="`backCol${i + 1}`"
        ></v-col>
      </v-row>
      <!-- <Footer /> -->
    </template>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cookies",
  components: {},
  mounted() {
    this.toTop();
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    }
  },
  computed: {
    blockText() {
      return [
        {
          title: this.$t("about-us.block1.title"),
          text: this.$t("about-us.block1.text"),
          imageOnLeft: false,
          hasLink: true,
          link: "/plans"
        },
        {
          title: this.$t("about-us.block2.title"),
          text: this.$t("about-us.block2.text"),
          imageOnLeft: true,
          hasLink: false,
          link: ""
        },
        {
          title: this.$t("about-us.block3.title"),
          text: this.$t("about-us.block3.text"),
          imageOnLeft: false,
          hasLink: false,
          link: ""
        },
        {
          title: this.$t("about-us.block4.title"),
          text: this.$t("about-us.block4.text"),
          imageOnLeft: true,
          hasLink: false,
          link: ""
        },
        {
          title: this.$t("about-us.block5.title"),
          text: this.$t("about-us.block5.text"),
          imageOnLeft: false,
          hasLink: false,
          link: ""
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
</style>

<style scoped lang="scss">
.colReverse {
  display: flex !important;
  flex-flow: wrap;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 700px) {
  .headlineTitle {
    padding-top: 10px;
    font-size: 2.2rem !important;
    line-height: 3rem !important;
  }

  .orderXS {
    order: 13 !important;
  }
}
.textContainer {
  font-family: Muli, sans-serif;
  margin: 0 auto;
  width: 30vw;
  min-width: 300px;
}
.height-30 {
  min-height: 40vh;
}

.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
.backWhite {
  background-color: white;
}
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.w-100 {
  width: 100%;
}
.room-selection {
  margin: 0 auto 0 auto;
  padding: 1em 1.5em 1.3em 1.5em;
  //max-width: 700px;
  position: relative;
  top: 7em;
}
.h-divider {
  margin: auto;
  width: 100%;
  position: relative;
  .shadow {
    overflow: hidden;
    height: 0px;
    border-top: 1px solid;
  }

  .text {
    width: 25%;
    height: auto;
    padding: 2px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    left: 0;
    margin-left: 0;
    background: white;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: -13px;
    font-family: Muli, sans-serif;
  }
}
.backCol1 {
  background: url(../../assets/easy-paid.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.backCol2 {
  background: url(../../assets/meeting-mobile.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.backCol3 {
  background: url(../../assets/wait-to-room.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.backCol4 {
  background: url(../../assets/one-to-one.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.backCol5 {
  background: url(../../assets/web-integration.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
