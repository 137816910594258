<template>
  <div class="plans align-start align-sm-center mt-xs-16">
    <!-- <Header /> -->
    <div
      class="w100xs room-selection flex-column align-start align-sm-center pt-xs-0 pt-sm-16 mt-md-0"
    >
      <v-row class="w100xs" align="center">
        <div class="price__table-wrap flex-column flex-sm-row px-4">
          <v-col class="mx-sm-2 xs-12 md-4 mb-4 price__table-item">
            <ul class="pricing p-green">
              <li class="mb-0">
                <p class="mb-0 mt-7 titlePlan">
                  {{ $t("plans.starter.title") }}
                </p>
                <p class="body-2 mb-0">{{ $t("plans.starter.subtitle") }}</p>
              </li>
              <li class="px-0 text-center mb-5">
                <div class="price__table-price">
                  <h3>
                    <span class="spanCurrency">€</span>
                    {{ $t("plans.starter.price") }}
                    <br />
                    <span class="perMonth">{{ $t("plans.per-month") }}</span>
                  </h3>
                </div>
              </li>
              <li>{{ $t("plans.starter.feature1") }}</li>
              <li>{{ $t("plans.starter.feature2") }}</li>
              <li>{{ $t("plans.starter.feature3") }}</li>
              <!-- <li>{{ $t("plans.starter.feature4") }}</li> -->
            </ul>

            <div class="price__table-btn">
              <v-btn
                class="btnPlans font-weight-bold"
                outlined
                @click="startMeeting"
                >{{ $t("startnow") }}</v-btn
              >
            </div>
          </v-col>

          <v-col
            class="mx-sm-2 xs-12 mb-4 md-4 ml-md-4 mr-md-4 price__table-item pRelative"
          >
            <p class="pMostPopular">{{ $t("plans.most-popular") }}</p>
            <ul class="pricing p-green">
              <li class="mb-0">
                <p class="mb-0 mt-7 titlePlan">{{ $t("plans.pro.title") }}</p>
                <p class="body-2 mb-0">{{ $t("plans.pro.subtitle") }}</p>
              </li>
              <li class="px-0 text-center mb-5">
                <div class="price__table-price">
                  <h3>
                    <span class="spanCurrency">€</span>
                    {{ $t("plans.pro.price") }}
                    <br />
                    <span class="perMonth">{{ $t("plans.per-month") }}</span>
                  </h3>
                </div>
              </li>
              <li>{{ $t("plans.pro.feature1") }}</li>
              <li>{{ $t("plans.pro.feature2") }}</li>
              <li>{{ $t("plans.pro.feature3") }}</li>
              <li>{{ $t("plans.pro.feature4") }}</li>
              <li>{{ $t("plans.pro.feature5") }}</li>
              <li>{{ $t("plans.pro.feature6") }}</li>
              <li>{{ $t("plans.pro.feature7") }}</li>
              <li>{{ $t("plans.pro.feature8") }}</li>
              <li>{{ $t("plans.pro.feature9") }}</li>

              <!-- <li v-if="!isRaMicro">
                {{ $t("profile.addressBook.addressbook") }}
              </li> -->
            </ul>

            <div class="price__table-btn">
              <v-btn
                :disabled="subscriptionId >= 1"
                class="btnPlans font-weight-bold"
                outlined
                :loading="requestingSubs"
                @click="goToSubscriptions()"
                >{{
                  subscriptionId === 1
                    ? $t("plans.your-plan")
                    : $t("plans.subscribe")
                }}</v-btn
              >
            </div>
          </v-col>

          <v-col class="mx-sm-2 xs-12 mb-4 md-4 price__table-item">
            <ul class="pricing p-green">
              <li class="mb-0">
                <p class="mb-0 mt-7 titlePlan">
                  {{ $t("plans.enterprise.title") }}
                </p>
                <p class="body-2 mb-0">{{ $t("plans.enterprise.subtitle") }}</p>
              </li>
              <li class="px-0 text-center mb-5">
                <div class="price__table-price">
                  <h3>
                    <span
                      class="perMonth"
                      v-html="$t('plans.enterprise.price')"
                    ></span>
                  </h3>
                </div>
              </li>
              <li>{{ $t("plans.enterprise.feature1") }}</li>
              <li>{{ $t("plans.enterprise.feature2") }}</li>
              <li>{{ $t("plans.enterprise.feature3") }}</li>
              <li>{{ $t("plans.enterprise.feature4") }}</li>
              <li>{{ $t("plans.enterprise.feature5") }}</li>
              <!--<li>{{ $t("plans.enterprise.feature6") }}</li>
               <li>{{ $t("plans.enterprise.feature7") }}</li>
              <li>{{ $t("plans.enterprise.feature8") }}</li> -->
              <!-- <li v-if="!isRaMicro">
                {{ $t("profile.addressBook.addressbook") }}
              </li> -->
            </ul>
            <div class="price__table-btn">
              <v-btn
                class="btnPlans font-weight-bold"
                outlined
                :loading="requestingSubs"
                @click="openEnterpriseRequestModal"
                >{{
                  subscriptionId === 2
                    ? $t("plans.your-plan")
                    : $t("plans.request")
                }}</v-btn
              >
            </div>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert :value="showInfoAlert" type="info" class="genericAlerts">{{
            $t("plans.login-alert")
          }}</v-alert>
          <v-alert
            :value="showErrorSubscription"
            type="error"
            class="genericAlerts"
          >
            {{ $t("profile.subscriptions.subscriptionError") }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <template v-if="showModalStripeOrPaypal">
      <StripeOrPaypalModal
        :showModalStripeOrPaypal="showModalStripeOrPaypal"
        :closeModal="closeStripeOrPaypalModal"
        :subscribeWithPaypal="subscribeWithPaypal"
        :subscribeWithStripe="subscribeWithStripe"
      />
    </template>
    <template v-if="showModalEnterpriseRequest">
      <EnterpriseRequestModal
        :showModalEnterpriseRequest="showModalEnterpriseRequest"
        :closeModal="closeEnterpriseRequestModal"
      />
    </template>
  </div>
</template>
<script>
import { getFirebaseClaims, getFirebaseToken } from "../firebase";
// document.getFirebaseClaims = getFirebaseClaims;
import StripeOrPaypalModal from "./modals/stripeOrPayPalModal.vue";
import EnterpriseRequestModal from "./modals/enterpriseRequestModal.vue";
import { randomString, isRaMicro } from "../utils.js";
import FingerprintJS from "fingerprintjs";

export default {
  name: "Plans",
  components: { StripeOrPaypalModal, EnterpriseRequestModal },
  data: () => ({
    loading: false,
    showInfoAlert: false,
    showErrorSubscription: false,
    hasSubscription: false,
    subscriptionId: 0,
    requestingSubs: false,
    showModalStripeOrPaypal: false,
    showModalEnterpriseRequest: false,
    chosenPlan: null,
    isRaMicro: isRaMicro()
  }),
  created() {
    this.loading = true;
    getFirebaseClaims(true)
      .then(claims => {
        this.hasSubscription = !!(claims || {}).hasSub;
        this.subscriptionId = +(claims || {}).subId;
        this.loading = false;
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
        this.loading = false;
      });
  },
  methods: {
    async startMeeting() {
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            let fingerPrint = new FingerprintJS().get();
            let ts = Date.now();
            this.$router.push({
              path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}`
            });
            this.showCallComponent = true;
          } else {
            // Try again with a different room
            this.startMeeting();
          }
        });
    },
    goToSubscriptions() {
      if (this.$store.state.user.user) {
        this.$router.push({ path: "profile", params: { goToSubscribe: true } });
      } else {
        this.$router.push({ path: "login" });
      }
    },
    checkSubscription(plan) {
      // Unused by the moment we're redirecting to /subscriptions.TODO: we need to add TubePay, CoinBase and CryptoCurrency checkout to the modal
      this.chosenPlan = plan;
      if (this.hasSubscription && this.subscriptionId) {
        if (this.subscriptionId == 1) {
          this.subscribeWithPaypal();
        } else {
          this.subscribeWithStripe();
        }
      } else {
        this.openStripeOrPaypalModal();
      }
    },
    openStripeOrPaypalModal() {
      this.showModalStripeOrPaypal = true;
    },
    closeStripeOrPaypalModal() {
      this.showModalStripeOrPaypal = false;
    },
    openEnterpriseRequestModal() {
      this.showModalEnterpriseRequest = true;
    },
    closeEnterpriseRequestModal() {
      this.showModalEnterpriseRequest = false;
    },
    async subscribeWithPaypal() {
      const plan = this.chosenPlan;
      try {
        if (!this.$store.state.user.user) {
          this.showInfoAlert = true;
          setTimeout(() => {
            this.showInfoAlert = false;
          }, 5000);
        } else {
          // LOCAL: http://localhost:5001/sclipcom/us-central1
          this.requestingSubs = true;
          let planId;
          if (plan === "advanced") {
            planId = process.env.VUE_APP_ADV_PLAN_ID;
          } else if (plan === "pro") {
            planId = process.env.VUE_APP_PRO_PLAN_ID;
          }
          fetch(
            `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/${
              this.hasSubscription ? "reviseSubscription" : "paySubscription"
            }`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + (await getFirebaseToken()),
                "Content-Type": "application/json;charset=UTF-8"
              },
              body: JSON.stringify({
                plan_id: planId,
                returnBaseUrl: window.location.origin,
                isRaMicro: this.isRaMicro
              })
            }
          )
            .then(response => response.json())
            .then(response => {
              if (response.success && response.approval_url) {
                // window.open(response.approval_url);
                this.requestingSubs = false;
                window.location.href = response.approval_url;
              } else if (response.error) {
                this.requestingSubs = false;
                this.showErrorSubscription = true;
                setTimeout(() => {
                  this.showErrorSubscription = false;
                }, 5000);
              }
            })
            .catch(error => {
              console.warn("Error on subscription", error);
              this.showErrorSubscription = true;
              setTimeout(() => {
                this.showErrorSubscription = false;
              }, 5000);
            });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async subscribeWithStripe() {
      const plan = this.chosenPlan;
      try {
        if (!this.$store.state.user.user) {
          this.showInfoAlert = true;
          setTimeout(() => {
            this.showInfoAlert = false;
          }, 5000);
        } else {
          let planId = "";
          if (this.isRaMicro) {
            if (plan === "pro") {
              planId = process.env.VUE_APP_STRIPE_PRO_PLAN_ID_RA;
            } else if (plan === "advanced") {
              planId = process.env.VUE_APP_STRIPE_ADV_PLAN_ID_RA;
            }
          } else {
            if (plan === "pro") {
              planId = process.env.VUE_APP_STRIPE_PRO_PLAN_ID;
            } else if (plan === "advanced") {
              planId = process.env.VUE_APP_STRIPE_ADV_PLAN_ID;
            }
          }
          let endPoint;
          if (planId === "") {
            endPoint = "cancelStripeSubscription";
          } else if ((this.claims || {}).hasSub) {
            endPoint = "reviseStripeSubscription";
          } else {
            endPoint = "startStripeSubscription";
          }
          fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/${endPoint}`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              plan_id: planId,
              isRaMicro: this.isRaMicro,
              returnBaseUrl: window.location.origin
            })
          })
            .then(response => response.json())
            .then(response => {
              if (response.url) {
                window.location.assign(response.url);
              } else {
                console.log(response.message);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  computed: {
    cols() {
      const { lg, sm, md, xs } = this.$vuetify.breakpoint;
      return lg
        ? [4, 4, 4]
        : sm
        ? [12, 12, 12]
        : md
        ? [4, 4, 4]
        : xs
        ? [12, 12, 12]
        : [4, 4, 4];
    }
  }
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 700px) {
  .room-selection {
    margin-top: 85px;
  }
  .w100xs {
    width: 100%;
  }
  .pricing {
    li {
      text-align: center !important;
    }
  }
}
.pRelative {
  position: relative;
}
.pMostPopular {
  background-color: #37b748;
  color: white;
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 5px 7px;
}
.plans {
  display: flex;
  justify-content: center;
}
.v-application .align-center {
  align-items: unset !important;
  margin: 0;
}
.room-selection {
  position: relative;
  display: flex;
  justify-content: center;
  .row {
    flex: unset;
  }
  .pricing {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    padding-left: 0;
    margin-bottom: 10px;
    flex-grow: 1;
    li {
      list-style: none;
      text-align: left;
      padding-left: 1em;
      padding-right: 1em;
      line-height: 1.4;
      font-size: 17px;
      padding-bottom: 1em;
    }
    li:first-child {
      padding: 20px 13px;
      text-align: center;
      background-color: #1976d2;
      margin-bottom: 1em;
    }
    .titlePlan {
      font-size: 32px;
    }
  }
  .p-green big {
    color: white;
  }

  .perMonth {
    font-size: 25px;
  }
}
.plans {
  background-color: #fff;
  word-break: break-word;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.price__table {
  &-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &-item {
    margin-top: auto;
    padding: 0;
    border: 1px solid #1976d2;
    background: #1976d2;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &-price {
    background: #1565b4;
    padding: 15px;
    & h3 {
      color: #ffffff;
      margin-bottom: 0;
      font-size: 36px;
      line-height: 1.1;
    }
  }
  &-btn {
    margin: 1em;
    .btnPlans {
      border: none;
      color: white;
    }
  }
}
</style>
