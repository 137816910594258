import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Imprint from "../components/pages/imprint.vue";
import Terms from "../components/pages/terms.vue";
import StripeTerms from "../components/pages/stripeTerms.vue";
import Privacy from "../components/pages/privacy.vue";
import Cookies from "../components/pages/cookies.vue";
import Help from "../components/pages/help.vue";
import Call from "../components/call.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import RecoveryPassword from "@/views/RecoveryPassword.vue";
import PaypalCancel from "@/views/PaypalCancel.vue";
import Paypal from "@/views/Paypal.vue";
import ManageProfile from "@/views/ManageProfile.vue";
import Plans from "../components/plans.vue";
import About from "../components/pages/about.vue";
import LandingPage from "../components/landingPage.vue";
import StartCall from "../components/startCall.vue";
import StripeSuccess from "../components/pages/stripeSuccess.vue";
import StripeCanceled from "../components/pages/stripeCanceled.vue";
import StripeConnectReturn from "../components/pages/stripeConnectReturn.vue";
import CryptoCanceled from "../components/pages/cryptoCanceled.vue";
import CryptoReturn from "../components/pages/cryptoReturn.vue";
import VerifyEmail from "../components/pages/verifyEmail.vue";
import VueTelInput from "vue-tel-input";

Vue.use(VueRouter);
// Install vue-tel-input
Vue.use(VueTelInput);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "easymeet - Home",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/stripe_success",
    name: "stripe_success",
    component: StripeSuccess,
    meta: {
      title: "easymeet - Payment Success",
      metaTags: [
        {
          name: "description",
          content: "Successful payment at stripe platform."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/stripe_canceled",
    name: "stripe_canceled",
    component: StripeCanceled,
    meta: {
      title: "easymeet - Payment Canceled",
      metaTags: [
        {
          name: "description",
          content: "Payment at stripe platform canceled by user."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/stripe_connect_return",
    name: "stripe_connect_return",
    component: StripeConnectReturn,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Successful link payments at stripe platform."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/crypto-cancelled",
    name: "crypto_canceled",
    component: CryptoCanceled,
    meta: {
      title: "easymeet - Payment Canceled",
      metaTags: [
        {
          name: "description",
          content: "Payment at stripe platform canceled by user."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/crypto-success",
    name: "crypto_return",
    component: CryptoReturn,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Successful link payments at stripe platform."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/verifyemail",
    name: "verifyemail",
    component: VerifyEmail,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Successful link payments at stripe platform."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/resetpassword",
    name: "Recovery pass",
    component: RecoveryPassword,
    meta: {
      title: "easymeet - Home",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/c/:roomid",
    name: "call",
    component: Call,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/c/:roomid/:subRoom",
    name: "call_subroom",
    component: Call,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/startCall",
    name: "start_call",
    component: StartCall,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },

  {
    path: "/r/:mainRoom",
    name: "room",
    component: LandingPage,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/r/:mainRoom/:subRoom",
    name: "room_subroom",
    component: LandingPage,
    meta: {
      title: "easymeet",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/imprint",
    name: "imprint",
    component: Imprint,
    meta: {
      title: "easymeet - Imprint",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      title: "easymeet - Terms",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/stripe",
    name: "stripe-terms",
    component: StripeTerms,
    meta: {
      title: "easymeet - Stripe Terms and Conditions",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: {
      title: "easymeet - Privacy",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
    meta: {
      title: "easymeet - Cookies",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      title: "easymeet - Help",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "easymeet - Login",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "easymeet - Register",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {
      title: "easymeet - ForgotPassword",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/plans",
    name: "Plans",
    component: Plans,
    meta: {
      title: "easymeet - Plans",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/paypal",
    name: "Paypal",
    component: Paypal,
    meta: {
      title: "easymeet - Paypal",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: PaypalCancel,
    meta: {
      title: "easymeet - Cancel subscription",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: ManageProfile,
    meta: {
      requiresAuth: true,
      title: "easymeet - Profile",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "easymeet - About",
      metaTags: [
        {
          name: "description",
          content: "Simply payments in a video call."
        },
        {
          property: "og:description",
          content: "Simply payments in a video call."
        }
      ]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
