<template>
  <v-dialog v-model="showModalSecurity" persistent max-width="600px">
    <v-card id="modalSecurity">
      <HeaderModals :titleModal="'2FA'"></HeaderModals>
      <v-col cols="12" class="text-left">
        {{ $t("2FA.enterYour2FA") }}
      </v-col>
      <v-col cols="12" class="pt-0 text-left">
        <v-text-field
          v-model="pin"
          autofocus
          dense
          outlined
          hide-details
          @focus="clearErrors"
          v-on:keyup.enter="login2FA()"
          :label="$t('2FA.2FAcode')"
        ></v-text-field>
        <p class="mb-0 errorText" v-if="showError">{{ errorMessage }}</p>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="login2FA()"
          :disabled="pin == ''"
          :loading="sendingCode"
        >
          {{ $t("callComponent.accept") }}
        </v-btn>
        <v-btn color="primary" @click="closeModalSecurity(false)">
          {{ $t("callComponent.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
import { getFirebaseToken } from "../../firebase";
import Vue from "vue";
export default {
  components: { HeaderModals },
  name: "settingsModal",
  props: ["showModalSecurity", "closeModalSecurity", "continueToProfile"],
  data: () => ({
      pin: '',
      sendingCode: false,
      showError: false,
      errorMessage: '',
  }),
  methods:{
      clearErrors(){
        this.showError = false;
        this.errorMessage = '';
      },
      async login2FA(){
          this.sendingCode = true;
          fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/verifySecret`, {
            method: "POST",
            headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
            action: 'login2fa',
            userToken: this.pin
            })
        })
        .then(response => response.json())
        .then(response => {
          if ( response.success ){
            
            this.pin = '';
            const user = this.user;
            // this.$store.dispatch("USER_2FA_SUCCESS", { user });
            this.continueToProfile(true);
            localStorage.setItem('continue_to_page', Date.now())
          }else if ( response.error && response.message == "INCORRECT_CODE" ){
            this.showError = true;
            this.errorMessage = Vue.prototype.$t("2FA.invalidCode");
          }else if ( response.error && response.message !== "INCORRECT_CODE"){
              this.showError = true;
            this.errorMessage = Vue.prototype.$t("2FA.somethingWentWrong");
          }
        this.sendingCode = false;
          
        });
      }
  },
  computed:{
      user(){
          return this.$store.state.user.user;
      }
  }
};

</script>
<style scoped lang="scss">
.errorText{
    color: red;
}
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

