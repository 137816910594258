var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px","attach":".call"},model:{value:(_vm.showPaymentLinkInProgress),callback:function ($$v) {_vm.showPaymentLinkInProgress=$$v},expression:"showPaymentLinkInProgress"}},[_c('v-card',[_c('HeaderModals',{attrs:{"titleModal":_vm.$t('callComponent.payment')}}),(!_vm.isPaymentSuccess)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"text-left"},[_c('p',[_vm._v(_vm._s(_vm.$t("paymentModals.paymentInProgress")))]),_c('v-text-field',{staticClass:"mt-7",attrs:{"readonly":"","dense":"","outlined":"","hide-details":"","label":'Link',"id":"inputLink"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.isMobile)?_c('font-awesome-icon',{style:({
                fontSize: 18,
                color: _vm.iconColor,
                position: 'relative',
                top: 3,
                cursor: 'pointer'
              }),attrs:{"icon":['fal', 'copy']},on:{"click":function($event){return _vm.copyPayLink()}}}):_c('font-awesome-icon',{style:({
                fontSize: 18,
                position: 'relative',
                top: 3,
                color: _vm.iconColor,
                cursor: 'pointer'
              }),attrs:{"icon":['far', 'share']},on:{"click":function($event){return _vm.shareLinkOnMobile(_vm.link)}}})]},proxy:true}],null,false,4075444915),model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1):_vm._e(),(_vm.isPaymentSuccess)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"text-left"},[_c('p',[_vm._v(_vm._s(_vm.$t("paymentModals.paymentSuccessPayLink")))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.unlockSubroom}},[_vm._v(" "+_vm._s(_vm.$t("paymentModals.close"))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }