import {
  signInUser,
  signupUser,
  signOutUser,
  addUser,
  fetchUser,
  signInWithSocial,
  sendPaswordReset,
  changePassword,
  checkActionCode,
  saveUserAPISettings,
  saveUserTransactions,
  saveUserInfo,
  fetchUserTransactions,
  fetchUserSubscriptions,
  getStripeInvoice,
  getStripeSubscription
} from "@/firebase";

const state = {
  user: null,
  user_request: null,
  user_success: null,
  user_error: null,
  user_error_code: null,
  loading: true,
  transactions: null,
  subscriptions: null,
  stripe_invoice: null,
  stripe_subscription: null,
  fingerprint: null
};

const getters = {};

const mutations = {
  USER_UPDATE_SUBSCRIPTIONS(state, { subscriptions }) {
    state.loading = false;
    state.subscriptions = subscriptions;
  },
  USER_UPDATE_TRANSACTIONS(state, { transactions }) {
    state.loading = false;
    state.transactions = transactions;
  },
  USER_UPDATE_STRIPE_SUBSCRIPTIONS(state, { subscriptions }) {
    state.loading = false;
    state.stripe_subscription = subscriptions;
  },
  USER_UPDATE_STRIPE_INVOICES(state, { invoice }) {
    state.loading = false;
    state.stripe_invoice = invoice;
  },
  USER_UPDATE(state, { user }) {
    state.loading = false;
    state.user = user;
  },
  USER_CLEAR(state) {
    state.user = null;
    state.transactions = null;
    state.subscriptions = null;
    state.stripe_invoice = null;
    state.stripe_subscription = null;
    localStorage.removeItem("continue_to_page");
  },
  USER_REQUEST(state) {
    state.user_request = true;
    state.user_success = null;
    state.user_error = null;
    state.user_error_code = null;
  },
  USER_SUCCESS(state) {
    state.user_request = null;
    state.user_success = true;
    state.user_error = null;
    state.user_error_code = null;
  },
  USER_ERROR(state, { message, code }) {
    state.user_request = null;
    state.user_success = null;
    state.user_error = message;
    state.user_error_code = code;
  },
  USER_CLEAR_REQUEST(state) {
    state.user_request = null;
    state.user_success = null;
    state.user_error = null;
    state.user_error_code = null;
  },
  USER_LOADING(state, payload) {
    state.loading = payload;
  },
  USER_FINGERPRINT(state, payload) {
    state.fingerPrint = payload;
  }
};

const actions = {
  SAVE_USER_TRANSACTIONS({ commit, dispatch }, payload) {
    commit("USER_REQUEST");
    const saveUserTransactionsPromise = saveUserTransactions(payload);
    saveUserTransactionsPromise
      .then(user => {
        dispatch("USER_FETCH", user);
        dispatch("USER_TRANSACTIONS_FETCH", user);
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },
  SAVE_USER_API_SETTINGS({ commit, dispatch }, payload) {
    commit("USER_REQUEST");
    const saveUserAPISettingsPromise = saveUserAPISettings(payload);
    saveUserAPISettingsPromise
      .then(user => {
        dispatch("USER_FETCH", user);
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },
  SAVE_USER_INFO({ commit, dispatch }, payload) {
    const saveUserInfoPromise = saveUserInfo(payload);
    saveUserInfoPromise
      .then(user => {
        dispatch("USER_FETCH", user);
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },
  CHECK_ACTION_CODE({ commit }, payload) {
    commit("USER_REQUEST");
    const checkActionCodePromise = checkActionCode(payload);
    checkActionCodePromise
      .then(() => {
        commit("USER_CLEAR_REQUEST");
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },
  USER_CHANGE_PASSWORD({ commit }, payload) {
    commit("USER_REQUEST");
    const changePasswordPromise = changePassword(payload);
    changePasswordPromise
      .then(() => {
        commit("USER_SUCCESS");
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },
  USER_FORGOT_PASSWORD({ commit }, payload) {
    commit("USER_REQUEST");
    const forgotPasswordPromise = sendPaswordReset(payload);
    forgotPasswordPromise
      .then(() => {
        commit("USER_SUCCESS");
      })
      .catch(() => {
        commit("USER_SUCCESS");
        // commit("USER_ERROR", { message: error.message });
      });
  },
  USER_SIGNIN({ commit, dispatch }, payload) {
    commit("USER_REQUEST");
    const loginPromise = signInUser(payload);
    loginPromise
      .then(user => {
        dispatch("USER_FETCH", user);
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_SIGNIN_SOCIAL({ commit, dispatch }, { provider, ref }) {
    commit("USER_REQUEST");
    const loginPromise = signInWithSocial({ provider });
    loginPromise
      .then(user => {
        user.provider = provider;
        const addUserPromise = addUser(user, ref);
        addUserPromise
          .then(() => {
            // TODO: Check if user exist before create?
            // Could be nice to always add (merge) so we fetch user changes from social login
            dispatch("USER_FETCH", user);
          })
          .catch(error => {
            commit("USER_ERROR", { message: error.message, code: error.code });
          });
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_SIGNUP({ commit, dispatch }, { email, password, ref }) {
    commit("USER_REQUEST");
    const createdUserPromise = signupUser({ email, password });
    createdUserPromise
      .then(user => {
        // Store user to datbase
        user.provider = "email";
        const addUserPromise = addUser(user, ref);
        addUserPromise
          .then(() => {
            dispatch("USER_FETCH", user);
          })
          .catch(error => {
            commit("USER_ERROR", { message: error.message, code: error.code });
          });
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_FETCH({ commit }, { user }) {
    commit("USER_REQUEST");
    const fetchedUserPromise = fetchUser(user);
    fetchedUserPromise
      .then(user => {
        commit("USER_UPDATE", { user });
        commit("USER_SUCCESS");
        commit("USER_LOADING", false);
      })
      .catch(error => {
        commit("USER_LOADING", false);
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_TRANSACTIONS_FETCH({ commit }, { user }) {
    commit("USER_REQUEST");
    const fetchUserTransactionsPromise = fetchUserTransactions(user);
    fetchUserTransactionsPromise
      .then(transactions => {
        commit("USER_UPDATE_TRANSACTIONS", { transactions });
        commit("USER_SUCCESS");
        commit("USER_LOADING", false);
      })
      .catch(error => {
        commit("USER_LOADING", false);
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_SUBSCRIPTIONS_FETCH({ commit }, { user }) {
    commit("USER_REQUEST");
    const fetUserSubscriptionsPromise = fetchUserSubscriptions(user);
    fetUserSubscriptionsPromise
      .then(subscriptions => {
        commit("USER_UPDATE_SUBSCRIPTIONS", { subscriptions });
        commit("USER_SUCCESS");
        commit("USER_LOADING", false);
      })
      .catch(error => {
        commit("USER_LOADING", false);
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_STRIPE_INVOICE_FETCH({ commit }, { user, isRaMicro }) {
    commit("USER_REQUEST");
    const fetchUserInvoicePromise = getStripeInvoice(user, isRaMicro);
    fetchUserInvoicePromise
      .then(invoice => {
        commit("USER_UPDATE_STRIPE_INVOICES", { invoice });
        commit("USER_SUCCESS");
        commit("USER_LOADING", false);
      })
      .catch(error => {
        commit("USER_LOADING", false);
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_STRIPE_SUBSCRIPTIONS_FETCH({ commit }, { user, isRaMicro }) {
    commit("USER_REQUEST");
    const fetchUserSubscriptionsPromise = getStripeSubscription(
      user,
      isRaMicro
    );
    fetchUserSubscriptionsPromise
      .then(subscriptions => {
        commit("USER_UPDATE_STRIPE_SUBSCRIPTIONS", { subscriptions });
        commit("USER_SUCCESS");
        commit("USER_LOADING", false);
      })
      .catch(error => {
        commit("USER_LOADING", false);
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_SIGNOUT({ commit }) {
    const signOutPromise = signOutUser();
    signOutPromise
      .then(() => {
        commit("USER_CLEAR");
      })
      .catch(error => {
        commit("USER_ERROR", { message: error.message, code: error.code });
      });
  },

  USER_SET_FINGERPRINT({ commit }, { fingerPrint }) {
    commit("USER_FINGERPRINT", fingerPrint);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
