import {
  faBluetoothB as fabBluetoothB,
  faFacebook as fabFacebook,
  faGithub as fabGithub,
  faGoogle as fabGoogle,
  faTwitter as fabTwitter,
  faWhatsapp as fabWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressBook as falAddressBook,
  faAddressCard as falAddressCard,
  faCopy as falCopy,
  faHandsUsd as falHandsUsd,
  faHistory as falHistory,
  faHome as falHome,
  faMoneyCheckEditAlt as falMoneyCheckEditAlt,
  faSitemap as falSitemap,
  faTrash as falTrash,
  faUserFriends as falUserFriends,
  faStars as falStars,
  faArrowLeft as falArrowLeft
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronRight as farChevronRight,
  faCopy as farCopy,
  faEnvelope as farEnvelope,
  faEuroSign as farEuroSign,
  faLock as farLock,
  faMoneyBill as farMoneyBill,
  faPencil as farPencil,
  faShare as farShare,
  faSms as farSms,
  faSync as farSync,
  faTimes as farTimes,
  faUserLock as farUserLock,
  faUserUnlock as farUserUnlock
} from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown as fasChevronDown,
  faDesktop as fasDesktop,
  faExpandWide as fasExpandWide,
  faHandHoldingUsd as fasHandHoldingUsd,
  faHeadset as fasHeadset,
  faMicrophone as fasMicrophone,
  faMicrophoneSlash as fasMicrophoneSlash,
  faMobile as fasMobile,
  faMobileAlt as fasMobileAlt,
  faPhone as fasPhone,
  faPhoneAlt as fasPhoneAlt,
  faSlash as fasSlash,
  faVideo as fasVideo,
  faVideoSlash as fasVideoSlash,
  faStickyNote as fasStickyNote
} from "@fortawesome/pro-solid-svg-icons";
export default [
  fabBluetoothB,
  fabFacebook,
  fabGithub,
  fabGoogle,
  fabTwitter,
  fabWhatsapp,
  falAddressBook,
  falAddressCard,
  falCopy,
  falHandsUsd,
  falHistory,
  falHome,
  falMoneyCheckEditAlt,
  falSitemap,
  falTrash,
  falStars,
  falUserFriends,
  falArrowLeft,
  farChevronRight,
  farCopy,
  farEnvelope,
  farEuroSign,
  farLock,
  farMoneyBill,
  farPencil,
  farShare,
  farSms,
  farSync,
  farTimes,
  farUserLock,
  farUserUnlock,
  fasChevronDown,
  fasDesktop,
  fasExpandWide,
  fasHandHoldingUsd,
  fasHeadset,
  fasMicrophone,
  fasMicrophoneSlash,
  fasMobile,
  fasMobileAlt,
  fasPhone,
  fasPhoneAlt,
  fasSlash,
  fasVideo,
  fasVideoSlash,
  fasStickyNote
];
