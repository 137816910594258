/* eslint-disable */
import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n.js";
import FontsActuallyUsed from "./fontawesome.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core"
Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(...FontsActuallyUsed); // Include needed icons
Vue.use(Vuetify);
const opts = {
  icons: {
    iconFont: 'fa'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#4995ff',
      },
      dark: {
        primary: '#4995ff'
      }
    }
  }
};
export default new Vuetify({opts});
