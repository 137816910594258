<template>
  <v-dialog v-model="pinModal" persistent max-width="600px" attach=".call">
    <v-card>
      <HeaderModals :titleModal="$t('callComponent.setPass')"></HeaderModals>
      <v-col cols="12" class="text-left">
        {{ $t("callComponent.setPassword") }}
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="pin"
          dense
          outlined
          hide-details
          :label="$t('generics.password')"
        ></v-text-field>
      </v-col>
      <v-card-actions v-if="roomProtected && isMobile">
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="unlockRoom()">
          {{ $t("callComponent.unlockRoom") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="pin == ''" @click="setPin(pin)">
          {{ $t("callComponent.setPass") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="unlockRoom()"
          v-if="roomProtected && !isMobile"
        >
          {{ $t("callComponent.unlockRoom") }}
        </v-btn>
        <v-btn color="primary" @click="closePinModal">
          {{ $t("callComponent.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import HeaderModals from "./commons/headerModals.vue";
import { isMobile } from "../../utils";
export default {
  components: { HeaderModals },
  name: "settingsModal",
  props: ["pinModal", "closePinModal", "setPin", "roomProtected", "unlockRoom"],
  data: () => ({
      pin: '',
      isMobile: isMobile()
  }),
  methods:{},
  computed:{}
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

