// Also see src/i18n.js

import {
  de as deVuetify,
  en as enVuetify,
  es as esVuetify,
  ru as ruVuetify
  // it as itVuetify,
  // fr as frVuetify,
  // nl as nlVuetify,
  // ua as uaVuetify,
  // zh as zhVuetify,
  // pt as ptVuetify,
  // pl as plVuetify,
  // tr as trVuetify
} from "vuetify/es5/locale";

import de from "./de/strings.json";
import en from "./en/strings.json";
import es from "./es/strings.json";
import ru from "./ru/strings.json";
// import it from "./it/strings.json";
// import fr from "./fr/strings.json";
// import nl from "./nl/strings.json";
// import ua from "./ua/strings.json";
// import zh from "./zh/strings.json";
// import pt from "./pt/strings.json";
// import pl from "./pl/strings.json";
// import tr from "./tr/strings.json";

export const defaultLocale = "en";

export const messages = {
  de: { $vuetify: { ...deVuetify }, ...de },
  en: { $vuetify: { ...enVuetify }, ...en },
  es: { $vuetify: { ...esVuetify }, ...es },
  ru: { $vuetify: { ...ruVuetify }, ...ru }
  // it: { $vuetify: { ...itVuetify }, ...it },
  // fr: { $vuetify: { ...frVuetify }, ...fr },
  // nl: { $vuetify: { ...nlVuetify }, ...nl },
  // ua: { $vuetify: { ...uaVuetify }, ...ua },
  // zh: { $vuetify: { ...zhVuetify }, ...zh },
  // pt: { $vuetify: { ...ptVuetify }, ...pt },
  // pl: { $vuetify: { ...plVuetify }, ...pl },
  // tr: { $vuetify: { ...trVuetify }, ...tr }
};

export const availableLocales = Object.keys(messages);
