<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            v-if="!isRaMicro"
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>
          <v-img
            v-else
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>

          <span v-if="isLoading">
            <p class="text-center headline" cols="12">
              {{ $t("payPal.processing") }}
            </p>
          </span>
          <span v-else>
            <p
              v-if="provider === 'coinbase'"
              class="text-center headline"
              cols="12"
            >
              {{ $t("plans.payments.cryptoReturnCoinbase") }}
            </p>
            <p
              v-else-if="provider === 'cccheckout'"
              class="text-center headline"
              cols="12"
            >
              {{ $t("plans.payments.cryptoReturnCCCheckout") }}
            </p>

            <p v-else class="text-center headline" cols="12">
              {{ $t("plans.payments.cryptoReturn") }}
            </p>
            <v-btn color="primary" @click="$router.push({ path: `/profile` })">
              {{ $t("plans.payments.accept") }}
            </v-btn>
          </span>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { getFirebaseToken } from "@/firebase";

import { isRaMicro } from "../../utils.js";
export default {
  data: () => ({
    isRaMicro: isRaMicro(),
    isLoading: true,
    provider: null
  }),
  name: "CryptoReturn",
  components: {},
  mounted() {
    if (this.$route.query) {
      this.provider = this.$route.query.p;
    }
    if (this.provider === "coinbase" || this.provider === "cccheckout") {
      this.isLoading = false;
    }
    this.requestPromise = getFirebaseToken().then(token =>
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/recordUserFirstSubscriptionStat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    );
  },
  methods: {},
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/global.scss";
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
</style>
