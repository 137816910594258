import { render, staticRenderFns } from "./call.vue?vue&type=template&id=877e4a82&scoped=true&"
import script from "./call.vue?vue&type=script&lang=js&"
export * from "./call.vue?vue&type=script&lang=js&"
import style0 from "./call.vue?vue&type=style&index=0&id=877e4a82&scoped=true&lang=scss&"
import style1 from "./call.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "877e4a82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAvatar,VBtn,VImg,VList,VListItem,VListItemTitle,VMenu,VProgressLinear,VTooltip})
