<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-progress-linear
      fixed
      v-if="isProcessingSubscription"
      color="primary accent-4"
      indeterminate
      rounded
      height="5"
      style="top: 0px; z-index: 999;"
    ></v-progress-linear>
    <v-row class="rowPills">
      <v-col cols="12" class="pb-5">
        <h1 class="pb-2">
          {{ $t("profile.subscriptions.subscriptionsTitle") }}
        </h1>
        <p>
          {{ $t("profile.subscriptions.subscriptionsSubtitle") }}
        </p>
      </v-col>

      <v-col cols="12" class="paymentDivs text-left pa-6 mb-6">
        <h2 class="pb-1 title">
          {{ $t("profile.subscriptions.mainTitle") }}
        </h2>
        <p class="subTitlePaymentSection">
          <span
            v-if="isStarter || this.subscriptionPlatform == 'tubePay'"
            v-html="
              $t('profile.subscriptions.block-1-text-free', [
                getCurrentPlanName()
              ])
            "
          ></span>
          <span
            v-if="!isStarter && this.subscriptionPlatform != 'tubePay'"
            v-html="
              $t('profile.subscriptions.block-1-text', [getCurrentPlanName()])
            "
          ></span>
          <br v-if="!isStarter && this.subscriptionPlatform != 'tubePay'" />
          <span
            v-if="!isStarter && this.subscriptionPlatform != 'tubePay'"
            v-html="
              $t('profile.subscriptions.block-1-text2', [
                next_billing,
                `${claims.subId == 1 ? '5' : '10'} EUR`
              ])
            "
          ></span>
        </p>

        <h2 class="pb-1 pt-3 title">
          {{ $t("profile.subscriptions.changeYourPlan") }}
        </h2>
        <div class="d-flex mt-2 text-left">
          <v-select
            :items="getItems"
            :label="$t('profile.subscriptions.changeYourPlan')"
            dense
            outlined
            item-text="name"
            item-value="value"
            hide-details
            v-model="selectedPlan"
            v-mutate="enableApplyButton"
          >
          </v-select>
        </div>

        <!-- CANCEL SUBSCRIPTION -->
        <!-- Only for paypal && stripe as the other platforms are single payments and not subscriptions -->
        <div
          class="pb-1 pt-3"
          @click="confirmCancelSubscription()"
          v-if="
            selectedPlan == 4 &&
              (subscriptionPlatform == 'paypal' ||
                subscriptionPlatform == 'stripe')
          "
        >
          <v-btn class="cancelButtonColor">{{
            $t("profile.subscriptions.confirmCancelSubscriptionTitle")
          }}</v-btn>
        </div>
        <div
          class="pb-1 pt-3"
          v-if="
            selectedPlan == 4 &&
              subscriptionPlatform != 'paypal' &&
              subscriptionPlatform != 'stripe'
          "
        >
          <span>{{ $t("profile.subscriptions.noDowngrade") }}</span>
        </div>

        <!-- PLAN INFO -->
        <h2 class="pb-1 pt-3 title" v-if="!isStarter">
          {{ $t("profile.subscriptions.accountStatus") }}
        </h2>
        <p class="subTitlePaymentSection mb-3" v-if="!isStarter">
          <span
            v-if="billingEmail !== '' && subscriptionPlatform == 'paypal'"
            v-html="
              $t('profile.subscriptions.accountStatusTextPaypal', [
                billingEmail
              ])
            "
          ></span>
          <span
            v-if="billingEmail !== '' && subscriptionPlatform == 'stripe'"
            v-html="
              $t('profile.subscriptions.accountStatusTextStripe', [
                billingEmail
              ])
            "
          ></span>
          <span
            v-if="subscriptionPlatform == 'CoinBase'"
            v-html="
              $t('profile.subscriptions.accountStatusTextTubePay', ['CoinBase'])
            "
          ></span>
          <span
            v-if="subscriptionPlatform == 'tubePay'"
            v-html="
              $t('profile.subscriptions.accountStatusTextTubePay', ['TubePay'])
            "
          ></span>
          <br />
          <span
            v-if="startSubscriptionTS !== null"
            v-html="
              $t('profile.subscriptions.accountRemainingTime', [
                this.next_billing
              ])
            "
          ></span>
        </p>
        <div
          class="paymentPlatforms"
          v-if="!isApplyButtonDisabled && selectedPlan != 4"
        >
          <h2 class="pb-1 pt-3 title">
            {{ $t("plans.payments.choosePaymentPlatform") }}
          </h2>
          <div class="paymentRow">
            <!-- PAYPAL -->
            <div
              class="paymentLogoRow"
              v-if="showPayPalButton"
              @click="subscribeWithPaypal()"
            >
              <v-img
                class="paymentLogo logoPayPal"
                src="img/PP_Color_Primary.png"
              />
            </div>
            <!-- STRIPE -->
            <div
              class="paymentLogoRow"
              v-if="showStripeButton"
              @click="subscribeWithStripe()"
            >
              <v-img
                class="paymentLogo logoStripe"
                src="img/StripeLogo.svg.png"
              />
            </div>
            <!-- TUBECASH -->
            <div class="paymentLogoRow tubeLogoRow" v-if="showCoinbaseButton">
              <!-- <v-img class="paymentLogoTube" src="img/BitTube_tubepay-01.svg" /> -->
              <div
                :data-tubepay-product="tubePayProductId"
                data-tubepay-type="easymeet-product"
                class="button-tubePay paymentLogo paymentLogoTube"
              ></div>
            </div>
            <!-- COINBASE -->
            <div
              class="paymentLogoRow"
              v-if="showCoinbaseButton"
              @click="subscribeWithCoinBase()"
            >
              <v-img class="paymentLogoCoinbase" src="img/CoinbaseLogo.svg" />
            </div>
            <!-- CRYPTOCURRENCYCHECKOUT -->
            <div class="paymentLogoRow CCLogoRow" v-if="showCCCheckoutButton">
              <form
                method="POST"
                action="https://cryptocurrencycheckout.com/validation"
              >
                <input
                  type="hidden"
                  name="CC_TUBE_ADDRESS"
                  value="Tubed9H1EgGUtHGhWoj83D5BiBUa1bihGd5i8Mgfx3PaaNb5p9QbvNf7zL1qf35VPXBoKxM4EMpy3ZkfEXnjnFYg15wxwqk3kHL"
                />
                <input type="hidden" name="CC_STORE_NAME" value="easymeet.me" />
                <input
                  type="hidden"
                  name="CC_STORE_ID"
                  value="151522f0-4c70-11ec-b21d-ffbd9ffa0371"
                />
                <input type="hidden" name="CC_CONNECTION_ID" value="1413" />
                <input type="hidden" name="CC_ORDER_ID" value="proplan" />
                <input type="hidden" name="CC_GRANDTOTAL" value="5" />
                <input
                  type="hidden"
                  name="CC_API_TOKEN"
                  value="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNjc3MGIwZDVjMjc4YzYyNzQ4N2U0OThjNWFjMDQ2ZGFiZTQyYzg3ZjVjZWY0YjEzNDYwNTZkNWIxMTY1ZGY3NjE5MjJlZmMyMDMyNmM1MDYiLCJpYXQiOjE2Mzc2OTUyMDAsIm5iZiI6MTYzNzY5NTIwMCwiZXhwIjoxNzMyMzg5NjAwLCJzdWIiOiIxNTE1MjJmMC00YzcwLTExZWMtYjIxZC1mZmJkOWZmYTAzNzEiLCJzY29wZXMiOltdfQ.Yqnp9I4ZDHwyPcStb1yKqbpOgQzDwC63mTJCK00e6ruSGGr4pB38onXq5P9l7e43V9hniGzPW4l5Kar5k-y3pL0HAgvjDcAUpPigWGu84Yx8kZAfOL9BmPZ07JhJdsh6B2WEMHddDMtJ5INOXJQONoU5yCeYscjR0aAm9SS7Ld7_-dOAPpcgA9PJbU7g_GGEgmTKcE9Kk8keA29Wd1nglBS8B7SuXyHQk7XOvMACWPZ50texENLqFZKiLmbL2hSCZZxyBMyAg53uMvJ_NMS2W3PojcpXI1J5jfSpSgA1172fksldVjfJBlmbwI6kHDfIZJ99zMjEOtW9oFi-hsoJQQ-qPQa4QdnxHJg5PlL2xksSRXAWgDFS6rROcxvDp5oP9db-FebP8_WUbZLZUaiiPkKEwgOcrR6u1dqt-pgixBfwXEKUg3Yexm5GIdBaLmGAoOvheMZzjaON1o15MnJWTDiK_HHepN1d8jwEtdZQfKifamTYAWRqLegNgqOOHGdxq3S7k6rphzGWYIKg6SEDbNMD9yQ0Emba2pFSdjp33WgGEBPBeppMYP90X6pP5BY0AEneiVzoyO7PKvcN3dPDx1pgjPkse1PyWqLo4KVHWi5isnNhQQm1ZccFkUNgtEOIgIxk6Jf9XwLFX5LILFlLsoWcQFoRqmw7Y4yfAhFE5Jg"
                />
                <input
                  type="image"
                  class="paymentLogoCCurrency"
                  src="img/CClogo.png"
                  border="0"
                  alt="Submit"
                />
              </form>
            </div>
          </div>
        </div>
        <v-alert :value="showInfoAlert" type="info" class="genericAlerts">{{
          $t("plans.login-alert")
        }}</v-alert>
        <v-alert
          :value="showErrorSubscription"
          type="error"
          class="genericAlerts"
        >
          {{ $t("profile.subscriptions.subscriptionError") }}
        </v-alert>
        <v-alert
          :value="showSuccessCancellation"
          type="success"
          class="genericAlerts"
        >
          {{ $t("profile.subscriptions.subscriptionCancelled") }}
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        class="paymentDivs text-left pa-6 pRelative links"
        :key="dataLoaded"
      >
        <h2 class="pb-1 title">
          {{ $t("profile.subscriptions.billing") }}
        </h2>
        <v-data-table
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :search="search"
          :headers="headers"
          :loading="isLoadingTransactions"
          :loading-text="$t('plans.payments.loading')"
          :items="transactionArray"
          :footer-props="{
            'items-per-page-text': footerText,
            'items-per-page-all-text': allText
          }"
          @click:row="handleTableClick"
          :header-props="{
            'sort-by-text': sortByText
          }"
        >
          <template #item.date="{ item }">
            {{ getTransactionDate(item.date) }}
          </template>
          <template #item.platform="{ item }">
            <v-img
              v-if="item.platform == 'paypal'"
              class="paymentLogo logoBilling"
              src="img/PP_Color_Primary.png"
            />
            <v-img
              v-if="item.platform == 'stripe'"
              class="paymentLogo logoBilling"
              src="img/StripeLogo.svg.png"
            />
            <v-img
              v-if="item.platform == 'TubePay'"
              class="paymentLogo logoBilling"
              src="img/BitTube_tubepay-01.svg"
            />
            <v-img
              v-if="item.platform == 'CoinBase'"
              class="paymentLogo logoBilling"
              src="img/CoinbaseLogo.svg"
            />
          </template>
          <template slot="no-data">
            {{ $t("profile.subscriptions.no-data") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showCancelSubscriptionDialog" max-width="500">
      <v-card>
        <HeaderModals
          :titleModal="
            $t('profile.subscriptions.confirmCancelSubscriptionTitle')
          "
        ></HeaderModals>
        <v-card-text
          class="text-left mt-2"
          v-html="$t('profile.subscriptions.confirmCancelSubscriptionBody')"
        >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="showCancelSubscriptionDialog = false"
            color="primary"
            >{{
              $t("profile.subscriptions.confirmCancelSubscriptionButtonCancel")
            }}</v-btn
          >
          <v-btn @click="cancelSubscription()" class="cancelButtonColor">{{
            $t("profile.subscriptions.confirmCancelSubscriptionButtonOK")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import { getFirebaseClaims, getFirebaseToken } from "../../firebase";
import { isRaMicro, isMobile } from "@/utils";
import HeaderModals from "../modals/commons/headerModals.vue";
export default {
  name: "Payments",
  props: ["showSection"],
  components: { HeaderModals },
  data: () => ({
    menu: false,
    searchDate: new Date().toISOString().substr(0, 10),
    search: "",
    expanded: [],
    singleExpand: true,
    showInfoAlert: false,
    isApplyButtonDisabled: true,
    showErrorSubscription: false,
    showSuccessCancellation: false,
    isProcessingSubscription: false,
    selectedPlan: 0,
    sortBy: "date",
    sortDesc: true,
    showStripeButton: false,
    showPayPalButton: false,
    showCoinbaseButton: false,
    showCCCheckoutButton: false,
    showCancelSubscriptionDialog: false,
    isRaMicro: isRaMicro(),
    isMobile: isMobile(),
    headers: [
      {
        text: Vue.prototype.$t("profile.subscriptions.date"),
        value: "date"
      },
      {
        text: Vue.prototype.$t("profile.subscriptions.productName"),
        value: "planName"
      },
      {
        text: Vue.prototype.$t("profile.subscriptions.charges"),
        value: "amount"
      },
      {
        text: Vue.prototype.$t("plans.payments.paymentPlatform"),
        value: "platform"
      }
    ],
    claims: null,
    isStarter: true,
    subscriptions: null,
    subscriptionPlatform: null,
    transactionsPayPal: null,
    trPaypalRetries: 7,
    transactionsStripe: null,
    next_billing: null,
    billingEmail: "",
    dataLoaded: false,
    isLoadingTransactions: true,
    transactionArray: [],
    tubePayProductId: process.env.VUE_APP_TUBEPAY_PRODUCT_ID,
    startSubscriptionTS: null,
    items: [
      { name: Vue.prototype.$t("profile.subscriptions.upgrade1"), value: 1 }, // 1 upgradeToAdvanced
      { name: Vue.prototype.$t("profile.subscriptions.upgrade2"), value: 2 }, // 2 upgradeToPro
      { name: Vue.prototype.$t("profile.subscriptions.downgrade1"), value: 3 }, // 3 downgradeToPro
      { name: Vue.prototype.$t("profile.subscriptions.downgrade2"), value: 4 } //downgradeToFree
    ]
  }),
  created() {
    /*
      1. Refresh firebase claims
      2. Refresh required data in store
      3. Populate data in front-end
    */
    getFirebaseClaims(true)
      .then(claims => {
        this.claims = claims;
        this.initFetchData();
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
      });
  },
  mounted() {
    this.subscribeToPurchaseTubePayListener();
  },
  destroyed() {
    if (document.body)
      document.body.removeEventListener("PurchaseSuccess", this.updateDetails);
  },
  methods: {
    async updateDetails(paymentInfo) {
      let chosenPlanPrice;
      let chosenPlanTubePayId;
      let chosenPlanId;

      /* tubePay && security */
      const paymentConfirmed = paymentInfo.detail.res.reportResponse.success;
      const payment_tx = paymentInfo.detail.res.reportResponse.tx_res.tx_hash;
      if (paymentConfirmed) {
        const postBody = {
          planId: chosenPlanId,
          priceTube: chosenPlanPrice,
          tubePayId: chosenPlanTubePayId,
          payment_tx: payment_tx,
          certificate: paymentInfo.detail.product.certificate
        };
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reportTubePurchase`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify(postBody)
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              // console.log("ICEICE it was success:", response);
              this.recordUserSubscriptionTube(payment_tx);
              setTimeout(() => {
                this.initFetchData();
                this.checkSubscription();
                this.isStarter = false;
                this.selectedPlan = 4;
              }, 2000);
            } else {
              console.log("updateDetails error");
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.notifier.error("Something went wrong after the payment response");
      }
    },
    async recordUserSubscriptionTube() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/recordUserFirstSubscriptionStat`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      );
    },
    initFetchData() {
      const user = this.$store.state.user.user;
      if (user) {
        Promise.all([
          this.$store.dispatch("USER_TRANSACTIONS_FETCH", { user }),
          this.$store.dispatch("USER_SUBSCRIPTIONS_FETCH", { user }),
          this.$store.dispatch("USER_STRIPE_INVOICE_FETCH", {
            user,
            isRaMicro: this.isRaMicro
          }),
          this.$store.dispatch("USER_STRIPE_SUBSCRIPTIONS_FETCH", {
            user,
            isRaMicro: this.isRaMicro
          })
        ]).then(() => {
          this.updateTransactionHistory();
          this.updateSubscriptionHistory();
        });
      }
    },
    handleTableClick(valueRow) {
      if (valueRow.platform == "stripe" && valueRow.hosted_invoice_url) {
        window.open(valueRow.hosted_invoice_url, "_blank");
      }
    },
    enableApplyButton() {
      if (this.selectedPlan > 0) {
        this.isApplyButtonDisabled = false;
      } else {
        this.isApplyButtonDisabled = true;
      }
      this.checkSubscription();
    },
    confirmCancelSubscription() {
      this.showCancelSubscriptionDialog = true;
    },
    async cancelSubscription() {
      if (this.subscriptionPlatform == "tubePay") {
        console.log("Cacenling tubePay");
        this.processCancelSubscription();
      } else if (this.subscriptionPlatform == "paypal") {
        console.log("Cacenling paypal");
        this.subscribeWithPaypal(); // Plan 4 will unsubscribe
      } else if (this.subscriptionPlatform == "stripe") {
        console.log("Cacenling stripe");
        this.subscribeWithStripe(); // Plan 4 will unsubscribe
      } else {
        console.log(
          "You don't need to unsubscribe as you're using a single method payment"
        );
      }
    },
    async subscribeWithCoinBase() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/startCoinbaseSubscription`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            amount: 5.0,
            currency: "EUR",
            itemDescription: "easymeet.me Pro Plan",
            returnBaseUrl: window.location.origin
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          console.log(response.url);
          window.open(response.url, "_blank");
        })
        .catch(error => {
          console.log(error);
        });
    },
    subscribeToPurchaseTubePayListener() {
      if (document.body) {
        // console.log('ICEICE triggering subscribeToPurchaseTubePayListener')
        document.body.removeEventListener(
          "PurchaseSuccess",
          this.updateDetails
        );
        document.body.addEventListener("PurchaseSuccess", this.updateDetails);
      } else {
        setTimeout(() => {
          this.subscribeToPurchaseTubePayListener();
        }, 200);
      }
    },
    async subscribeWithPaypal() {
      this.isProcessingSubscription = true;
      sessionStorage.setItem("returnPage", "subscriptions");
      let plan;
      if (this.selectedPlan == 2 || this.selectedPlan == 3) {
        plan = "pro";
      } else if (this.selectedPlan == 1) {
        plan = "advanced";
      } else if (this.selectedPlan == 4) {
        plan = "starter";
      }
      try {
        if (!this.$store.state.user.user) {
          this.showInfoAlert = true;
          setTimeout(() => {
            this.showInfoAlert = false;
          }, 5000);
        } else {
          // LOCAL: http://localhost:5001/sclipcom/us-central1
          this.requestingSubs = true;
          let planId;
          if (plan === "starter") {
            planId = process.env.VUE_APP_STARTER_PLAN_ID;
          } else if (plan === "pro") {
            planId = process.env.VUE_APP_PRO_PLAN_ID;
          } else if (plan === "advanced") {
            planId = process.env.VUE_APP_ADV_PLAN_ID;
          }
          let endPoint;
          if (planId === process.env.VUE_APP_STARTER_PLAN_ID) {
            endPoint = "cancelSubscription";
          } else if ((this.claims || {}).hasSub) {
            endPoint = "reviseSubscription";
          } else {
            endPoint = "paySubscription";
          }
          fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/${endPoint}`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              plan_id: planId,
              returnBaseUrl: window.location.origin,
              isRaMicro: this.isRaMicro
            })
          })
            .then(response => response.json())
            .then(response => {
              if (endPoint === "cancelSubscription") {
                if (response.success) {
                  console.log(response.message);
                  this.processCancelSubscription();
                } else {
                  console.log(response.message);
                  this.isProcessingSubscription = false;
                }
              } else if (endPoint === "reviseSubscription") {
                if (response.success && response.approval_url) {
                  this.isProcessingSubscription = false;
                  this.requestingSubs = false;
                  window.location.href = response.approval_url;
                } else if (response.success) {
                  //TODO success message
                  console.log("Revised paypal subscription success.");
                  this.isProcessingSubscription = false;
                  this.requestingSubs = false;
                }
              } else if (endPoint === "paySubscription") {
                if (response.success && response.approval_url) {
                  this.isProcessingSubscription = false;
                  this.requestingSubs = false;
                  window.location.href = response.approval_url;
                } else if (response.error) {
                  this.isProcessingSubscription = false;
                  this.requestingSubs = false;
                  this.showErrorSubscription = true;
                  setTimeout(() => {
                    this.showErrorSubscription = false;
                  }, 5000);
                }
              }
            })
            .catch(error => {
              this.isProcessingSubscription = false;
              console.warn("Error on subscription", error);
              this.showErrorSubscription = true;
              setTimeout(() => {
                this.showErrorSubscription = false;
              }, 5000);
            });
        }
      } catch (err) {
        this.isProcessingSubscription = false;
        console.log(err);
      }
    },
    checkSubscription() {
      if ((this.claims || {}).hasSub) {
        if (
          (this.claims || {}).subType == null ||
          (this.claims || {}).subType == 0
        ) {
          this.showStripeButton = false;
          this.showPayPalButton = true;
          this.showCoinbaseButton = false;
          this.showCCCheckoutButton = false;
        } else if ((this.claims || {}).subType == 1) {
          this.showStripeButton = true;
          this.showPayPalButton = false;
          this.showCoinbaseButton = false;
          this.showCCCheckoutButton = false;
        } else if ((this.claims || {}).subType == 2) {
          this.showStripeButton = false;
          this.showPayPalButton = false;
          this.showCoinbaseButton = true;
          this.showCCCheckoutButton = true;
        }
      } else {
        this.showStripeButton = true;
        this.showPayPalButton = true;
        this.showCoinbaseButton = true;
        this.showCCCheckoutButton = true;
      }
    },
    async subscribeWithStripe() {
      this.isProcessingSubscription = true;
      let plan;
      if (this.selectedPlan == 2 || this.selectedPlan == 3) {
        plan = "pro";
      } else if (this.selectedPlan == 1) {
        plan = "advanced";
      } else if (this.selectedPlan == 4) {
        plan = "starter";
      }
      try {
        if (!this.$store.state.user.user) {
          this.showInfoAlert = true;
          setTimeout(() => {
            this.showInfoAlert = false;
          }, 5000);
        } else {
          let planId = "";
          if (this.isRaMicro) {
            if (plan === "pro") {
              planId = process.env.VUE_APP_STRIPE_PRO_PLAN_ID_RA;
            } else if (plan === "advanced") {
              planId = process.env.VUE_APP_STRIPE_ADV_PLAN_ID_RA;
            }
          } else {
            if (plan === "pro") {
              planId = process.env.VUE_APP_STRIPE_PRO_PLAN_ID;
            } else if (plan === "advanced") {
              planId = process.env.VUE_APP_STRIPE_ADV_PLAN_ID;
            }
          }
          let endPoint;
          if (planId === "") {
            endPoint = "cancelStripeSubscription";
          } else if ((this.claims || {}).hasSub) {
            endPoint = "reviseStripeSubscription";
          } else {
            endPoint = "startStripeSubscription";
          }
          fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/${endPoint}`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              plan_id: planId,
              isRaMicro: this.isRaMicro,
              returnBaseUrl: window.location.origin
            })
          })
            .then(response => response.json())
            .then(response => {
              // console.log("ICEICE response is: ", response);
              if (response.url) {
                window.location.assign(response.url);
              } else if (response.message) {
                console.log(response.message);
              }
              if (response.success && endPoint === "reviseStripeSubscription") {
                //TODO show success message
                console.log("plan revised success");
                this.isProcessingSubscription = false;
              } else if (
                response.success &&
                endPoint === "cancelStripeSubscription"
              ) {
                //TODO show success message
                console.log("plan cancelled success");
                this.isProcessingSubscription = false;
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      } catch (err) {
        this.isProcessingSubscription = false;
        console.log(err);
      }
    },
    async processCancelSubscription() {
      sessionStorage.removeItem("returnPage");
      this.showCancelSubscriptionDialog = false;
      getFirebaseToken()
        .then(token =>
          fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/cancelAgreement`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8"
            }
          })
        )
        .then(response => response.json())
        .then(async response => {
          this.requestLoading = false;
          if (response.success) {
            this.isProcessingSubscription = false;
            this.$store.dispatch(
              "USER_SUBSCRIPTIONS_FETCH",
              this.$store.state.user
            );
            this.claims.hasSub = 0;
            this.showSuccessCancellation = true;
            this.selectedPlan = null;
            setTimeout(() => {
              this.showSuccessCancellation = false;
            }, 5000);
          } else {
            this.showSuccessCancellation = false;
            throw new Error("cancelAgreement response not successful");
          }
        })
        .catch(error => {
          this.showSuccessCancellation = false;
          console.warn("Error on cancelAgreement", error);
        });
    },
    handlerClickShowTransactions() {
      this.showTransactions = !this.showTransactions;
    },
    getTransactionDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getCurrentPlanName() {
      if (this.claims) {
        if (!this.claims.hasSub || this.claims.hasSub == 0) {
          this.isStarter = true;
          return this.$t("plans.starter.title");
        } else if (this.claims.hasSub && this.claims.hasSub == 1) {
          this.isStarter = false;
          if (this.claims.subId == 1) {
            return this.$t("plans.pro.title");
          } else {
            return this.$t("plans.pro.title");
          }
        }
      }
    },
    getPlanById(plan_id) {
      if (
        plan_id == "P-2WM94475WJ480931BMBNQXZQ" ||
        plan_id == "P-5S077447S60331813MBNQ5PY"
      ) {
        return this.$t("plans.pro.title");
      } else if (
        plan_id == "P-4R411802E52010258MBNQYGI" ||
        plan_id == "P-9BF1313748768700PMBNQ5ZI"
      ) {
        return this.$t("plans.pro.title");
      }
    },
    getNextBilling(value) {
      const date = moment(value)
        .lang(this.$locale.current())
        .format("ll");
      this.next_billing = `${date}`;
    },
    async updateSubscriptionHistory() {
      if (this.$store.state.user.subscriptions) {
        this.subscriptions = this.$store.state.user.subscriptions;
        Object.keys(this.subscriptions).forEach(index => {
          const subscription = this.subscriptions[index];
          if (subscription.status == "ACTIVE") {
            this.getNextBilling(subscription.billing_info.next_billing_time);
            this.billingEmail = subscription.subscriber.email_address;
            this.subscriptionPlatform = "paypal";
            this.startSubscriptionTS = subscription.start_time;
          }
        });
      }
      if (
        this.$store.state.user.stripe_subscription &&
        this.$store.state.user.stripe_invoice
      ) {
        this.subscription = this.$store.state.user.stripe_subscription;
        this.transactionsStripe = this.$store.state.user.stripe_invoice;
        if (this.subscription.status == "active" && this.transactionsStripe) {
          // Get next billing
          this.billingEmail = this.transactionsStripe[
            this.transactionsStripe.length - 1
          ].customer_email;
          this.subscriptionPlatform = "stripe";
          this.startSubscriptionTS = this.subscription.start_time;
        }
      }
      return fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/updateSubscriptionHistory`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.message) {
            console.log(response.message);
          }
          this.$store.dispatch("USER_SUBSCRIPTIONS_FETCH", {
            user: this.$store.state.user.user
          });
          this.$store.dispatch("USER_STRIPE_SUBSCRIPTIONS_FETCH", {
            user: this.$store.state.user.user
          });
        })
        .then(() => {
          this.subscriptions = this.$store.state.user.subscriptions;
          const subscriptionssObj = this.subscriptions;
          if (subscriptionssObj) {
            Object.keys(subscriptionssObj).forEach(index => {
              const subscription = subscriptionssObj[index];
              if (subscription.status == "ACTIVE") {
                this.getNextBilling(
                  subscription.billing_info.next_billing_time
                );
                this.billingEmail = subscription.subscriber.email_address;
                this.subscriptionPlatform = "paypal";
              }
            });
          }
        })
        .catch(error => {
          console.log("error in updateSubscriptionHistory : " + error);
        });
    },
    getTransactions() {
      // console.log("ICEICE getTransactions()");
      this.transactionArray = [];
      const transactionsObjPayPal = this.transactionsPayPal;
      const transactionsObjStripe = this.transactionsStripe;
      const regexCoinbase = /COINBASE/g;
      const regexTubepay = /TUBEPAY/g;
      let lastTransactionTS = 0;
      if (transactionsObjPayPal) {
        // console.log("ICEICE transactionsObjPayPal is: ", transactionsObjPayPal);
        Object.keys(transactionsObjPayPal).forEach(index => {
          const transactionList = transactionsObjPayPal[index];
          if (regexCoinbase.test(index)) {
            const startSubscriptionTS = transactionsObjPayPal[index].ts;
            const date = new Date(startSubscriptionTS);
            const tmpObj = {
              date: date,
              planName: this.$t("plans.pro.title"), // Always pro by the moment
              amount: "5.00 EUR", // By the moment, always the same price
              platform: "CoinBase"
            };
            this.transactionArray.push(tmpObj);
            if (lastTransactionTS < startSubscriptionTS) {
              this.subscriptionPlatform = "CoinBase";
              lastTransactionTS = startSubscriptionTS;
              this.startSubscriptionTS = startSubscriptionTS;
            }
          }
          // TUBEPAY TRANSACTION
          if (regexTubepay.test(index)) {
            const startSubscriptionTS = transactionsObjPayPal[index].ts;
            const date = new Date(startSubscriptionTS);
            const tmpObj = {
              date: date,
              planName: this.$t("plans.pro.title"), // Always pro by the moment
              amount: "5.00 EUR", // By the moment, always the same price
              platform: "TubePay"
            };
            this.transactionArray.push(tmpObj);
            if (lastTransactionTS < startSubscriptionTS) {
              this.subscriptionPlatform = "tubePay";
              lastTransactionTS = startSubscriptionTS;
              this.startSubscriptionTS = startSubscriptionTS;
            }
          }
          // PAYPAL TRANSACTION
          if (
            transactionList &&
            transactionList.transactions &&
            transactionList.transactions.transactions
          ) {
            transactionList.transactions.transactions.forEach(transaction => {
              if (transaction.status == "COMPLETED") {
                const startSubscriptionTS = transaction.time;
                const tmpObj = {
                  date: Date.parse(startSubscriptionTS),
                  //PlanId is not saved on a per transaction basis, so for now we'll do it this way:
                  planName: this.$t("plans.pro.title"),
                  amount: `${transaction.amount_with_breakdown.gross_amount.value} ${transaction.amount_with_breakdown.gross_amount.currency_code}`,
                  platform: "paypal"
                };
                this.transactionArray.push(tmpObj);
                if (lastTransactionTS < startSubscriptionTS) {
                  this.subscriptionPlatform = "paypal";
                  lastTransactionTS = startSubscriptionTS;
                  this.startSubscriptionTS = startSubscriptionTS;
                }
              }
            });
          }
        });
      }
      if (transactionsObjStripe) {
        Object.keys(transactionsObjStripe).forEach(index => {
          const transaction = transactionsObjStripe[index];
          if (transaction.paid === true) {
            let planName = "";
            // console.log(transaction.lines.data[0]);
            if (
              transaction?.lines?.data[0]?.plan?.nickname &&
              transaction.lines.data[0].plan.nickname === "Pro"
            ) {
              planName = this.$t("plans.pro.title");
            } else if (
              transaction?.lines?.data[0]?.plan?.nickname &&
              transaction.lines.data[0].plan.nickname === "Advanced"
            ) {
              planName = this.$t("plans.pro.title");
            }
            const startSubscriptionTS = transaction.created * 1000;
            const tmpObj = {
              date: startSubscriptionTS,
              planName: planName,
              amount: `${(transaction.amount_paid / 100).toFixed(
                2
              )} ${transaction.currency.toUpperCase()}`,
              platform: "stripe",
              hosted_invoice_url: transaction.hosted_invoice_url
            };
            this.billingEmail = transaction.customer_email;
            this.transactionArray.push(tmpObj);
            if (lastTransactionTS < startSubscriptionTS) {
              this.subscriptionPlatform = "stripe";
              lastTransactionTS = startSubscriptionTS;
              this.startSubscriptionTS = startSubscriptionTS;
            }
          }
        });
      }
      // Set next BillingDate
      if (this.$store.state.user.user.cryptoSubExpiry) {
        this.getNextBilling(this.$store.state.user.user.cryptoSubExpiry);
      }
    },
    async updateTransactionHistory() {
      this.isLoadingTransactions = true;
      if (this.$store.state.user.transactions) {
        this.transactionsPayPal = this.$store.state.user.transactions;
      }
      if (this.$store.state.user.stripe_invoice) {
        this.transactionsStripe = this.$store.state.user.stripe_invoice;
      }
      return fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/updateTransactionHistory`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.message) {
            console.log(response.message);
          }
          const user = this.$store.state.user.user;
          if (user) {
            this.$store
              .dispatch("USER_TRANSACTIONS_FETCH", {
                user: user
              })
              .then(() => {
                this.transactionsPayPal = this.$store.state.user.transactions;
                const transactionKey = this.transactionsPayPal
                  ? Object.keys(this.transactionsPayPal)[0]
                  : null;
                /* As the data can be empty/incomplete even after a PayPal successful payment, 
                we need to check if we already have some transactions or fetch the data again (recursively) */
                if (
                  this.isStarter ||
                  (this.transactionsStripe && this.transactionsStripe.length) ||
                  (this.transactionsPayPal &&
                    transactionKey &&
                    this.transactionsPayPal[transactionKey].transactions &&
                    this.transactionsPayPal[transactionKey].transactions
                      .transactions) ||
                  this.trPaypalRetries < 1
                ) {
                  this.isLoadingTransactions = false;
                  this.getTransactions();
                } else {
                  this.trPaypalRetries--;
                  setTimeout(() => {
                    this.updateTransactionHistory();
                  }, 1500);
                }
              });
            this.$store
              .dispatch("USER_STRIPE_INVOICE_FETCH", {
                user: user,
                isRaMicro: this.isRaMicro
              })
              .then(() => {
                this.transactionsStripe = this.$store.state.user.stripe_invoice;
                this.isLoadingTransactions = false;
                this.getTransactions();
              });
          }
        })
        .catch(error => {
          console.log("error in updateTransactionHistory : " + error);
        });
    }
  },
  computed: {
    footerText() {
      return this.$t("profile.subscriptions.rows-per-page");
    },
    sortByText() {
      return this.$t("profile.subscriptions.sort-by-text");
    },
    allText() {
      return this.$t("profile.subscriptions.allText");
    },
    getTransactionPaypal() {
      return this.$store.state.user.transactions;
    },
    getItems() {
      let items = [];
      if (this.isStarter) {
        items = [
          //{ name: this.$t("profile.subscriptions.upgrade1"), value: 1 }, // 1 upgradeToAdvanced
          { name: this.$t("profile.subscriptions.upgrade2"), value: 2 } // 2 upgradeToPro
        ];
      } else {
        if (this.claims.subId == 1) {
          items = [
            //{ name: this.$t("profile.subscriptions.upgrade1"), value: 1 }, // 1 upgradeToAdvanced
            { divider: true },
            // {
            //   header: this.$t("profile.subscriptions.noDowngrade"),
            //   class: "justify-content-center"
            // },
            {
              name: this.$t("profile.subscriptions.downgrade2"),
              value: 4
              // disabled: true
            } //downgradeToFree
          ];
        } else if (this.claims.subId == 2) {
          items = [
            // {
            //   header: this.$t("profile.subscriptions.noDowngrade"),
            //   class: "justify-content-center"
            // },
            // {
            //   name: this.$t("profile.subscriptions.downgrade1"),
            //   value: 3,
            //   disabled: true
            // }, // 3 downgradeToPro
            {
              name: this.$t("profile.subscriptions.downgrade2"),
              value: 4
              // disabled: true
            } //downgradeToFree
          ];
        }
      }
      return items;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}
.cardProfile {
  display: table;
  height: 100%;
  width: 100%;

  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}

.paymentDivs {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}

.pRelative {
  position: relative;
}

.subTitlePaymentSection {
  font-size: 15px;
}

.iconGo {
  position: absolute;
  bottom: 40%;
  right: 25px;
}

.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}
.paymentPlatforms {
  margin-top: 15px;
}
.paymentLogo {
  max-height: 25px;
}
.paymentLogoCoinbase {
  max-height: 17px;
}
.paymentLogoCCurrency {
  max-height: 20px;
}
.paymentLogoTube {
  width: 100%;
  height: 100%;
}
.paymentLogoRow {
  width: 125px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px 15px;
  border: 1px solid rgb(25, 118, 210);
  border-radius: 5%;
  cursor: pointer;
  .logoCoinbase {
    width: 120px;
  }
}
.tubeLogoRow {
  padding: 5px !important;
  min-width: 120px;
}
.CCLogoRow {
  background-color: rgb(25, 118, 210);
  padding: 5px 5px 0px 5px;
}
.paymentRow {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
.logoBilling {
  width: 100px;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
<style>
.v-subheader {
  justify-content: center;
}
.cancelButtonColor {
  background-color: #e69696 !important;
  float: right;
}
/* .am-PoweredByBitTubeRight {
  display: none;
} */
</style>
