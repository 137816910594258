var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cardProfile",attrs:{"flat":""}},[(_vm.isMobile)?_c('span',{staticClass:"text-right closeButtonPosition links",on:{"click":function($event){return _vm.showSection('home')}}},[_vm._v("x")]):_vm._e(),_c('v-row',{staticClass:"rowPills"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t("profile.roomHistory.titel"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("profile.roomHistory.subtitel")))])]),_c('v-col',{staticClass:"px-0",staticStyle:{"text-align":"left"},attrs:{"cols":"12"}},[_c('div',{staticClass:"bordered pa-5"},[_c('h2',{staticClass:"pb-1 title"},[_vm._v(" "+_vm._s(_vm.$t("profile.roomHistory.history"))+" ")]),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.getTableHeaders,"footer-props":{
              'items-per-page-text': _vm.footerText,
              'items-per-page-all-text': _vm.allText
            },"items":_vm.roomHistory,"loading":_vm.loadingDataTable,"loading-text":"","mobile-breakpoint":"750","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"header-props":{
              'sort-by-text': _vm.sortByText
            }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.roomName",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"links",on:{"click":function($event){return _vm.goToRoom(item.roomName)}}},[_vm._v(" "+_vm._s(item.roomName)+" ")])]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format("DD.MM.YYYY"))+" ")]}},{key:"item.time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format("HH:mm"))+" ")]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("profile.roomHistory.noHistory"))+" ")])],2)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }