<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            class="logo mb-8"
            contain
            src="../assets/easymeet_logo-b.svg"
          ></v-img>

          <p class="mb-0 headline">
            {{ $t("payPal.cancelRequest") }}
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Paypal",
  data: () => ({
    timeoutHolder: undefined
  }),
  mounted() {
    this.timeoutHolder = setTimeout(() => {
      if (sessionStorage.getItem("returnPage") === "subscriptions") {
        this.$router.replace("/profile");
      } else {
        this.$router.replace("/");
      }
    }, 3000);
  },
  beforeDestroy() {
    if (this.timeoutHolder) {
      clearTimeout(this.timeoutHolder);
    }
  },
  methods: {},
  watch: {},
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess() {
      return !!this.$store.state.user.user_success;
    }
  }
};
</script>

<style scoped lang="scss">
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
</style>
