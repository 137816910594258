<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-row class="rowPills">
      <v-col cols="12" class="pb-5" v-if="!showTransactions">
        <h1 class="pb-2">{{ $t("profile.payments.mainTitle") }}</h1>
        <p>
          {{ $t("profile.payments.subtitle") }}
        </p>
      </v-col>

      <v-col
        cols="12"
        class="paymentDivs text-left pa-6 mb-6"
        v-if="!showTransactions"
      >
        <h2 class="pb-1 title">
          {{ $t("profile.payments.paymentMethods") }}
        </h2>
        <p class="subTitlePaymentSection">
          {{ $t("profile.payments.paymentMedthodsSubtitle") }}
        </p>
        <v-row
          class="pRelative cursorPointer stripePaymentRow mx-0"
          @click="loginStripeConnect"
        >
          <div class="colWithImages">
            <h2 class="pb-1 pt-3 title">
              {{ $t("plans.payments.stripe") }}
            </h2>
            <p class="subTitlePaymentSection mb-3">
              {{ $t("profile.payments.stripeDesc") }}
              <span v-if="isStripeConnectLinked" class="green--text">
                {{ $t("plans.payments.connected") }}
              </span>
            </p>
          </div>
          <div class="logoCol">
            <v-img class="paymentLogo" src="img/StripeLogo.svg.png" />
            <div v-if="!isStripeInfoReady" class="pl-4 white--text">...</div>
            <div
              v-if="isStripeInfoReady && isStripeConnectLinked"
              class="pl-4 stripetext"
            >
              {{ $t("plans.payments.manage-account") }}
            </div>
            <div
              v-if="isStripeInfoReady && !isStripeConnectLinked"
              class="pl-4 stripetext"
            >
              {{ $t("plans.payments.connect-account") }}
            </div>
          </div>
          <font-awesome-icon
            v-if="!isLoadingStripe"
            :icon="['far', 'chevron-right']"
            :style="{ fontSize: 20 }"
          />
          <div v-if="isLoadingStripe" class="spinner" />
        </v-row>
      </v-col>

      <template v-if="showModalPaypalSettings">
        <PaypalSettingsModal
          :showModalPaypalSettings="showModalPaypalSettings"
          :closeModal="closePaypalSettings"
        />
      </template>

      <v-col
        cols="12"
        class="paymentDivs text-left pa-6 pRelative cursorPointer"
        @click="handlerClickShowTransactions"
      >
        <h2 class="pb-1 title">
          {{ $t("profile.payments.clientTransactions") }}
        </h2>
        <p class="subTitlePaymentSection mb-3">
          {{ $t("profile.payments.clientTransactionsSubtitle") }}
        </p>

        <font-awesome-icon
          v-if="!showTransactions"
          class="iconGo"
          :icon="['far', 'chevron-right']"
          :style="{ fontSize: 20 }"
        />
        <font-awesome-icon
          v-if="showTransactions"
          class="iconGo"
          :icon="['far', 'times']"
          :style="{ fontSize: 20 }"
        />
      </v-col>
      <v-col cols="12" class="paymentDivs mt-6" v-if="showTransactions">
        <v-row class="mb-2">
          <v-col cols="6"> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('plans.payments.search')"
              single-line
              dense
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :search="search"
          :headers="headers"
          :items="invoiceList"
          show-expand
          :single-expand="singleExpand"
          item-key="transactionID"
          :footer-props="{
            'items-per-page-text': footerText,
            'items-per-page-all-text': allText
          }"
          :header-props="{
            'sort-by-text': sortByText
          }"
        >
          <template slot="no-data">
            {{ $t("profile.subscriptions.no-data") }}
          </template>
          <template #item.date="{ item }">
            {{ getTransactionDate(item.date) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.id }}
              <!-- <a :href="item.link" target="_blank">{{ item.link }}</a> -->
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-alert
      :value="showError"
      type="error"
      class="genericAlerts"
      transition="scale-transition"
    >
      {{ showError }}
    </v-alert>
    <template v-if="showStripeConnect">
      <StripeConnectModal
        :showStripeConnect="showStripeConnect"
        :closeModal="closeStripeConnect"
        @loading="setProgressBar"
      />
    </template>
  </v-card>
</template>
<script>
import Vue from "vue";
import PaypalSettingsModal from "./modals/paypalSettingsModal.vue";
import StripeConnectModal from "../modals/setupStripeConnect.vue";
import { getStripeConnectInvoice, getFirebaseToken } from "../../firebase";
import country_en from "@/utils/countries_en.json";
import country_es from "@/utils/countries_es.json";
import country_de from "@/utils/countries_de.json";
import moment from "moment";
import { isRaMicro, isMobile } from "@/utils";
export default {
  name: "Payments",
  props: ["showSection"],
  components: { PaypalSettingsModal, StripeConnectModal },
  data: () => ({
    menu: false,
    searchDate: new Date().toISOString().substr(0, 10),
    search: "",
    singleExpand: true,
    isStripeInfoReady: false,
    isLoadingStripe: false,
    isStripeConnectLinked: false,
    showStripeConnect: false,
    isRaMicro: isRaMicro(),
    isMobile: isMobile(),
    headers: [
      {
        text: Vue.prototype.$t("profile.subscriptions.date"),
        value: "date",
        sortable: true
      },
      {
        text: Vue.prototype.$t("profile.payments.payer"),
        value: "payer",
        sortable: false
      },
      {
        text: Vue.prototype.$t("profile.payments.status"),
        value: "status",
        sortable: true
      },
      {
        text: Vue.prototype.$t("profile.payments.amount"),
        value: "amount",
        sortable: true
      },
      {
        text: "",
        value: "data-table-expand"
      }
      // { text: 'Transaction Link', value: 'link' },
    ],
    invoiceList: [],
    showTransactions: false,
    showModalPaypalSettings: false,
    showError: false
  }),
  created() {},
  mounted() {
    const user = this.$store.state.user.user;
    if (user) {
      this.fetchMerchantInvoice();
      this.fetchMerchantStatus();
    }
  },
  methods: {
    handlerClickShowTransactions() {
      this.showTransactions = !this.showTransactions;
    },
    getTransactionDate(date) {
      return moment.unix(date).format("DD.MM.YYYY HH:mm");
    },
    showPaypalSettings() {
      this.showModalPaypalSettings = true;
    },
    closePaypalSettings() {
      this.showModalPaypalSettings = false;
    },
    setProgressBar(option) {
      this.isLoadingStripe = option;
    },
    async loginStripeConnect() {
      if (!this.isStripeInfoReady) {
        return;
      }
      if (this.isStripeConnectLinked) {
        this.isLoadingStripe = true;
        fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/getStripeConnectLoginLink`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              isRaMicro: this.isRaMicro
            })
          }
        )
          .then(response => response.json())
          .then(response => {
            this.isLoadingStripe = false;
            window.location.assign(response.url);
          })
          .catch(error => {
            this.isLoadingStripe = true;
            this.handlerShowError(error);
          });
      } else {
        this.showStripeConnect = true;
      }
    },
    async convertToCountryCode(countryName) {
      let countryCode = "";
      country_en.forEach(country => {
        if (country.name.toLowerCase() === countryName.toLowerCase()) {
          countryCode = country.alpha2.toUpperCase();
        }
      });
      country_es.forEach(country => {
        if (country.name.toLowerCase() === countryName.toLowerCase()) {
          countryCode = country.alpha2.toUpperCase();
        }
      });
      country_de.forEach(country => {
        if (country.name.toLowerCase() === countryName.toLowerCase()) {
          countryCode = country.alpha2.toUpperCase();
        }
      });
      return countryCode;
    },
    async fetchMerchantStatus() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          this.isStripeInfoReady = true;
          if (response.success && response.account.charges_enabled) {
            this.isStripeConnectLinked = true;
          }
        })
        .catch(error => {
          console.log(error);
          this.handlerShowError(error);
        });
    },
    async fetchMerchantInvoice() {
      let invoices = await getStripeConnectInvoice(
        this.$store.state.user,
        this.isRaMicro
      );
      invoices.forEach(invoice => {
        let tempObj = {
          date: invoice.created,
          payer: invoice.charges.data[0].billing_details.name,
          status: invoice.status,
          amount:
            (invoice.amount / 100).toFixed(2) +
            " " +
            invoice.currency.toUpperCase()
        };
        this.invoiceList.push(tempObj);
        this.invoiceList.sort(function(a, b) {
          var keyA = new Date(a.date),
            keyB = new Date(b.date);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
      });
    },
    handlerShowError(error) {
      this.showError = error;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    },
    closeStripeConnect() {
      this.showStripeConnect = false;
    }
  },
  computed: {
    footerText() {
      return this.$t("profile.subscriptions.rows-per-page");
    },
    sortByText() {
      return this.$t("profile.subscriptions.sort-by-text");
    },
    allText() {
      return this.$t("profile.subscriptions.allText");
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}
.errorToaster {
  position: fixed;
  right: 30px;
  bottom: 0;
  max-width: 300px;
}
.cursorPointer {
  cursor: pointer;
}
.cardProfile {
  display: table;
  height: 100%;
  width: 100%;

  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}

.paymentDivs {
  border: 1px solid #ccc;
  min-height: 115px;
  border-radius: 10px;
}

.pRelative {
  position: relative;
}

.subTitlePaymentSection {
  font-size: 15px;
}

.iconGo {
  position: absolute;
  bottom: 40%;
  right: 29px;
}

.paymentText {
  margin-right: 155px;
}

.iconGoInner {
  @extend .iconGo;
  right: 13px;
}
.paymentLogo {
  width: 150px;
  float: right;
  margin-right: 10px;
}
.stripetext {
  color: #635bff;
}

.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}
.stripePaymentRow {
  flex-direction: row;
  align-items: center;
}

.colWithImages {
  width: calc(100% - 175px);
}

// Spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  // left: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}
// End Spinner

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
@media screen and (max-width: 992px) {
  .paymentText {
    margin-right: 105px;
  }
}
@media screen and (max-width: 767px) {
  .colWithImages {
    width: 100%;
  }
  .logoCol {
    width: calc(100% - 15px);
  }
}
</style>
