<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            v-if="!isRaMicro"
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>
          <v-img
            v-else
            class="logo mb-8"
            contain
            src="../../assets/easymeet_logo-b.svg"
          ></v-img>
          <div class="div_texts">
            <p class="text-center headline" cols="12">
              {{ $t("plans.payments.thanksSetupStripe") }}
            </p>
            <p class="text-center headline" cols="12" v-if="isAccountCompleted">
              {{ $t("plans.payments.accountReady") }}
            </p>
            <p
              class="text-center headline"
              cols="12"
              v-if="isAccountNotCompleted"
            >
              <strong>{{ $t("plans.payments.needMoreInfo") }}</strong>
              {{ $t("plans.payments.fillAllRequired") }}
            </p>
          </div>
          <v-btn-toggle v-model="toggle_exclusive" class="primary">
            <v-btn depressed color="primary" @click="startNow">
              <font-awesome-icon
                v-if="!isRaMicro"
                class="mr-3"
                :icon="['fas', 'phone-alt']"
                :style="{ fontSize: 15 }"
              />
              {{ $t("startnow") }}
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  depressed
                  :class="{ redButton: isRaMicro }"
                  class="w-100 buttonEuroMainScreen"
                  @click="showLinkModal"
                  :loading="loading"
                >
                  €
                </v-btn>
              </template>
              <span>
                {{ getTooltipButton }}
              </span>
            </v-tooltip>
          </v-btn-toggle>
          <!-- <v-btn
            color="primary"
            :disabled="!isAcceptButtonActive"
            @click="$router.push({ path: `/profile` })"
          >
            {{ $t("plans.payments.accept") }}
          </v-btn> -->
        </v-col>
      </v-row>
    </div>
    <needs-login
      :showNeedsLogin="showNeedsLogin"
      :closeModal="closeNeedsLogin"
      :goToLogin="goToLogin"
      :goToRegister="goToRegister"
    />
  </v-container>
</template>

<script>
import { getFirebaseToken } from "../../firebase";
import { isRaMicro, randomString } from "../../utils.js";
import FingerprintJS from "fingerprintjs";
import NeedsLogin from "../modals/needsLogin.vue";

export default {
  name: "StripeConnectReturn",
  components: { NeedsLogin },
  data: () => ({
    isAccountCompleted: false,
    isAccountNotCompleted: false,
    isAcceptButtonActive: false,
    isRaMicro: isRaMicro(),
    toggle_exclusive: 2,
    showNeedsLogin: false,
    loading: false
  }),
  mounted() {
    this.fetchStripeConnectAccount();
  },
  methods: {
    closeNeedsLogin() {
      this.showNeedsLogin = false;
    },
    goToLogin() {
      this.$router.push({ path: `/login` });
    },
    goToRegister() {
      this.$router.push({ path: `/register` });
    },
    async fetchStripeConnectAccount() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          console.log(response);
          let address = response.account.business_profile.support_address || "";
          let addressArray = address.split(" ");
          let street = addressArray[0];
          if (isNaN(street)) {
            street = "";
          } else {
            addressArray.shift();
          }
          let addressString = addressArray.toString().replace(",", " ");

          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              company: response.account.business_profile.name,
              street: street || this.user.street,
              address: addressString || this.user.address,
              country: response.account.country || this.user.country,
              billingEmail: response.account.email || this.user.billingEmail
            }
          });

          if (response.success && response.account.charges_enabled) {
            this.isAccountCompleted = true;
          } else {
            this.isAccountNotCompleted = false;
          }
          // this.isAcceptButtonActive = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    startNow() {
      if (!this.isRaMicro) {
        this.initCall();
      } else {
        if (this.user) {
          this.goToStartCallPage();
          // this.initCall();
        } else {
          this.showNeedsLogin = true;
        }
      }
    },
    async initCall() {
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            let fingerPrint = new FingerprintJS().get();
            let ts = Date.now();
            this.$router.push({
              path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}`
            });
            this.showCallComponent = true;
          } else {
            // Try again with a different room
            this.initCall();
          }
        });
    },
    async showLinkModal() {
      if (this.user && this.isAccountCompleted) {
        this.loading = true;
        let subRoom = await this.generateRandomSubroom();
        let room = this.user.mainRoom.name + "/" + subRoom;
        this.loading = false;
        let fingerPrint = new FingerprintJS().get();
        let ts = Date.now();
        this.$router.push({
          path: `/c/${room}?f=${fingerPrint}&ts=${ts}`,
          query: { payLink: "true" }
        });
      }
    },
    goToStartCallPage() {
      this.$router.push({ path: `/start` });
    },
    async generateRandomSubroom() {
      if (await this.ensureMainRoom()) {
        let savedRooms = [];
        let subRoomName = randomString(9);

        if (this.$store.state.user.user.savedRooms) {
          savedRooms = this.$store.state.user.user.savedRooms.slice();
        }
        savedRooms.push({ name: subRoomName, online: true, locked: true });
        let response = await fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              room_name: this.user.mainRoom.name,
              online: this.user.mainRoom.online,
              subroom: savedRooms
            })
          }
        );

        let responseJson = await response.json();
        if (responseJson.success) {
          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              savedRooms: savedRooms
            }
          });
          return subRoomName;
        }
      }
    },
    async ensureMainRoom() {
      if (this.user.mainRoom && this.user.mainRoom.name) {
        return true;
      } else {
        // If user does not have a main room, generate a random one
        let mainRoomName = randomString(9);
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: mainRoomName
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  mainRoom: { name: mainRoomName, online: true }
                }
              });
              const user = this.user;
              this.$store.dispatch("USER_FETCH", { user });
              return true;
            } else {
              return false;
            }
          })
          .catch(error => {
            console.warn("Error on claimRoom", error);
            return false;
          });
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    getTooltipButton() {
      let text = "";
      if (this.user && !this.isAccountCompleted) {
        text = this.$t("startPage.ttEuroBtnActivate");
      } else if (this.user && this.isAccountCompleted) {
        text = this.$t("startPage.ttEuroBtnGetPaid");
      } else if (!this.user) {
        text = this.$t("startPage.ttEuroBtnLogin");
      }
      return text;
    }
  }
};
</script>

<style scoped lang="scss">
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 36px;
}
.buttonEuroMainScreen {
  width: 20px;
  font-size: 20px;
  background-color: #42d13f !important; //#4EF04A !important;
  color: white !important;
  opacity: 1 !important;
  &.redButton {
    background-color: red !important;
  }
}
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
.div_texts {
  min-height: 130px;
}
</style>
