<template>
  <div class="home">
    <PaypalCancel v-if="!loading" />
  </div>
</template>

<script>
// @ is an alias to /src
import PaypalCancel from "@/components/paypalCancel.vue";

export default {
  name: "home",
  components: {
    PaypalCancel
  },
  computed: {
    loading() {
      return !!this.$store.state.user.loading;
    }
  }
};
</script>
<style scoped lang="scss">
.home {
  background-color: #fff;
  word-break: break-word;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
