<template>
  <v-container class="containerStartPage">
    <div class="d-flex align-center height-100">
      <v-row class="mb20">
        <v-col class="text-center" cols="12">
          <v-img
            class="logo mb-8"
            contain
            src="../assets/easymeet_logo-b.svg"
          ></v-img>

          <p class="mb-0 headline" v-if="requestLoading">
            {{ $t("payPal.processing") }}
          </p>
          <p class="mb-0 headline" v-if="!requestLoading && successRequest">
            {{ $t("payPal.completed") }}
          </p>
          <p class="mb-0 headline" v-if="!requestLoading && errorRequest">
            {{ $t("profile.subscriptions.subscriptionError") }}
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { getFirebaseToken } from "../firebase";
export default {
  name: "Paypal",
  data: () => ({
    requestLoading: true,
    requestPromise: undefined,
    timeoutHolder: undefined,
    successRequest: false,
    errorRequest: false
  }),
  created() {
    this.requestPromise = getFirebaseToken()
      .then(token =>
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/processAgreement`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            subscription_id: this.$route.query.subscription_id
          })
        })
      )
      .then(response => response.json())
      .then(async response => {
        this.requestLoading = false;
        if (response.success) {
          const token = await getFirebaseToken(true);
          this.successRequest = true;
          this.errorRequest = false;
          console.log("processAgreement response successful", response, token);

          await this.$store.dispatch(
            "USER_SUBSCRIPTIONS_FETCH",
            this.$store.state.user.user
          );
          await this.$store.dispatch(
            "USER_TRANSACTIONS_FETCH",
            this.$store.state.user.user
          );
          this.timeoutHolder = setTimeout(() => {
            sessionStorage.setItem("returnPage", "profile");
            this.$router.replace("/profile");
          }, 3000);
        } else {
          this.errorRequest = true;
          this.successRequest = false;
          throw new Error("processAgreement response not successful");
        }
      })
      .catch(error => {
        console.warn("Error on processAgreement", error);
        this.requestLoading = false;
        this.errorRequest = true;
        this.successRequest = false;
        this.timeoutHolder = setTimeout(() => {
          if (sessionStorage.getItem("returnPage") === "subscriptions") {
            this.$router.replace("/profile");
          } else {
            this.$router.replace("/plans");
          }
        }, 3000);
      });
  },
  beforeDestroy() {
    this.requestPromise = this.requestPromise.then(() => {
      if (this.timeoutHolder) {
        clearTimeout(this.timeoutHolder);
      }
    });
  },
  mounted() {
    this.requestPromise = getFirebaseToken().then(token =>
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/recordUserFirstSubscriptionStat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    );
  },
  methods: {},
  watch: {},
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    error() {
      return this.$store.state.user.user_error;
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    },
    hasSuccess() {
      return !!this.$store.state.user.user_success;
    }
  }
};
</script>

<style scoped lang="scss">
.logo {
  max-width: 350px;
  margin: 0 auto;
}
.mb20 {
  margin-bottom: 15vh;
}
.height-100 {
  height: 100vh;
}
</style>
