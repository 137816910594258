<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-row class="rowPills">
      <v-col cols="12">
        <h1 class="pb-2">
          {{ $t("profile.addressBook.addressbook") }}
        </h1>
        <p>{{ $t("profile.addressBook.subtitle") }}</p>
      </v-col>
      <!-- <v-col cols="3"></v-col> -->
      <v-col v-if="isStarter && !isLoadingUserClaim && !isRaMicro">
        <span v-html="$t('profile.addressBook.subtitleStarter')"></span>
      </v-col>
      <v-col
        v-if="!isStarter || isRaMicro"
        cols="12"
        style="text-align:left;"
        class="px-0"
      >
        <div class="bordered pa-5">
          <h2 class="pb-1 title">
            {{ $t("profile.addressBook.contacts") }}
            <v-btn
              class="buttonAddNew"
              depressed
              color="primary"
              @click="showModalAddNewContact()"
            >
              {{ $t("profile.addressBook.addNew") }}
            </v-btn>
          </h2>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('profile.addressBook.search')"
            single-line
            hide-details
            dense
            outlined
            class="mt-4"
          ></v-text-field>
          <div>
            <v-data-table
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :headers="getTableHeaders"
              :footer-props="{
                'items-per-page-text': footerText,
                'items-per-page-all-text': allText
              }"
              :header-props="{
                'sort-by-text': sortByText
              }"
              :items="contacts"
              :search="search"
              :loading="loadingDataTable"
              loading-text=""
              mobile-breakpoint="750"
            >
              <template slot="no-data">
                {{ $t("profile.addressBook.no-contacts") }}
              </template>

              <template #item.phone="{ item }">
                {{ item.phone }}
                <font-awesome-icon
                  v-show="item.hasWhatsapp"
                  :icon="['fab', 'whatsapp']"
                  :style="{ fontSize: 15 }"
                />
              </template>

              <template #item.actions="{ item }">
                <v-tooltip top max-width="40%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="editContact(item, true)"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      small
                    >
                      <font-awesome-icon
                        v-if="item.note"
                        class="yellow--text"
                        :icon="['fas', 'sticky-note']"
                        :style="{ fontSize: 15 }"
                      />
                      <font-awesome-icon
                        v-else
                        class="yellow--text text--darken-4"
                        :icon="['fas', 'sticky-note']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span v-if="item.note">{{ item.note }}</span>
                  <span v-else>{{ $t("profile.addressBook.addNotes") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="handleDirectCall(item)"
                      target="_blank"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      :loading="item.loadingDirectCall"
                      small
                    >
                      <font-awesome-icon
                        :icon="['fas', 'phone-alt']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span> {{ $t("profile.addressBook.direct-call") }}</span>
                </v-tooltip>
                <v-tooltip top v-if="isMobile">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="shareLinkOnMobile(generateDirectCallLink(item))"
                    >
                      <font-awesome-icon
                        :icon="['far', 'share']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span></span>
                </v-tooltip>
                <v-tooltip top v-if="userHasPayments">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="handlePaylinkCall(item)"
                      target="_blank"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      :loading="item.creatingPayLink"
                      small
                    >
                      <font-awesome-icon
                        :icon="['far', 'money-bill']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("profile.addressBook.paylink-call") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      target="_blank"
                      @click="editContact(item, false)"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      small
                    >
                      <font-awesome-icon
                        :icon="['far', 'pencil']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("profile.addressBook.edit-contact") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      target="_blank"
                      @click="handleDeleteContact(item.key)"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                      color="error"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'trash']"
                        :style="{ fontSize: 15 }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("profile.addressBook.deleteContact") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <!-- <div class="alpha-quickSelect">
                <span v-for="letter in getQuickSelect" :key="letter" @click="filterContacts(letter)">{{letter}}</span>
            </div> -->
          </div>
        </div>
      </v-col>
    </v-row>
    <AddNewContactModal
      :loading="loading"
      :showModalAddContact="showModalAddContact"
      :closeModalAddNewContact="closeModalAddNewContact"
      :insertContact="insertContact"
      :contacts="contacts"
    />
    <EditContactModal
      :loading="loading"
      :showModalEditContact="showModalEditContact"
      :closeModalEditContact="closeModalEditContact"
      :handleEditContact="handleEditContact"
      :contact="editedContact"
      :contacts="contacts"
      :isEditingNotesOnly="isEditingNotesOnly"
    />
    <DeleteContactModal
      :loading="loading"
      :showDeleteContact="showDeleteContact"
      :closeModalDeleteContact="closeModalDeleteContact"
      :deleteContact="deleteContact"
    />
  </v-card>
</template>
<script>
import { getFirebaseToken, getFirebaseClaims } from "../../firebase";
import AddNewContactModal from "../modals/addNewContact.vue";
import EditContactModal from "../modals/editContactModal.vue";
import DeleteContactModal from "../modals/deleteContactModal.vue";
import {
  randomString,
  isRaMicro,
  shareLinkOnMobile,
  isMobile
} from "../../utils";
import FingerprintJS from "fingerprintjs";

export default {
  name: "AddressBook",
  props: ["showSection"],
  components: { AddNewContactModal, EditContactModal, DeleteContactModal },
  data: () => ({
    sortBy: "first_name",
    sortDesc: true,
    contacts: [],
    tableHeaderRoom: [],
    showModalAddContact: false,
    shareLinkOnMobile: shareLinkOnMobile,
    isMobile: isMobile(),
    search: "",
    editedIndex: -1,
    editedContact: {
      first_name: "",
      last_name: "",
      email: "",
      phone: ""
    },
    isRaMicro: isRaMicro(),
    showModalEditContact: false,
    keyToDelete: false,
    showDeleteContact: false,
    loading: false,
    isLoadingUserClaim: true,
    isStarter: true,
    isEditingNotesOnly: false,
    loadingDataTable: true,
    userHasPaymentsActive: false,
    letterSelected: "",
    alpha: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "Ñ",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ],
    creatingPayLink: false,
    baseUrl: window.location.origin
  }),
  created() {
    getFirebaseClaims(true)
      .then(claims => {
        if (claims.hasSub) {
          this.isStarter = false;
        }
        this.isLoadingUserClaim = false;
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
      });
  },
  mounted() {
    this.getUserHasPaymentSet();
    this.getContacts();
  },
  methods: {
    filterContacts(letter) {
      this.letterSelected = letter;
    },
    async handleDirectCall(contact) {
      const roomId = randomString(9);
      contact.loadingDirectCall = true;
      console.log("roomId", roomId);
      let fingerPrint = new FingerprintJS().get();
      let ts = Date.now();
      const link = `${window.location.origin}/c/${roomId}?f=${fingerPrint}&ts=${ts}`;
      const sendEmail = await this.sendEmail(contact, link);
      contact.loadingDirectCall = false;
      if (sendEmail) {
        this.$router.push({ path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}` });
      } else {
        console.log("Something wrong on sent email");
      }
    },
    generateDirectCallLink(contact) {
      const roomId = randomString(9);
      contact.loadingDirectCall = true;
      console.log("roomId", roomId);
      let fingerPrint = new FingerprintJS().get();
      let ts = Date.now();
      contact.loadingDirectCall = false;
      return `${window.location.origin}/c/${roomId}${roomId}?f=${fingerPrint}&ts=${ts}`;
    },
    async handlePaylinkCall(contact) {
      if (this.userHasPaymentsActive) {
        contact.creatingPayLink = true;
        let subRoom = await this.generateRandomSubroom();
        let room = this.user.mainRoom.name + "/" + subRoom;
        contact.creatingPayLink = false;
        let fingerPrint = new FingerprintJS().get();
        let ts = Date.now();
        this.$router.push({
          path: `/c/${room}?f=${fingerPrint}&ts=${ts}`,
          query: { payLink: "true", contact: encodeURIComponent(contact.email) }
        });
      } else {
        console.log("no payments");
      }
    },
    async generateRandomSubroom() {
      if (await this.ensureMainRoom()) {
        let savedRooms = [];
        let subRoomName = randomString(9);

        if (this.$store.state.user.user.savedRooms) {
          savedRooms = this.$store.state.user.user.savedRooms.slice();
        }
        savedRooms.push({ name: subRoomName, online: true, locked: true });
        let response = await fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              room_name: this.user.mainRoom.name,
              online: this.user.mainRoom.online,
              subroom: savedRooms
            })
          }
        );

        let responseJson = await response.json();
        if (responseJson.success) {
          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              savedRooms: savedRooms
            }
          });
          return subRoomName;
        }
      }
    },
    async ensureMainRoom() {
      if (this.user.mainRoom.name) {
        return true;
      } else {
        // If user does not have a main room, generate a random one
        let mainRoomName = randomString(9);
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: mainRoomName
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  mainRoom: { name: mainRoomName, online: true }
                }
              });
              return true;
            } else {
              return false;
            }
          })
          .catch(error => {
            console.warn("Error on claimRoom", error);
            return false;
          });
      }
    },
    async sendEmail(contact, link) {
      let token = await getFirebaseToken();
      let subject = this.$t("profile.addressBook.email-subject", [
        this.getUserName
      ]);
      let message = this.$t("profile.addressBook.email-body", [
        contact.first_name,
        contact.last_name,
        this.getUserName
      ]);
      message += `<a href='${link}'>${link}</a><br />`;

      let response = await fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/sendemail`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            email: contact.email,
            subject: subject,
            message: message
          })
        }
      );

      let responseJson = await response.json();
      if (responseJson.success) {
        return true;
      } else {
        return false;
      }
    },
    closeModalEditContact() {
      this.showModalEditContact = false;
      this.editedContact = {
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      };
      this.editedIndex = -1;
    },
    showModalAddNewContact() {
      this.showModalAddContact = true;
    },
    closeModalAddNewContact() {
      this.showModalAddContact = false;
    },
    closeModalDeleteContact() {
      this.keyToDelete = false;
      this.showDeleteContact = false;
    },
    async insertContact(
      first_name,
      last_name,
      email,
      phone,
      hasWhatsapp,
      titel
    ) {
      this.loading = true;
      this.loadingDataTable = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveContact`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          hasWhatsapp: hasWhatsapp,
          titel: titel
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success && response.contacts) {
            this.contacts = response.contacts;
            this.contacts.forEach(contact => {
              const firstLetter = contact.first_name.charAt(0);
              if (this.alpha.indexOf(firstLetter) == -1) {
                this.alpha.push(firstLetter);
              }
            });
          }
          this.loading = false;
          this.closeModalAddNewContact();
          this.loadingDataTable = false;
        });
    },
    async getContacts() {
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/getAddressBook`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.success && response.contacts) {
            this.contacts = response.contacts;
            this.contacts.forEach(contact => {
              const firstLetter = contact.first_name.charAt(0);
              if (this.alpha.indexOf(firstLetter) == -1) {
                this.alpha.push(firstLetter);
              }
            });
          }
        });
    },
    handleDeleteContact(key) {
      this.keyToDelete = key;
      this.showDeleteContact = true;
    },
    async deleteContact() {
      this.loading = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/deleteContact`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          key: this.keyToDelete
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success && response.contacts) {
            this.contacts = response.contacts;
            this.contacts.forEach(contact => {
              const firstLetter = contact.first_name.charAt(0);
              if (this.alpha.indexOf(firstLetter) == -1) {
                this.alpha.push(firstLetter);
              }
            });
          }
          this.loading = false;
          this.closeModalDeleteContact();
        });
    },
    editContact(item, editNotesOnly) {
      this.isEditingNotesOnly = editNotesOnly;
      this.editedIndex = this.contacts.indexOf(item);
      this.editedContact = Object.assign({}, item);
      this.showModalEditContact = true;
    },
    async handleEditContact(
      first_name,
      last_name,
      email,
      phone,
      hasWhatsapp,
      key,
      titel,
      note
    ) {
      this.loading = true;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/editContact`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          hasWhatsapp: hasWhatsapp,
          key: key,
          titel: titel,
          note: note
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success && response.contacts) {
            this.contacts = response.contacts;
            this.contacts.forEach(contact => {
              const firstLetter = contact.first_name.charAt(0);
              if (this.alpha.indexOf(firstLetter) == -1) {
                this.alpha.push(firstLetter);
              }
            });
          }
          this.loading = false;
          this.closeModalEditContact();
        });
    },
    async getUserHasPaymentSet() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success && response.account.charges_enabled) {
            this.userHasPaymentsActive = true;
          } else {
            this.userHasPaymentsActive = false;
          }
          this.loadingDataTable = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    footerText() {
      return this.$t("profile.subscriptions.rows-per-page");
    },
    sortByText() {
      return this.$t("profile.subscriptions.sort-by-text");
    },
    allText() {
      return this.$t("profile.subscriptions.allText");
    },
    getUserName() {
      if (this.user.displayName) {
        return this.user.displayName;
      } else {
        return this.user.email;
      }
    },
    getQuickSelect() {
      console.log("this.alpha", this.alpha);
      return this.alpha;
    },
    getTableHeaders() {
      var tableHeaderRoom = [
        {
          text: "Titel",
          align: "start",
          sortable: false,
          value: "titel"
        },
        {
          text: this.$t("profile.addressBook.firstname"),
          align: "start",
          sortable: true,
          value: "first_name"
        },
        {
          text: this.$t("profile.addressBook.lastname"),
          align: "start",
          sortable: true,
          value: "last_name"
        },
        {
          text: this.$t("profile.addressBook.email"),
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t("profile.addressBook.phone"),
          align: "start",
          sortable: false,
          value: "phone"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "actions"
        }
      ];
      return tableHeaderRoom;
    },
    user() {
      return this.$store.state.user.user;
    },
    userHasPayments() {
      console.log("userHasPaymentsActive", this.userHasPaymentsActive);
      return this.userHasPaymentsActive;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}

.alpha-quickSelect {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3%;
  text-align: center;
  span {
    color: #1876d1;
    cursor: pointer;
  }
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.titlePill {
  width: calc(100% - 20px);
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.bordered {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}
.dropDownButton {
  width: 100%;
  color: black;
  .v-icon {
    position: absolute;
    right: -10px;
  }
}
.buttonAddNew {
  float: right;
}

@media screen and (max-width: 480px) {
  .buttonAddNew {
    float: unset;
  }
}

@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
<style lang="scss">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 7px !important;
  max-width: 10vw;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.v-data-table__wrapper > table > thead > tr > th {
  padding: 0 7px !important;
  white-space: nowrap;
}
</style>
